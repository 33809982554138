import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Order } from "../../../../common/interfaces/CryptoInterface";
import useCripto from "../../../../common/hooks/useCripto";

export const MisMovimientosTable = () => {
  const { dictionary, dataConfig } = useCripto("");
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#E9E9E9" }}>
            {[
              dictionary?.index.tablaOrdenes.fecha,
              dictionary?.index.tablaOrdenes.moneda,
              dictionary?.index.tablaOrdenes.operacion,
              dictionary?.index.tablaOrdenes.numeroOperacion,
              dictionary?.index.tablaOrdenes.cantidad,
            ].map((el) => (
              <TableCell
                align="center"
                key={`header-${el}`}
                sx={{ fontWeight: "bold" }}>
                {el}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataConfig && dataConfig.length > 0 ? (
            dataConfig.map((row: Order) => (
              <TableRow
                key={"body-" + row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell align="center" component="th" scope="row">
                  {row.created_at}
                </TableCell>
                <TableCell align="center">{row.asset?.currency}</TableCell>
                <TableCell align="center">{row?.operation}</TableCell>
                <TableCell align="center">{row.id}</TableCell>
                <TableCell align="center">
                  {parseFloat(
                    row.asset?.amount ? row.asset?.amount : "0"
                  ).toFixed(8)}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableCell align="center" colSpan={5}>
              no results
            </TableCell>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
