import Typography from "@mui/material/Typography";
import ShortLine from "../../../components/ShortLine/ShortLine";
import { Box, Grid, MenuItem, Select, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import "./VenderCrypto.css";
import ExchangeFormContainer from "../components/ExchangeFormContainer/ExchangeFormContainer";
import OrderVentaExchange from "../ComprarCrypto/components/OrderVentaExchange";
import ButtonSubmit from "../../../components/ButtonSubmit/ButtonSubmit";
import useVentaCripto from "../../../common/hooks/useVentaCripto";

const VenderCrypto = () => {
  const {
    //variable
    moneda,
    dataExchange,
    textFields,
    dictionary,
    criptoDisponible,
    orden,
    okForm,
    handleChange,
    handleChangeCripto,
    handleSubmit,
  } = useVentaCripto();

  return (
    <Grid container columns={12} paddingTop={4} paddingX={2}>
      <Grid item xs={12}>
        <Typography variant="h4" color="primary" align="left">
          {dictionary?.vender.titulo}
          <ShortLine position="left" />
        </Typography>
      </Grid>
      {/* FORMULARIO EXCHANGE COMPRA */}
      {!okForm ? (
        <ExchangeFormContainer>
          {/* select */}
          <Box
            borderBottom={2}
            borderColor={"primary.main"}
            className="compra-crypto-select-container">
            <Typography variant="h6" color="primary" width={"150px"}>
              {dictionary?.vender.formVenta.ventaDe}
            </Typography>
            <Select
              sx={{ width: "240px" }}
              value={moneda}
              onChange={handleChange}
              defaultValue={moneda}
              inputProps={{ "aria-label": "Without label" }}>
              <MenuItem value="default">
                {dictionary?.vender.formVenta.monedaVender}
              </MenuItem>
              {dataExchange &&
                dataExchange.data?.quotes.map((el: any) => (
                  <MenuItem
                    key={`menu-item-${el.currency}`}
                    value={el.currency}>
                    {el.currency}
                  </MenuItem>
                ))}
            </Select>
          </Box>
          {/* puntos disponibles */}
          <Typography variant="subtitle1" color="initial">
            {dictionary?.js.saldoEn
              .replace("{moneda}", moneda)
              .replace("{saldo}", parseFloat(criptoDisponible).toFixed(8))}
          </Typography>
          <Box className="crypto-text-field">
            <div className="vender--cripto-input">
              <Typography variant="subtitle1" color="initial">
                {dictionary?.vender.formVenta.monedaVender}
              </Typography>
              <TextField
                id="outlined-helperText"
                type="number"
                helperText={dictionary?.vender.formVenta.cantidadCripto}
                onChange={(e) => handleChangeCripto(e)}
                sx={{ input: { color: "primary.main", fontSize: "20px" } }}
              />
            </div>
            <div className="vender--cripto-input">
              <Typography variant="subtitle1" color="initial">
                {dictionary?.vender.formVenta.puntosRecibir}
              </Typography>
              <TextField
                id="outlined-helperText"
                type="number"
                value={textFields.puntos}
                select={false}
                defaultValue={0}
                helperText={dictionary?.vender.formVenta.infoValores}
                sx={{ input: { color: "primary.main", fontSize: "20px" } }}
              />
            </div>
          </Box>

          <ButtonSubmit onClick={handleSubmit}>
            {dictionary?.vender.formVenta.siguiente}
          </ButtonSubmit>
          <div className="enlace-vender-reenvio">
            <Typography variant="subtitle1" color="primary">
              {dictionary?.vender.formVenta.quieresComprar}
            </Typography>
            <Link to="/crypto/compra">
              <Typography
                variant="subtitle1"
                color="primary"
                fontWeight={"bold"}>
                &nbsp;{dictionary?.vender.formVenta.linkCompra}
              </Typography>
            </Link>
          </div>
        </ExchangeFormContainer>
      ) : (
        <>
          <OrderVentaExchange orden={orden} textFields={textFields} />
        </>
      )}
    </Grid>
  );
};

export default VenderCrypto;
