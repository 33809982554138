import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Divider, FormControl, Grid } from "@mui/material";
import InputForm from "../../../../components/Inputs/InputForm/InputForm";
import "./DatosDeLaCuentaForm.css";
import { AppContext } from "../../../../context/AppContext";
import { PutChangePass } from "../../../../services/MisDatosService";
import { useEffect, useState } from "react";
import { getUsuarioById } from "../../../../services/UsuarioService";
import { Usuario } from "../../../../common/interfaces/ComunInterface";
import useLanguage from "../../../../common/hooks/useLanguage";
import ES from "../../../../common/language/users/es.json";
import EN from "../../../../common/language/users/en.json";

export const DatosDeLaCuentaForm = () => {
  const [form, setForm] = useState<Usuario | null>(null);
  const appContext = React.useContext(AppContext);
  const { dictionary } = useLanguage({ EN, ES });
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    getUsuarioById(appContext?.user?.idUsuario).then((user) => {
      setForm(user);
    });
  }, [appContext?.user?.idUsuario]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async () => {
    let res = await PutChangePass(form, appContext?.user);
    res && appContext?.setUser(res);
  };

  return (
    <div>
      {form && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography variant="h5">
              {dictionary?.perfil.datosCuenta}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider sx={{ marginBottom: "20px" }} />
            <FormControl fullWidth>
              <Grid container columns={12} spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <InputForm
                    readonly={true}
                    name="nombre"
                    label={dictionary?.perfil.nombre}
                    type="text"
                    form={form}
                    setForm={setForm}
                  />
                  <InputForm
                    readonly={true}
                    name="apellido"
                    label={dictionary?.perfil.apellido}
                    type="text"
                    form={form}
                    setForm={setForm}
                  />
                  <InputForm
                    readonly={true}
                    name="dni"
                    label={dictionary?.perfil.dni}
                    type="text"
                    form={form}
                    setForm={setForm}
                  />
                  <InputForm
                    readonly={true}
                    name="email"
                    label={dictionary?.perfil.email}
                    type="text"
                    form={form}
                    setForm={setForm}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <InputForm
                    required
                    readonly={false}
                    name="clave"
                    label={dictionary?.perfil.cambiarContrasena}
                    type="password"
                    form={form}
                    setForm={setForm}
                    showPassword={showPassword}
                    handleTogglePassword={handleTogglePassword}
                  />
                  <InputForm
                    readonly={false}
                    name="clave2"
                    label={dictionary?.perfil.repetirContrasena}
                    type="password"
                    form={form}
                    setForm={setForm}
                    showPassword={showPassword}
                    handleTogglePassword={handleTogglePassword}
                  />
                </Grid>
                <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}>
                    {dictionary?.perfil.actualizar}
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

export default DatosDeLaCuentaForm;
