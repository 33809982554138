import React from "react";
import Header from "../Header/Header";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import "./Layout.css";
import { FooterInterface } from "../../common/interfaces/FooterInterface";
import useAutoLogout from "../../common/hooks/useAutoLogOut";
type Props = {
  footerInfo: FooterInterface | null;
};

const Layout: React.FC<Props> = ({ footerInfo }) => {
  useAutoLogout(sessionStorage.getItem("token"));

  return (
    <>
      <div className="app-container">
        <Header
          logo={{
            logoUrl: footerInfo?.logoUrl,
            tipoImagenLogo: footerInfo?.tipoImagenLogo,
            logo: footerInfo?.logo,
          }}
        />
        <main>
          <Outlet />
        </main>
        <Footer footerInfo={footerInfo} />
      </div>
    </>
  );
};

export default Layout;
