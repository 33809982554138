import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./ModalTerminosCondiciones.css";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ES from "../../../../common/language/giftcard/es.json";
import EN from "../../../../common/language/giftcard/en.json";
import useLanguage from "../../../../common/hooks/useLanguage";

type Props = {
  terminos: string;
};

export const ModalTerminosYCondiciones: React.FC<Props> = ({ terminos }) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const { dictionary } = useLanguage({ EN, ES });

  return (
    <div>
      <Typography
        fontSize={16}
        color="primary"
        sx={{ cursor: "pointer" }}
        onClick={() => setOpen(true)}>
        {dictionary?.terminosProducto}
      </Typography>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="modal-terminos-prod">
          <div className="modal-terminos-btn-close-container">
            <Button
              variant="text"
              color="primary"
              onClick={() => setOpen(false)}>
              <CloseIcon />
            </Button>
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {dictionary?.terminos}
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h3">
            {terminos}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
