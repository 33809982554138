import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ReportIcon from "@mui/icons-material/Report";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

type Props = {
  open: boolean;
  setOpen: any;
  message?: string;
};

export const ModalError: React.FC<Props> = ({ open, setOpen, message }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(false);
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 20000 }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
      >
        <Fade in={open}>
          <Box sx={style} textAlign={"center"} position={"relative"}>
            <Box
              sx={{ position: "absolute", top: "10px", right: "20px" }}
              onClick={handleClose}
            >
              <CloseIcon sx={{ fontSize: "20px", cursor: "pointer" }} />
            </Box>
            <Box color={"error.main"}>
              <ReportIcon sx={{ fontSize: "50px" }} />
            </Box>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Error al realizar un canje
            </Typography>
            <Typography variant="body1" component="h2">
              {message}
            </Typography>
            <Box
              marginTop={3}
              display={"flex"}
              gap={3}
              justifyContent={"center"}
            >
              <Button
                variant="contained"
                color="error"
                onClick={() => handleClick()}
              >
                Cerrar
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
