import styled from "styled-components";

export const DashboardContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const DashboardSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const TitleWrapper = styled.div``;

export const BoxTitle = styled.h3`
  font-size: 22px;
  color: #ffffff;
  padding-right: 20px;
  font-weight: 500;
  transition: all 0.5s ease-in;

  @media (max-width: 768px) {
    & {
      font-size: 18px;
    }
  }

  @media (max-width: 480px) {
  }
`;

export const LineTitle = styled.div`
  border: solid 1px #ebe5df;
  width: 33px;
`;

export const StatWrapper = styled.div``;

export const Stat = styled.div`
  font-size: 35px;
  color: #ffffff;
  transition: all 0.5s ease-in;

  @media (max-width: 768px) {
    & {
      font-size: 24px;
    }
  }
`;

export const Total = styled.div`
  font-size: 14px;
  color: #ffffff;
`;

export const FinancialWrapper = styled.div`
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: all 0.5s ease-in;
`;

export const Span = styled.span`
  color: #ffffff;
  transition: all 0.5s ease-in;

  @media (max-width: 768px) {
    & {
      font-size: 14px;
    }
  }

  @media (max-width: 480px) {
    & {
      font-size: 12px;
    }
  }
`;

export const FinancialMessage = styled.div`
  color: #ffffff;
  text-align: center;
  transition: all 0.5s ease-in;

  @media (max-width: 768px) {
    & {
      font-size: 14px;
    }
  }
`;

export const FinancialLevel = styled.div`
  width: 100%;
  max-width: 380px;
  display: flex;
  justify-content: center;
  gap: 15px;
`;

export const BarContainer = styled.div`
  width: 60%;
  border-radius: 5px;
  background-color: #84bdad;
`;

export const ProgressBar = styled.div`
  width: 50%;
  background-color: #08d18b;
  height: 100%;
  border-radius: 5px;
  transition: all 0.5s ease-in;
`;
