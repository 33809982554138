import useLanguage from "./useLanguage";
import ES from "../../common/language/crypto/es.json";
import EN from "../../common/language/crypto/en.json";
import { useQuery } from "react-query";
import {
  GetCustomer,
  GetSaldos,
  getOrders,
  getValuesForChart,
} from "../../services/CryptoService";
import { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { GetUsuarioLiriumStatus } from "../../services/UsuarioService";
import {
  getClienteMoneda,
  getExchangeRates,
} from "../../services/ComprarCryptoService";
import { cliente } from "../constants/constants";
import { IMonedasCliente } from "../../common/interfaces/CompraCryptoInterface";
import { modalError } from "../../utils/validaciones";

const useCripto = (moneda: string) => {
  const [myCrypto, setMyCrypto] = useState<string>("BTC");

  const { dictionary } = useLanguage({ EN, ES });
  const appContext = useContext(AppContext);

  const customer = useQuery({
    queryKey: [`customer`, appContext?.user?.idUsuario],
    queryFn: async () => GetCustomer(appContext?.user?.idUsuario),
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error ? error.message : "Error Customer";
      modalError("Error Customer\n" + errorMessage);
    },
  });

  const liriumStatus = useQuery({
    queryKey: [`liriumStatus`, appContext?.user?.idUsuario],
    queryFn: async () => GetUsuarioLiriumStatus(appContext?.user?.idUsuario),
    enabled: customer ? true : false,
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error ? error.message : "Error al traer los Usuarios";
      modalError("Error al traer los Usuarios\n" + errorMessage);
    },
  });

  const monedaClientes = useQuery<IMonedasCliente>({
    queryKey: ["monedaClientes"],
    queryFn: async () => getClienteMoneda(cliente),
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Error al traer monedaClientes";
      modalError("Error al traer los monedaClientes\n" + errorMessage);
    },
  });

  const dataExchange = useQuery({
    queryKey: [`dataExchange`],
    queryFn: async () => getExchangeRates(monedaClientes?.data?.alfa3),
    staleTime: 1000 * 60 * 5,
    enabled: monedaClientes.isSuccess,
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Error al traer Los tipos de cambio";
      modalError("Error al traer los tipos de cambio\n" + errorMessage);
    },
  });

  const { isLoading, data: dataGraph } = useQuery({
    queryKey: [`dataGraph${moneda}`],
    queryFn: async () => getValuesForChart(moneda, monedaClientes?.data),
    staleTime: 1000 * 60 * 5,
    enabled: monedaClientes.isSuccess && !!moneda,
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Error al traer los Valores para el gráfico";
      modalError("Error al traer los Valores para el gráfico\n" + errorMessage);
    },
  });

  const { data: dataCrypto } = useQuery({
    queryKey: [`dataCrypto`, appContext?.user?.idUsuario],
    queryFn: async () => GetSaldos(appContext?.user?.idUsuario, myCrypto),
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error ? error.message : "Error al traer los Saldos";
      modalError("Error al traer los Saldos\n" + errorMessage);
    },
  });

  const handleMyCryptoChange = (crypto: string) => {
    setMyCrypto(crypto);
  };

  const { data: dataConfig } = useQuery({
    queryKey: [`dataConfig`, appContext?.user?.idUsuario],
    queryFn: async () => getOrders(appContext?.user?.idUsuario),
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error ? error.message : "Error al obtener pedidos";
      modalError("Error al obtener pedidos\n" + errorMessage);
    },
  });

  return {
    dictionary,
    isLoading,
    dataGraph,
    dataCrypto,
    myCrypto,
    handleMyCryptoChange,
    dataConfig,
    customer,
    liriumStatus,
    appContext,
    dataExchange,
  };
};

export default useCripto;
