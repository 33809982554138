import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contadorItem: 0,
};

export const contadorItemReducer = createSlice({
  name: "contadorItem",
  initialState,
  reducers: {
    incrementar: (state) => {
      state.contadorItem += 1;
    },
    disminuir: (state) => {
      state.contadorItem -= 1;
    },
    disminuirPorCantidad: (state, { payload }) => {
      state.contadorItem = state.contadorItem - payload;
    },
    resetContador: (state) => {
      state.contadorItem = 0;
    },
  },
});

export const { incrementar, disminuir, disminuirPorCantidad, resetContador } =
  contadorItemReducer.actions;
export default contadorItemReducer.reducer;
