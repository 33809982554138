import React from "react";
import Reco from "../../../../common/assets/images/home/reco-home.webp";
import ShortLine from "../../../../components/ShortLine/ShortLine";
import { Grid, Box, Typography, Hidden } from "@mui/material";
import star from "../../../../common/assets/images/home/star-icon-reco.svg";
import thumbs from "../../../../common/assets/images/home/thumbs-up-reco.svg";
import "./SectionReconocimiento.css";
import ES from "../../../../common/language/home/es.json";
import EN from "../../../../common/language/home/en.json";
import useLanguage from "../../../../common/hooks/useLanguage";

const SectionReconocimiento: React.FC = () => {
  const { dictionary } = useLanguage({ EN, ES });
  return (
    <>
      <Grid
        container
        xs={12}
        justifyContent={"center"}
        sx={{ padding: "50px 0", position: "relative" }}>
        <Hidden smDown={true}>
          <img
            src={star}
            alt="estrella"
            className="icono-home-sec-rec icono-home-sec-rec-1"
          />
          <img
            src={thumbs}
            alt="pulgar icono"
            className="icono-home-sec-rec icono-home-sec-rec-2"
          />
          <img
            src={star}
            alt="estrella icon"
            className="icono-home-sec-rec icono-home-sec-rec-3"
          />
          <img
            src={star}
            alt="estrella icon"
            className="icono-home-sec-rec icono-home-sec-rec-4"
          />
        </Hidden>
        <Grid item xs={12}>
          <Typography variant="h4" color="primary" textAlign={"center"}>
            {dictionary?.reconocimientos}
            <ShortLine />
          </Typography>

          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <Typography
              variant="h6"
              color="initial"
              maxWidth={"470px"}
              fontSize={{ xs: 18, sm: 20, m: 20 }}
              textAlign={"center"}>
              {dictionary?.descripcionClientes}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <img src={Reco} width={380} alt="estrella icon" />
        </Grid>
      </Grid>
    </>
  );
};

export default SectionReconocimiento;
