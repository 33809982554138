import { useState, useCallback } from "react";
import {
  IFiltroCatalogoResponse,
  bodyProductsInterface,
} from "../../common/interfaces/GiftCardInterfaces";
import { bodyFilterInit, cliente } from "../../common/constants/constants";
import {
  GetFiltros,
  GetPaisForCheckBoxList,
  GetProducts,
} from "../../services/GiftCardService";
import { useQuery, useQueryClient } from "react-query";
import { modalError } from "../../utils/validaciones";
import { useSelector } from "react-redux";
import { RootState } from "../../common/redux/store";

const useCatalogo = () => {
  const [limpiar, setLimpiar] = useState<boolean>(false);
  const [filterProducts, setFilterProducts] =
    useState<bodyProductsInterface>(bodyFilterInit);
  const filters = useSelector((state: RootState) => state.filtroCatalogo);

  const { data: paises } = useQuery({
    queryKey: ["paises"],
    queryFn: async () => GetPaisForCheckBoxList(cliente),
    retry: false,
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Error al traer los Paises para el CheckBox";
      modalError("Error al traer los Paises para el CheckBox\n" + errorMessage);
    },
  });

  const filtrosOptions = useQuery<IFiltroCatalogoResponse>({
    queryKey: ["filtrosOptions", filters],
    queryFn: async () => GetFiltros(filters, cliente),
    retry: false,
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error ? error.message : "Error al traer los Filtros";
      modalError("Error al traer los Filtros\n" + errorMessage);
    },
  });

  const { isLoading: loading, data: productos } = useQuery({
    queryKey: [filterProducts],
    queryFn: async () => GetProducts(filterProducts, cliente, paises),
    retry: false,
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error ? error.message : "Error al traer los productos";
      modalError("Error al traer los productos\n" + errorMessage);
    },
  });

  const queryClient = useQueryClient();

  const PreFetchProductos = useCallback(async () => {
    try {
      await queryClient.prefetchQuery({
        queryKey: [filterProducts],
        queryFn: async () => GetProducts(filterProducts, cliente, paises),
        retry: false,
      });
    } catch (error: any) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Error al precargar los productos";
      modalError("Error al precargar los productos\n" + errorMessage);
    }
    // eslint-disable-next-line
  }, [filterProducts, paises]);

  return {
    productos,
    limpiar,
    setLimpiar,
    loading,
    paises,
    filterProducts,
    setFilterProducts,
    PreFetchProductos,
    filtrosOptions,
  };
};

export default useCatalogo;
