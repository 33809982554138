import { Typography } from "@mui/material";
import ShortLine from "../../components/ShortLine/ShortLine";
import { useSelector } from "react-redux";
import { RootState } from "../../common/redux/store";
import "./CarritoPage.css";
import CarritoTable from "./components/CarritoTable/CarritoTable";
import CarritoTotalContainer from "./components/CarritoTotalContainer/CarritoTotalContainer";
import CarritoVacio from "./components/CarritoVacio/CarritoVacio";
import ES from "../../common/language/catalogo/es.json";
import EN from "../../common/language/catalogo/en.json";
import useLanguage from "../../common/hooks/useLanguage";

const CarritoPage = () => {
  const carrito = useSelector((state: RootState) => state.carrito);
  const { dictionary } = useLanguage({ EN, ES });
  return (
    <div className="carrito-page-section">
      <Typography variant="h4" color="primary">
        {dictionary?.titulo}
        <ShortLine position="left" />
        {carrito.length > 0 ? (
          <>
            <CarritoTable />
            <CarritoTotalContainer />
          </>
        ) : (
          <CarritoVacio />
        )}
      </Typography>
    </div>
  );
};

export default CarritoPage;
