import { createGlobalStyle } from "styled-components";

type Props = {
  bgHeader?: string;
  bgFooter?: string;
  colorDefault?: string;
  colorTitle: string;
  bgLowerBar: string;
  bgMainButton: string;
  colorFooter: string;
  footerLane: string;
};

const GlobalStyles = createGlobalStyle<Props>`
    *,
    *::before,
    *::after {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        
    }

    header {
        background-color: ${({ bgHeader }) => bgHeader || "red"};
    }

    footer {
        background-color: ${({ bgFooter }) => bgFooter || "red"};
    }

    footer > div:first-child {
        border-bottom: 9px solid ${({ footerLane }) => footerLane};
    }

    footer a {
        color: ${({ colorFooter }) => colorFooter};
    }

    #lowerBar {
        background-color: ${({ bgLowerBar }) => bgLowerBar || "red"};
    }

    button {
        color: #ffffff;
        background-color: ${({ bgMainButton }) => bgMainButton || "green"};
        border: 1px solid ${({ bgMainButton }) => bgMainButton || "green"};
    }
`;
export default GlobalStyles;
