import { headerOptions } from "../common/constants/constants";
import { Usuario } from "../common/interfaces/ComunInterface";
import { formMisDatosInterface } from "../common/interfaces/MisDatosInterface";
import { fetchContent, fetchNoJsonRes } from "../utils/fetchUtils";
import { PutChangePassModal, notificacion } from "../utils/functions";
import { validarCamposDatosCuenta } from "../utils/validaciones";

export const GetPersonaByIdUsuario = async (idPersona: number | undefined) => {
  if (!idPersona) {
    const user: string | null = sessionStorage.getItem("user");
    if (user) {
      idPersona = JSON.parse(user).idPersona;
    }
  }
  let res = await GetPersonaByIdUsuarioFetch();
  return res.filter((el: formMisDatosInterface) => el.idPersona === idPersona);
};

const GetPersonaByIdUsuarioFetch = () => {
  try {
    let response = fetchContent(`/api/persona`, {
      method: "GET",
    });

    return response;
  } catch (error: any) {
    throw new Error("[GetPersonaByIdUsuario error]: " + error);
  }
};

export const getPaisForSelect = () => {
  try {
    let response = fetchContent("/api/pais", {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    throw new Error("[getPaisForSelect error]: " + error);
  }
};

export const getProvinciaForSelect = () => {
  try {
    let response = fetchContent("/api/provincia", {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    throw new Error("[getProvinciaForSelect error]: " + error);
  }
};

export const GetLocalidadByProvinciaForSelect = (idProvincia: string) => {
  try {
    let response = fetchContent(`/api/localidad/provincia/${idProvincia}`, {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    throw new Error("[GetLocalidadByProvinciaForSelect error]: " + error);
  }
};

export const GetGeneroPersonaForSelect = (lang: string) => {
  try {
    let response = fetchContent(`/api/generopersona?idioma=${lang}`, {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    throw new Error("[GetLocalidadByProvinciaForSelect error]: " + error);
  }
};

export const PutPersona = (
  body: formMisDatosInterface | null,
  idPersona: number | null
) => {
  try {
    let response = fetchNoJsonRes(`/api/persona/${idPersona}`, {
      method: "PUT",
      headers: headerOptions,
      body: JSON.stringify(body),
    });
    return response;
  } catch (error: any) {
    throw new Error("[GetLocalidadByProvinciaForSelect error]: " + error);
  }
};

export const PutChangePass = async (form: Usuario | null, user: any) => {
  const isValid = validarCamposDatosCuenta(form);

  if (!isValid || !form) {
    return notificacion(false, "Verificar campos");
  }
  const userBody = {
    idUsuario: form.idUsuario,
    email: form.email,
    nombre: form.nombre,
    apellido: form.apellido,
    avatar: form.avatar,
    dni: form.dni,
    idRol: form.idRol,
    usuarioDvh: form.usuarioDvh,
    cultura: "AR",
    clave: form.clave,
  };

  const userMod = { ...user, avatar: form.avatar };

  sessionStorage.setItem("user", JSON.stringify(userMod));

  const usuarioresult = await PutChangePassFetch(userBody.idUsuario, userBody);

  if (usuarioresult.ok) {
    PutChangePassModal();
    return userMod;
  }
};

const PutChangePassFetch = (idUsuario: number | undefined, body: Usuario) => {
  try {
    // let response = fetchNoJsonRes(`/api/usuario/${idUsuario}`,
    let response = fetchNoJsonRes(`/api/usuario/clave/${idUsuario}`, {
      method: "PUT",
      headers: headerOptions,
      body: JSON.stringify(body),
    });
    return response;
  } catch (error: any) {
    throw new Error("[GetLocalidadByProvinciaForSelect error]: " + error);
  }
};
