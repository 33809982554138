interface IExchange {
  minPrice: number;
  maxPrice: number;
  deltaIncrementPrice: number;
  value: number;
  button?: string;
}

const getPossiblesExchanges = (exchange: IExchange): number[] => {
  let { minPrice, maxPrice, deltaIncrementPrice } = exchange;

  if (minPrice >= maxPrice) {
    return [];
  }

  if (deltaIncrementPrice <= 0) {
    throw new Error('deltaIncrementoPrice debe ser mayor que 0');
  }
  let count = minPrice;
  const possiblesExchanges = [count];

  while (count <= maxPrice) {
    count += deltaIncrementPrice;
    possiblesExchanges.push(count);
  }
  return possiblesExchanges;
};

export const validateExchange = (exchange: IExchange): boolean => {
  const { value } = exchange;

  const possiblesExchanges = getPossiblesExchanges(exchange);
  const isAValidExchange = possiblesExchanges.includes(value);

  return isAValidExchange;
};

export const setClosestExchange = (exchange: IExchange): number => {
  const { value, button } = exchange;

  const possiblesExchanges = getPossiblesExchanges(exchange);
  const isAValidExchange = validateExchange(exchange);
  const deltaIndex = possiblesExchanges.findIndex((exchange) => exchange >= value);

  if (button === 'next' && isAValidExchange) {
    return possiblesExchanges[deltaIndex + 1];
  } else if (button === 'next' && !isAValidExchange) {
    return possiblesExchanges[deltaIndex];
  } else {
    return possiblesExchanges.slice(0, deltaIndex)[deltaIndex - 1];
  }
};
