import CatalogoCard from "../CatalogoCard/CatalogoCard";
import "./CatalogoCards.css";
import { IProducto } from "../../../../common/interfaces/GiftCardInterfaces";

type Props = {
  productos: IProducto[] | null;
};

const CatalogoCards: React.FC<Props> = ({ productos }) => {
  return (
    <div className="catalogo-cards-container-width">
      <div className="catalogo-cards-container">
        {productos &&
          productos.map((prod, index) => (
            <CatalogoCard info={prod} key={`prod-${prod.idProducto}`} />
          ))}
      </div>
    </div>
  );
};

export default CatalogoCards;
