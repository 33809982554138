import CarritoColumnas from "./CarritoColumnas";
import CarritoItemContainer from "../CarritoItemContainer/CarritoItemContainer";
import { Box } from "@mui/material";

const CarritoTable = () => {
  return (
    <Box marginBottom={5}>
      <CarritoColumnas />
      <CarritoItemContainer />
    </Box>
  );
};

export default CarritoTable;
