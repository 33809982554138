import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import React, { SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import ES from "../../../common/language/shared/es.json";
import EN from "../../../common/language/shared/en.json";
import useLanguage from "../../../common/hooks/useLanguage";

type Props = {
  setOpen: React.Dispatch<SetStateAction<boolean>>;
};
const MenuDrawerItemsPreLogin: React.FC<Props> = ({ setOpen }) => {
  const navigate = useNavigate();
  const { dictionary } = useLanguage({ EN, ES });

  const handleClick = (ruta: string) => {
    navigate(ruta);
    setOpen(false);
  };

  return (
    <>
      <ListItem disablePadding divider={false}>
        <ListItemButton onClick={() => handleClick("/")}>
          <ListItemText primary={dictionary?.header.inicio} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding divider={false}>
        <ListItemButton onClick={() => handleClick("/catalogo")}>
          <ListItemText primary={dictionary?.header.giftCards} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding divider={true}>
        <ListItemButton onClick={() => handleClick("/crypto")}>
          <ListItemText primary={dictionary?.header.criptomonedas} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding divider={true}>
        <ListItemButton onClick={() => handleClick("/contacto")}>
          <ListItemText primary={dictionary?.header.contactanos} />
        </ListItemButton>
      </ListItem>
    </>
  );
};

export default MenuDrawerItemsPreLogin;
