import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import modalBranchesStyles from "./styles";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";

const ModalBranches = ({
  detailProd,
  handleClose,
  open,
  dictionary,
  branches = [],
}) => {
  const styles = modalBranchesStyles();

  const groupedBranches = branches.reduce((acc, branch) => {
    const { provincia } = branch;
    if (!acc[provincia]) {
      acc[provincia] = [];
    }
    acc[provincia].push(branch);
    return acc;
  }, {});

  const sortedProvinces = Object.keys(groupedBranches).sort();

  sortedProvinces.forEach((provincia) => {
    groupedBranches[provincia].sort((a, b) =>
      a.localidad.localeCompare(b.localidad)
    );
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={styles.container}>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <StorefrontOutlinedIcon color="primary" sx={{ fontSize: "30px" }} />
          <Typography fontSize={20} component="h3" color="primary">
            Buscá tu sucursal más cercana
          </Typography>
        </Box>
        <Divider sx={{ width: "100%", my: "20px" }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 20px",
          }}>
          <Typography fontSize={17}>
            Sucursales de {detailProd?.marcaDescripcion}:
          </Typography>
          {/* <Box sx={{ display: "flex", gap: "5px" }}>
            <MapOutlinedIcon color="primary" sx={{ fontSize: "20px" }} />
            <Typography fontSize={14} color="primary">
              Ver en el mapa
            </Typography>
          </Box> */}
        </Box>
        {/* <Box sx={{ mt: 4 }}>
          <Typography fontSize={18} sx={{ mb: 1 }}>
            Mapa de Sucursales
          </Typography>
          <MapContainer branches={branches} />
        </Box> */}
        {sortedProvinces.map((provincia) => (
          <Box key={provincia} sx={{ mt: 2, p: "0px 20px" }}>
            <Typography fontSize={18} color="primary">
              {provincia}
            </Typography>
            <Grid container>
              {groupedBranches[provincia].map(
                (branch, index) =>
                  branch.direccion && (
                    <Grid item xs={12} sm={6} key={index}>
                      <List sx={{ p: 0, m: 0 }}>
                        <ListItem>
                          <ListItemIcon>
                            <PushPinOutlinedIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={branch.localidad}
                            secondary={branch.direccion}
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  )
              )}
            </Grid>
          </Box>
        ))}
      </Box>
    </Modal>
  );
};
export default ModalBranches;
