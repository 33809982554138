import { Box, Button, Grid, Typography } from "@mui/material";
import { miCriptoInterface } from "../../../../common/interfaces/CryptoInterface";
import useCripto from "../../../../common/hooks/useCripto";

const ContainerSaldoCrypto = () => {
  const { dictionary, dataCrypto, myCrypto, handleMyCryptoChange } =
    useCripto("");

  return (
    <Grid item xs={12}>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Typography variant="h5" color="initial">
          {dictionary?.index.saldo}
        </Typography>
        <Typography variant="h5" color="primary">
          {dataCrypto
            ? `${myCrypto}: ${parseFloat(
                dataCrypto.filter(
                  (el: miCriptoInterface) => el.currency === myCrypto
                ).length > 0
                  ? dataCrypto.filter(
                      (el: miCriptoInterface) => el.currency === myCrypto
                    )[0].amount
                  : "0"
              ).toFixed(8)}`
            : dictionary?.index.seleccioneMoneda}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", gap: "10px" }} mt={3}>
        <Typography variant="h5" color="initial">
          {dictionary?.index.moneda}
        </Typography>

        {["BTC", "DAI", "ETH", "USDT"].map((el) => (
          <Button
            key={"btn-" + el}
            variant={myCrypto === el ? "contained" : "outlined"}
            color="primary"
            onClick={() => handleMyCryptoChange(el)}>
            {el}
          </Button>
        ))}
      </Box>
    </Grid>
  );
};

export default ContainerSaldoCrypto;
