import { Button, Grid, Typography } from "@mui/material";
import ShortLine from "../../../../components/ShortLine/ShortLine";
import ContainerSaldoCrypto from "../ContainerSaldoCrypto/ContainerSaldoCrypto";
import { MONEDAD_CRYPTO } from "../../../../common/constants/constants";
import CryptoGrafico from "../CryptoGrafico/CryptoGrafico";
import "./LoginCrypto.css";
import { MisMovimientosTable } from "../MisMovimientosTable/MisMovimientosTable";
import { useNavigate } from "react-router-dom";
import useLanguage from "../../../../common/hooks/useLanguage";
import ES from "../../../../common/language/crypto/es.json";
import EN from "../../../../common/language/crypto/en.json";

const LoginCrypto = () => {
  const navigate = useNavigate();
  const { dictionary } = useLanguage({ EN, ES });
  return (
    <section className="login-cripto-page">
      <Grid container columns={12} spacing={2} padding={3}>
        <Typography variant="h4" color="primary">
          {dictionary?.index.titulo}
          <ShortLine position="left" />
        </Typography>
        <ContainerSaldoCrypto />
        <Grid item xs={12}>
          <div className="btn-container-compra-vender">
            <Button
              variant="contained"
              color="btnPrimary"
              onClick={() => navigate("/crypto/compra")}>
              {dictionary?.index.comprar}
            </Button>
            <Button
              variant="outlined"
              color="btnPrimary"
              onClick={() => navigate("/crypto/venta")}>
              {dictionary?.index.vender}
            </Button>
          </div>
        </Grid>
        <section className="graph-section-container">
          {MONEDAD_CRYPTO.map((el, index) => (
            <CryptoGrafico moneda={el} key={`grifico-${el}-${index}`} />
          ))}
        </section>
        <MisMovimientosTable />
      </Grid>
    </section>
  );
};

export default LoginCrypto;
