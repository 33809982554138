import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./CardVideo.css";
import { NivelEducacionVideoDTO } from "../../../../common/interfaces/EducacionFinancieraInterface";
import { ModalVideo } from "../ModalVideo/ModalVideo";
import ES from "../../../../common/language/educacion/es.json";
import EN from "../../../../common/language/educacion/en.json";
import useLanguage from "../../../../common/hooks/useLanguage";

type Props = {
  cardInfo: NivelEducacionVideoDTO;
  card: NivelEducacionVideoDTO | undefined;
  setCard: React.Dispatch<React.SetStateAction<any>>;
  eneable: boolean;
};

const CardVideo: React.FC<Props> = ({ cardInfo, card, setCard, eneable }) => {
  const [estado, setEstado] = useState<boolean>(false);
  const { dictionary } = useLanguage({ EN, ES });

  const handleClick = async () => {
    setEstado(!estado);
    setCard(cardInfo);
  };

  useEffect(() => {
    card?.idNivelEducacion !== cardInfo.idNivelEducacion && setEstado(false);
  }, [card, cardInfo.idNivelEducacion]);

  return (
    <div className="card-video-container">
      <Box
        bgcolor={eneable ? "primary.main" : "gray"}
        className={`card-video ${
          cardInfo.estado === "FINALIZADO" && "video-finalizado"
        } ${estado && "card-video-active"}`}
        onClick={() => handleClick()}>
        {dictionary?.nivel.nivel} {cardInfo.idNivelEducacion}
      </Box>
      {estado && (
        <div
          className="div-todo-pantalla-video"
          onClick={() => setEstado(false)}></div>
      )}
      <Box
        bgcolor={eneable ? "primary.main" : "gray"}
        className={`box-nivel-video ${estado && "box-nivel-video--active"}`}>
        <ModalVideo cardInfo={cardInfo} eneable={eneable} />
      </Box>
    </div>
  );
};

export default CardVideo;
