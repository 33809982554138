import React, { useEffect } from 'react';
import { Box, Button, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { ArrowForwardIosRounded, ArrowBackIosRounded } from '@mui/icons-material';
import { IProducto } from '../../../../common/interfaces/ProductoInterface';
import { setClosestExchange, validateExchange } from '../../../../utils/productos';
import { formatNumber } from '../../../../utils/functions';
import customCountStyles from './customCount.styles';

type Props = {
  minPrice: number;
  maxPrice: number;
  amount: number;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  calculatePoints: (idProduct: number | string, amount: number) => void;
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  exchange: string;
  product: IProducto;
};

const CustomCount: React.FC<Props> = ({
  maxPrice,
  minPrice,
  amount,
  setAmount,
  calculatePoints,
  error,
  setError,
  exchange,
  product,
}) => {
  const styles = customCountStyles();
  const exchangeOptions = {
    minPrice,
    maxPrice,
    deltaIncrementPrice: product?.deltaIncrementoPrecio || 1,
    value: +amount,
  };
  const isAValidExchange = validateExchange({ ...exchangeOptions, value: amount });

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const button = e.currentTarget.id;

    const closestExchange = setClosestExchange({ ...exchangeOptions, button });
    setAmount(closestExchange);
    calculatePoints(product.idProducto, closestExchange);
  };

  useEffect(() => {
    setError(!isAValidExchange);
  }, [isAValidExchange, setError]);

  return (
    <>
      {maxPrice && (
        <Box sx={styles.container}>
          <Box sx={styles.errorMessage}>
            {error && (
              <Typography color="red" fontSize={12}>
                Monto invalido
              </Typography>
            )}
          </Box>
          <Box sx={styles.containerButtons}>
            <Button
              variant="contained"
              disableElevation
              size="small"
              disabled={amount <= minPrice}
              color="btnPrimary"
              id="prev"
              onClick={handleOnClick}
              style={{ padding: 0 }}
              sx={{ ...styles.buttonLeft, ...styles.button }}
              children={<ArrowBackIosRounded sx={{ fontSize: '20px' }} />}
            />
            <OutlinedInput
              sx={styles.input}
              value={amount ? formatNumber(amount) : formatNumber(minPrice)}
              placeholder={minPrice.toString()}
              startAdornment={<InputAdornment position="start">{exchange}</InputAdornment>}
              inputMode="numeric"
              onChange={(e) => {
                validateExchange({
                  minPrice,
                  maxPrice,
                  deltaIncrementPrice: product?.deltaIncrementoPrecio,
                  value: +e.target.value,
                });
                calculatePoints(product.idProducto, +e.target.value);
                setAmount(+e.target.value);
              }}
            />
            <Button
              variant="contained"
              disableElevation
              size="small"
              disabled={amount + product?.deltaIncrementoPrecio > maxPrice}
              color="btnPrimary"
              id="next"
              onClick={handleOnClick}
              sx={{ ...styles.buttonRight, ...styles.button }}
              children={<ArrowForwardIosRounded sx={{ fontSize: '20px' }} />}
            />
          </Box>
          <Box sx={styles.subtitle}>
            <Typography color="#adadad" fontSize={12}>
              Min. {exchange} {formatNumber(minPrice)}
            </Typography>
            <Typography color="#adadad" fontSize={12}>
              Max. {exchange} {formatNumber(maxPrice)}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};
export default CustomCount;
