import { useContext, useEffect, useMemo, useState } from "react";
import { LanguageContext } from "../../context/LanguageContext";

type Props = {
  EN?: any;
  ES?: any;
  PT?: any;
};

const useLanguage = ({ EN, ES, PT }: Props) => {
  const [dictionary, setDictionary] = useState(ES);
  const languageContext = useContext(LanguageContext);
  const dictionaryList = useMemo(() => ({ EN, ES, PT }), [EN, ES, PT]);

  useEffect(() => {
    dictionaryList[languageContext.language as keyof typeof dictionaryList]
      ? setDictionary(
          dictionaryList[
            languageContext.language as keyof typeof dictionaryList
          ]
        )
      : setDictionary(dictionaryList["ES"]);
  }, [dictionaryList, languageContext.language]);
  return { dictionary };
};

export default useLanguage;
