import React, { useEffect, useContext, useState } from "react";
import "./SeleccionarIntegrantesContainer.css";
import Typography from "@mui/material/Typography";
import { Box, TextField, Grid } from "@mui/material";
import { GetConsumidoresForListMultiple } from "../../../../../services/ReconocimientoService";
import { cliente } from "../../../../../common/constants/constants";
import { AppContext } from "../../../../../context/AppContext";
import { consumidorListInterface } from "../../../../../common/interfaces/ReconocimientoInterface";
import ES from "../../../../../common/language/reconocimiento/es.json";
import EN from "../../../../../common/language/reconocimiento/en.json";
import useLanguage from "../../../../../common/hooks/useLanguage";
import { useQuery } from "react-query";
import { modalError } from "../../../../../utils/validaciones";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import ListItemText from "@mui/material/ListItemText";

type Props = {
  form: any;
  setForm: any;
  reset: boolean;
};

const SeleccionarIntegrantesContainer: React.FC<Props> = ({
  form,
  setForm,
  reset,
}) => {
  const [consumidoresList, setConsumidoresList] = useState<
    consumidorListInterface[] | null
  >(null);
  const [seleccionadoList, setSeleccionadoList] = useState<
    consumidorListInterface[] | null
  >(null);

  const appContext = useContext(AppContext);
  const { data: consumidores } = useQuery({
    queryKey: "consumidores",
    queryFn: async () =>
      GetConsumidoresForListMultiple(cliente, appContext?.user?.idUsuario),
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Error al traer la lista de Consumidores";
      modalError("Error al traer la lista de Consumidores\n" + errorMessage);
    },
  });
  const { dictionary } = useLanguage({ EN, ES });

  const seleccionarConsumidor = (el: consumidorListInterface) => {
    const UsuariosReconocidos = form.usuariosReconocidos;
    UsuariosReconocidos.push(el.idUsuario);
    setForm({ ...form, usuariosReconocidos: UsuariosReconocidos });
    if (consumidores) {
      let resSel = consumidores.filter((el: consumidorListInterface) =>
        UsuariosReconocidos.includes(el.idUsuario)
      );
      let resCon = consumidores.filter(
        (el: consumidorListInterface) =>
          !UsuariosReconocidos.includes(el.idUsuario)
      );
      setSeleccionadoList(resSel);
      setConsumidoresList(resCon);
    }
  };

  const deseleccionarConsumidor = async (element: consumidorListInterface) => {
    const UsuariosReconocidos = form.usuariosReconocidos.filter(
      (usuario: number) => usuario !== element.idUsuario
    );
    setForm({ ...form, usuariosReconocidos: UsuariosReconocidos });
    if (consumidores) {
      let resSel = consumidores.filter((el: consumidorListInterface) =>
        UsuariosReconocidos.includes(el.idUsuario)
      );
      let resCon = consumidores.filter(
        (el: consumidorListInterface) =>
          !UsuariosReconocidos.includes(el.idUsuario)
      );
      setSeleccionadoList(resSel);
      setConsumidoresList(resCon);
    }
  };

  useEffect(() => {
    if (consumidores) {
      setSeleccionadoList([]);
      setConsumidoresList(consumidores);
      setForm((prev) => ({ ...prev, usuariosReconocidos: [] }));
    }
  }, [reset, consumidores, setForm]);

  return (
    <Grid container spacing={5} alignItems={"center"}>
      <Grid item xs={12} md={4}>
        <Typography fontSize={18} color="primary" pb={1} pt={2}>
          {dictionary?.seleccioneIntegrantes}
        </Typography>
        <Autocomplete
          multiple
          fullWidth
          options={consumidoresList ?? []}
          getOptionLabel={(option) => `${option.apellido} ${option.nombre}`}
          value={seleccionadoList ?? []}
          onChange={(_, newValue) => {
            if (newValue.length > (seleccionadoList?.length ?? 0)) {
              const addedItem = newValue[newValue.length - 1];
              seleccionarConsumidor(addedItem);
            } else {
              const removedItems = seleccionadoList?.filter(
                (item) => !newValue.includes(item)
              );
              removedItems?.forEach((item) => deseleccionarConsumidor(item));
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={dictionary?.buscar}
            />
          )}
          renderTags={() => null} // No mostrar chips dentro del input
          renderOption={(props, option) => (
            <li {...props}>
              <ListItemText primary={`${option.apellido} ${option.nombre}`} />
            </li>
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
          }}>
          {seleccionadoList &&
            seleccionadoList.map((el) => (
              <Chip
                label={`${el.apellido} ${el.nombre}`}
                onClick={() => deseleccionarConsumidor(el)}
                key={`panel2-${el.idUsuario}`}
                color="primary"
              />
            ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SeleccionarIntegrantesContainer;
