import { List, ListItem, ListItemText, Typography, Button, Box, Drawer, Badge } from '@mui/material';
import { DeleteOutlineOutlined, ShoppingCartOutlined } from '@mui/icons-material';
import { carritoItemsInterface } from '../../common/interfaces/CarritoInterface';
import ES from '../../common/language/catalogo/es.json';
import EN from '../../common/language/catalogo/en.json';
import useLanguage from '../../common/hooks/useLanguage';
import useCarrito from '../../common/hooks/useCarrito';
import { formatNumber } from '../../utils/functions';
import './Carrito.css';

export const Carrito = () => {
  const { open, setOpen, contadorItem, carrito, handleRemove, navigate, toggleDrawer } = useCarrito();
  const { dictionary } = useLanguage({ EN, ES });

  return (
    <Box>
      <Badge badgeContent={contadorItem} color="secondary">
        <Box onClick={() => setOpen(!open)} sx={{ cursor: 'pointer', position: 'relative' }}>
          <ShoppingCartOutlined sx={{ color: open ? 'primary.main' : 'white', fontSize: '20px' }} />
        </Box>
      </Badge>
      <Drawer open={open} onClose={toggleDrawer(true)} anchor="right" sx={{ zIndex: '6000' }}>
        <Box
          className="carrito-desplegable-container"
          width={{ xs: '100vw', sm: 600, md: 600 }}
          sx={{
            paddingTop: 4.5,
            position: 'relative',
          }}
          role="presentation"
          onKeyDown={toggleDrawer(false)}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: '20px',
            }}
          >
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <ShoppingCartOutlined
                onClick={() => setOpen(!open)}
                sx={{
                  color: open ? 'primary.main' : 'white',
                  fontSize: '25px',
                }}
              />
              <Typography variant="h6" color="primary">
                {dictionary?.titulo}
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="paginationColor"
              sx={{
                color: 'primary.main',
                textTransform: 'capitalize',
                borderRadius: '10px',
              }}
              onClick={() => {
                navigate('/carrito');
                setOpen(false);
              }}
            >
              {dictionary?.irAlCarrito}
            </Button>
          </Box>

          <List>
            <ListItem divider={true}>
              <ListItemText>
                <Box className="carrito-menu-drawer-row" sx={{ fontWeight: 600 }}>
                  <Box>{dictionary?.formCarrito.row1.tablaCarritoHeaders.producto}</Box>
                  <Box>{dictionary?.formCarrito.row1.tablaCarritoHeaders.marca}</Box>
                  <Box>{dictionary?.formCarrito.row1.tablaCarritoHeaders.cantidad}</Box>
                  <Box>{dictionary?.formCarrito.row1.tablaCarritoHeaders.puntos}</Box>
                </Box>
              </ListItemText>
            </ListItem>
            {carrito.length > 0 &&
              carrito.map((el: carritoItemsInterface, index: number) => (
                <ListItem key={`${el.idProducto}-${index}`} disablePadding divider={true}>
                  <ListItemText>
                    <Box className="carrito-menu-drawer-row">
                      <Box>{el.Nombre}</Box>
                      <Box>{el.Marca}</Box>
                      <Box>{formatNumber(el.cantidad)}</Box>
                      <Box>{formatNumber(el.puntos)}</Box>
                      <Box>
                        <Button variant="text" onClick={() => handleRemove(index, el.cantidad)}>
                          <DeleteOutlineOutlined color="primary" />
                        </Button>
                      </Box>
                    </Box>
                  </ListItemText>
                </ListItem>
              ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};
