import { LinearProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./ProgressBar.css";
import ES from "../../../../../../common/language/home/es.json";
import EN from "../../../../../../common/language/home/en.json";
import useLanguage from "../../../../../../common/hooks/useLanguage";
import parse from "html-react-parser";
import { GetNivelAlcanzado } from "../../../../../../services/EducacionFinancieraService";
import { NivelEducacionVideoDTO } from "../../../../../../common/interfaces/EducacionFinancieraInterface";

type Props = {
  edu: number;
};

const ProgressBar: React.FC<Props> = ({ edu }) => {
  const [nivelEdu, setNivelEdu] = useState<NivelEducacionVideoDTO | null>(null);
  const { dictionary } = useLanguage({ EN, ES });

  useEffect(() => {
    (async () => {
      try {
        const nivel = await GetNivelAlcanzado();
        setNivelEdu(nivel);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <section className="section-progress-bar">
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", alignItems: "center" }}>
        <Grid item sm={2} className="progress-grid">
          <p>{dictionary?.nivel}</p>
        </Grid>
        <Grid item xs={8}>
          <LinearProgress
            color="secondary"
            sx={{
              height: "20px",
              borderRadius: "15px",
            }}
            variant="determinate"
            value={edu}
          />
        </Grid>
        <Grid item sm={2} className="progress-grid">
          <p>{edu} %</p>
        </Grid>
      </Grid>
      <div className="progress-grid">
        <p>
          {nivelEdu?.estado !== "FINALIZADO"
            ? parse(
                dictionary?.subiAlNivel.replace(
                  "{nivel}",
                  nivelEdu?.idNivelEducacion
                )
              )
            : dictionary?.completasteLoNiveles}
        </p>
      </div>
    </section>
  );
};

export default ProgressBar;
