import { Box, Grid, Typography } from "@mui/material";
import ES from "../../../../common/language/catalogo/es.json";
import EN from "../../../../common/language/catalogo/en.json";
import useLanguage from "../../../../common/hooks/useLanguage";

const CarritoColumnas = () => {
  const { dictionary } = useLanguage({ EN, ES });
  return (
    <Grid container columns={14} className="row-carrito row-carrito-gris">
      <Grid item xs={12} sm={2} md={2}>
        <Box className="column-carrito">
          <Typography variant="h6" color="initial">
            {dictionary?.formCarrito.row1.tablaCarritoHeaders.fecha}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <Box className="column-carrito">
          <Typography variant="h6" color="initial">
            {dictionary?.formCarrito.row1.tablaCarritoHeaders.producto}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <Box className="column-carrito">
          <Typography variant="h6" color="initial">
            {dictionary?.formCarrito.row1.tablaCarritoHeaders.marca}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <Box className="column-carrito">
          <Typography variant="h6" color="initial">
            {dictionary?.formCarrito.row1.tablaCarritoHeaders.cantidad}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <Box className="column-carrito">
          <Typography variant="h6" color="initial">
            {dictionary?.formCarrito.row1.tablaCarritoHeaders.puntosUnidad}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <Box className="column-carrito">
          <Typography variant="h6" color="initial">
            {dictionary?.formCarrito.row1.tablaCarritoHeaders.puntos}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={2} md={2}></Grid>
    </Grid>
  );
};

export default CarritoColumnas;
