import { useCallback, useContext, useEffect, useState } from 'react';
import useLanguage from './useLanguage';
import { Usuario } from '../../common/interfaces/ComunInterface';
import { useQuery } from 'react-query';
import { GetMiEquipo } from '../../services/MiEquipoService';
import { modalError } from '../../utils/validaciones';
import { AppContext, LanguageContext } from '../../context';
import EN from "../language/my_team/en.json";  
import ES from "../language/my_team/es.json";  

const useMiEquipo = () => {
  const [usuSearch, setUsuSearch] = useState<Usuario[] | null>(null);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [orden, setOrden] = useState({ campo: 'nombre', ascendente: true });
  const [search, setSearch] = useState<string>('');
  const appContext = useContext(AppContext);
  const language = useContext(LanguageContext);
  const { dictionary } = useLanguage({ EN, ES });
  const idUsuario = appContext?.user?.idUsuario;

  const fetchData = useCallback(async () => {
    const direccion = orden.ascendente ? 'asc' : 'desc';
    const order = `${orden.campo} ${direccion}`;
    const usuario = search ?? '';
    let params = {
      pagina: page,
      ordenamiento: order,
      CantidadRegistrosPorPagina: 10,
      ...(usuario && { usuario }),
    };
    return await GetMiEquipo(params);
  }, [page, orden, search]);

  const cambiarOrdenamiento = (campo: string) => {
    setOrden((ordenActual) => {
      const esAscendente = ordenActual.campo === campo ? !ordenActual.ascendente : true;
      return {
        campo,
        ascendente: esAscendente,
      };
    });
  };

  const { isLoading: loading, data: usuarios } = useQuery({
    queryKey: ['usuarios', appContext?.user?.idUsuario, page, orden, search],
    queryFn: fetchData,
    cacheTime: 0,
    onSuccess: (data) => setTotalPages(data.cantidadPaginas),
    onError: (error: any) => {
      const errorMessage = error instanceof Error ? error.message : 'Error al traer Mi Equipo';
      modalError('Error al traer Mi Equipo\n' + errorMessage);
    },
  });

  useEffect(() => {
    usuarios && setUsuSearch(usuarios.data);
  }, [usuarios]);

  return {
    idUsuario,
    loading,
    usuSearch,
    dictionary,
    page,
    totalPages,
    orden,
    language,
    setPage,
    setSearch,
    cambiarOrdenamiento,
  };
};

export default useMiEquipo;
