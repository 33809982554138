import { fetchContent, fetchNoJsonRes } from "../utils/fetchUtils";
import { handleServiceError } from "./GiftCardService";

export const getUsuarioByIdUser = (idUsuario: number | undefined) => {
  if (!idUsuario) {
    const user: string | null = sessionStorage.getItem("user");
    if (user) {
      idUsuario = JSON.parse(user).idUsuario;
    }
  }
  try {
    let response = fetchContent(`/api/usuario/${idUsuario}`, {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    throw new Error("[ProductoPage], detalleService error]: " + error);
  }
};

export const getUsuarioById = (idUsuario: number | undefined) => {
  if (!idUsuario) {
    const user: string | null = sessionStorage.getItem("user");
    if (user) {
      idUsuario = JSON.parse(user).idUsuario;
    }
  }
  try {
    let response = fetchContent(`/api/persona/usuario/${idUsuario}`, {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    throw new Error("[ProductoPage], detalleService error]: " + error);
  }
};

export const GetUsuarioLirium = async (idUsuario: number | undefined) => {
  if (!idUsuario) {
    const user: string | null = sessionStorage.getItem("user");
    if (user) {
      idUsuario = JSON.parse(user).idUsuario;
    }
  }
  try {
    let response_json = await fetchNoJsonRes(
      `/api/liriumUsuario/usuario/${idUsuario}`,
      {
        method: "GET",
      }
    );
    const response = await response_json.json();
    return response;
  } catch (error: any) {
    console.log("[Crypto], getCustomerId [error]: " + error);
    throw handleServiceError(error);
  }
};
export const GetUsuarioLiriumStatus = async (idUsuario: number | undefined) => {
  if (!idUsuario) {
    const user: string | null = sessionStorage.getItem("user");
    if (user) {
      idUsuario = JSON.parse(user).idUsuario;
    } else {
      return false;
    }
  }
  try {
    let response_json = await fetchNoJsonRes(
      `/api/liriumUsuario/usuario/${idUsuario}`,
      {
        method: "GET",
      }
    );
    return response_json.ok;
  } catch (error: any) {
    console.log("[Crypto], getCustomerId [error]: " + error);
    throw handleServiceError(error);
  }
};

export const GetPuntosCrypto = () => {
  try {
    let response = fetchNoJsonRes("/api/liriumusuario/puntosporcrypto", {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    throw new Error("[Crypto], getCustomerId [error]: " + error);
  }
};

export const getPersonaByIdUsuario = (idUsuario: number | undefined) => {
  if (!idUsuario) {
    const user: string | null = sessionStorage.getItem("user");
    if (user) {
      idUsuario = JSON.parse(user).idUsuario;
    }
  }

  try {
    let response = fetchContent(`/api/persona/usuario/${idUsuario}`, {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    throw new Error("[ProductoPage], detalleService error]: " + error);
  }
};

export const getPermisosUsuario = (idUsuario: number | undefined) => {
  if (!idUsuario) {
    const user: string | null = sessionStorage.getItem("user");
    if (user) {
      idUsuario = JSON.parse(user).idUsuario;
    }
  }
  try {
    let response = fetchContent(
      `/api/permiso/usuario/${idUsuario}/entorno/FRONTEND`,
      {
        method: "GET",
      }
    );
    return response;
  } catch (error: any) {
    throw new Error("getPermisosUsuario error]: " + error);
  }
};
