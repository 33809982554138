import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useContext } from 'react';
import { misReconocimientoInterface } from '../../../../../../common/interfaces/HomeInterace';
import { Box, CircularProgress, TableHead } from '@mui/material';
import ES from '../../../../../../common/language/home/es.json';
import EN from '../../../../../../common/language/home/en.json';
import useLanguage from '../../../../../../common/hooks/useLanguage';
import { useQuery } from 'react-query';
import { LanguageContext } from '../../../../../../context/LanguageContext';
import { formatDate } from '../../../../../../utils/datesUtils';
import { modalError } from '../../../../../../utils/validaciones';
import { GetMyReconocimientos } from '../../../../../../services/HomeService';
import { LATEST_RECONOCIMIENTOS } from '../../../../../../common/constants/constants';

export const MisReconocimientosTable = () => {
  const { dictionary } = useLanguage({ EN, ES });
  const language = useContext(LanguageContext);
  const { isLoading: loading, data: ultimosReco } = useQuery({
    queryKey: 'ultimosReco',
    queryFn: async () =>
      GetMyReconocimientos({
        idioma: language.language,
        pagina: LATEST_RECONOCIMIENTOS.page,
        CantidadRegistrosPorPagina: LATEST_RECONOCIMIENTOS.limit,
        ordenamiento: LATEST_RECONOCIMIENTOS.sort,
      }),
    initialData: 0,
    onError: (error: any) => {
      const errorMessage = error instanceof Error ? error.message : 'Error al traer Mis Reconocimientos';
      modalError('Error al traer Mis Reconocimientos\n' + errorMessage);
    },
  });

  return (
    <Box>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#E9E9E9' }}>
              {[dictionary?.tabla.fecha, dictionary?.tabla.tipoReconocimiento, dictionary?.tabla.comentario].map(
                (el) => (
                  <TableCell align="center" key={`header-${el}`} sx={{ fontWeight: 'bold', position: 'relative' }}>
                    <div className="row-header-reco">{el}</div>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading || !ultimosReco ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <CircularProgress />
                  <div>{dictionary?.tabla.loading}</div>
                </TableCell>
              </TableRow>
            ) : ultimosReco.data.length > 0 ? (
              ultimosReco.data.map((row: misReconocimientoInterface) => (
                <TableRow key={`mis-reco-dashboard-${row.idMovimiento}-${row.idReconocimiento}`}>
                  <TableCell align="center" component="th" scope="row">
                    {formatDate(row.fecha)}
                  </TableCell>
                  <TableCell align="center">{row.tituloTipoReconocimiento}</TableCell>
                  <TableCell align="center">{row.descripcion}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell align="center" colSpan={3}>
                {dictionary?.tabla.sinDatos}
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
