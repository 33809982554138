import { useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/material";
import {
  IFiltro,
  bodyProductsInterface,
} from "../../../../../common/interfaces/GiftCardInterfaces";
import useFiltroCatalogo from "../../../../../common/hooks/useFiltroCatalogo";

type Props = {
  paises: IFiltro[] | null;
  limpiar?: boolean;
  filterProducts: bodyProductsInterface;
  setFilterProducts: React.ComponentState;
};

type PropsCheckbox = {
  el: IFiltro;
  limpiar?: boolean;
  filterProducts: bodyProductsInterface;
  setFilterProducts: React.ComponentState;
};

const CheckBoxPais: React.FC<PropsCheckbox> = ({
  el,
  filterProducts,
  setFilterProducts,
}) => {
  const { check, setCheck, handleChangePais } = useFiltroCatalogo({
    filterProducts,
    setFilterProducts,
  });

  useEffect(() => {
    filterProducts.paises &&
    el.id &&
    filterProducts.paises?.includes(el.id.toString())
      ? setCheck(true)
      : setCheck(false);
  }, [filterProducts, el.id, setCheck]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={check}
          size="small"
          sx={{
            height: "10px",
            "&.Mui-checked": {
              color: "paginationColor.main",
            },
          }}
          value={el.id}
          onChange={(e) => handleChangePais(e, el?.id.toString())}
        />
      }
      label={
        <Box component="div" fontSize={13}>
          {el?.descripcionWeb}
        </Box>
      }
    />
  );
};

export const FiltroPais: React.FC<Props> = ({
  paises,
  limpiar,
  filterProducts,
  setFilterProducts,
}) => {
  const [interruptor, setInterruptor] = useState<boolean>(false);

  useEffect(() => {
    setInterruptor(true);
  }, [limpiar]);

  const handleInterruptor = () => {
    setInterruptor(false);
  };

  return (
    <section className="filtro-pais-container">
      <FormGroup onSubmit={() => interruptor && (() => handleInterruptor())}>
        {paises &&
          paises.length > 0 &&
          paises.map((el: IFiltro, index: number) => (
            <CheckBoxPais
              key={`filtro-cat-${el.id}-${index}`}
              filterProducts={filterProducts}
              setFilterProducts={setFilterProducts}
              el={el}
            />
          ))}
      </FormGroup>
    </section>
  );
};
