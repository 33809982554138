import axios from "axios";
// import { URL_BASE_CONST, cliente } from "../constants/constants";
// import camelcaseKeys from "camelcase-keys";
const URL_BASE: string | undefined = process.env.REACT_APP_URL_BASE_CONST;
const httpClient = axios.create({
  baseURL: URL_BASE,
  // baseURL: "",
});

export const Method = {
  GET: "GET",
  PUT: "PUT",
  PATCH: "PATCH",
  POST: "POST",
  DELETE: "DELETE",
};
interface config {
  method?: string;
  headers?: any;
  body?: any;
  data?: any;
}
// AXIOS GENERICO QUE SE USA CUANDO LA RESPUESTA ES UN JSON
export const fetchContent = async (url: string, config: config = {}) => {
  try {
    const { headers: headersOptions } = config;
    const token = sessionStorage.getItem("token");
    // HACEMOS EL TERNARIO PARA VER QUE HEADERS USAMOS, SI CON TOKEN O NO
    const headers = token
      ? {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          ...headersOptions,
        }
      : {
          "Content-Type": "application/json",
          ...headersOptions,
        };

    const { body, ...options } = config;

    const source = axios.CancelToken.source();

    const request = {
      cancelToken: source.token,
      method: Method.GET,
      ...options,
      headers,
      url,
    };

    if (body) {
      request.data = body;
    }

    const promise: any = httpClient.request(request);
    promise.cancel = () => source.cancel("cancelled");
    const { data } = await promise;
    if (data?.error) {
      throw new Error(data.error);
    }
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.status === 401) {
        // sessionStorage.clear()
        window.location.replace("/login?expiredToken=true");
      }
    }
    console.info(error);
    throw error;
  }
};

// FETCH CUANDO LA RESPUESTA NO ES TIPO JSON
export const fetchNoJsonRes = async (url: string, config: config = {}) => {
  try {
    const { headers: headersConfig, ...rest } = config;
    const token = sessionStorage.getItem("token");
    const headers = token
      ? {
          ...headersConfig,
          Authorization: `Bearer ${token}`,
        }
      : { ...headersConfig };

    const res = await fetch(`${URL_BASE}${url}`, { ...rest, headers: headers });

    return res;
  } catch (error) {
    console.info(error);
    if (axios.isAxiosError(error)) {
      if (error.status === 401) {
        window.location.replace("/login?expiredToken=true");
      }
    }
    throw error;
  }
};
