import Typography from "@mui/material/Typography";
import ShortLine from "../../../components/ShortLine/ShortLine";
import { Box, Grid, MenuItem, Select, TextField } from "@mui/material";
import "./ComprarCrypto.css";
import { Link } from "react-router-dom";
import ExchangeFormContainer from "../components/ExchangeFormContainer/ExchangeFormContainer";
import OrderCompraExchange from "./components/OrderCompraExchange";
import ButtonSubmit from "../../../components/ButtonSubmit/ButtonSubmit";
import useCompraCripto from "../../../common/hooks/useCompraCripto";

export const ComprarCrypto = () => {
  const {
    moneda,
    textFields,
    dataExchange,
    okForm,
    puntosPersona,
    orden,
    dictionary,
    handleChange,
    handleChangePuntos,
    handleSubmit,
  } = useCompraCripto();

  return (
    <Grid container columns={12} paddingTop={4} paddingX={2}>
      <Grid item xs={12}>
        <Typography variant="h4" color="primary" align="left">
          {dictionary?.comprar.titulo}
          <ShortLine position="left" />
        </Typography>
      </Grid>
      {/* FORMULARIO EXCHANGE COMPRA */}
      {!okForm ? (
        <ExchangeFormContainer>
          {/* select */}
          <Box
            borderBottom={2}
            borderColor={"primary.main"}
            className="compra-crypto-select-container">
            <Typography variant="h6" color="primary" width={"150px"}>
              {dictionary?.comprar.selectorCompra}
            </Typography>
            <Select
              sx={{ width: "240px" }}
              value={moneda}
              onChange={handleChange}
              inputProps={{ "aria-label": "Without label" }}>
              <MenuItem value="default">
                {dictionary?.index.seleccioneMoneda}
              </MenuItem>
              {dataExchange &&
                dataExchange.data?.quotes.map((el: any) => (
                  <MenuItem
                    key={`menu-item-${el.currency}`}
                    value={el.currency}>
                    {el.currency}
                  </MenuItem>
                ))}
            </Select>
          </Box>
          {/* puntos disponibles */}
          <Typography variant="subtitle1" color="initial">
            {dictionary?.js.puntosDisponibles.replace("{n}", puntosPersona)}
          </Typography>
          <Box className="crypto-text-field">
            <TextField
              id="outlined-helperText"
              type="number"
              helperText={dictionary?.comprar.formCompra.cantidadPuntos}
              onChange={(e) => handleChangePuntos(e)}
              sx={{ input: { color: "primary.main", fontSize: "20px" } }}
            />
            <TextField
              id="outlined-helperText"
              type="number"
              value={textFields.crypto}
              select={false}
              helperText={dictionary?.comprar.formCompra.infoValores}
              sx={{ input: { color: "primary.main", fontSize: "20px" } }}
            />
          </Box>

          <ButtonSubmit onClick={handleSubmit}>
            {dictionary?.comprar.formCompra.siguiente}
          </ButtonSubmit>
          <div className="enlace-vender-reenvio">
            <Typography variant="subtitle1" color="primary">
              {dictionary?.comprar.formCompra.quieresVender}
            </Typography>
            <Link to="/crypto/venta">
              <Typography
                variant="subtitle1"
                color="primary"
                fontWeight={"bold"}>
                &nbsp;{dictionary?.comprar.formCompra.linkVenta}
              </Typography>
            </Link>
          </div>
        </ExchangeFormContainer>
      ) : (
        <OrderCompraExchange orden={orden} textFields={textFields} />
      )}
    </Grid>
  );
};
