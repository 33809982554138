import { Box, Grid } from "@mui/material";
import React from "react";

type Props = {
  position?: string;
};

const ShortLine: React.FC<Props> = ({ position }) => {
  return (
    <Grid
      container
      sx={{ display: "flex", padding: "10px 0 35px 0" }}
      justifyContent={position ? position : "center"}>
      <Box
        sx={{ height: "5px", backgroundColor: "primary.main" }}
        width={125}></Box>
    </Grid>
  );
};

export default ShortLine;
