import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { Dispatch, useContext, useEffect, useState } from "react";
import InputForm from "../../../../../components/Inputs/InputForm/InputForm";
import SelectForm from "../../../../../components/Inputs/SelectForm/SelectForm";
import {
  PaisInterface,
  localidadInterface,
  provinciaInterface,
} from "../../../../../common/interfaces/MisDatosInterface";
import useLanguage from "../../../../../common/hooks/useLanguage";
import ES from "../../../../../common/language/crypto/es.json";
import EN from "../../../../../common/language/crypto/en.json";
import { AppContext } from "../../../../../context/AppContext";
import ShortLine from "../../../../../components/ShortLine/ShortLine";
import { DatePickerParentController } from "../../../../../components/DatePickerParentController/DatePickerParentController";
import {
  GetLocalidadByProvinciaForSelect,
  getPaisForSelect,
  getProvinciaForSelect,
} from "../../../../../services/MisDatosService";
import { CustomerDTO } from "../../../../../common/interfaces/CryptoInterface";
import { PostCustomer } from "../../../../../services/CryptoService";
import { validarCamposAltaCripto } from "../../../../../utils/validaciones";

type Props = {
  form: CustomerDTO;
  setForm: Dispatch<React.SetStateAction<any>>;
  setCustomer: Dispatch<React.SetStateAction<any>>;
};
const DatosGeneralesAltaCripto: React.FC<Props> = ({
  form,
  setForm,
  setCustomer,
}) => {
  const [pais, setPais] = useState<PaisInterface[] | null>(null);
  const [provincia, setProvincia] = useState<provinciaInterface[] | null>(null);
  const [localidades, setLocalidades] = useState<localidadInterface[] | null>();
  const { dictionary } = useLanguage({ EN, ES });
  const appContext = useContext(AppContext);

  useEffect(() => {
    getPaisForSelect()
      .then((paisData) => setPais(paisData))
      .catch((err) => setPais(null));
    getProvinciaForSelect()
      .then((provData) => setProvincia(provData))
      .catch((err) => setProvincia(null));
  }, []);

  useEffect(() => {
    form.provincia &&
      form.provincia.trim() !== "" &&
      GetLocalidadByProvinciaForSelect(form.provincia)
        .then((localidadesData) => setLocalidades(localidadesData))
        .catch((err) => setLocalidades(null));
  }, [form.provincia]);

  const handleSubmit = async () => {
    if (validarCamposAltaCripto(form)) {
      await PostCustomer(appContext?.user?.idUsuario, {
        ...form,
        pais: "AR",
        national_id_country: "AR",
        national_id_type: "national_id",
        citizenship: "AR",
      });
    }
  };

  return (
    <section className="alta-cripto-form-container">
      <Typography
        variant="h6"
        color="primary"
        maxWidth={"550px"}
        textAlign={"center"}>
        {dictionary?.create.info}
        <ShortLine position="center" />
      </Typography>
      <Box
        p={"40px 20px"}
        border={3}
        borderColor={"primary.main"}
        maxWidth={"800px"}
        borderRadius={"15px"}
        width={"100%"}>
        {/* datos personales basicos */}
        <Grid container columns={12} spacing={2}>
          <Grid item xs={12} sm={12} md={4} spacing={2}>
            <InputForm
              name="first_name"
              label={dictionary?.create.form1.nombre}
              type="text"
              form={form}
              setForm={setForm}
            />
            <InputForm
              name="middle_name"
              label={dictionary?.create.form1.segundoNombre}
              type="text"
              form={form}
              setForm={setForm}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <InputForm
              name="last_name"
              label={dictionary?.create.form1.apellido}
              type="text"
              form={form}
              setForm={setForm}
            />
            <InputForm
              name="national_id"
              label={dictionary?.create.form1.dni}
              type="text"
              form={form}
              setForm={setForm}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <DatePickerParentController
              name="date_of_birth"
              label={dictionary?.create.form1.fechaNacimiento}
              form={form}
              setForm={setForm}
            />
          </Grid>
        </Grid>
        <Box m={2}>
          <Divider />
        </Box>
        {/* datos de localidad */}
        <Grid container columns={12} spacing={2}>
          <Grid item xs={12} sm={12} md={4} spacing={2}>
            <InputForm
              name="address_line1"
              label={dictionary?.create.form1.direccionCompleta}
              type="text"
              form={form}
              setForm={setForm}
            />
            <SelectForm
              form={form}
              setForm={setForm}
              label={dictionary?.create.form1.ciudad}
              children={
                localidades ? (
                  localidades.map((el: localidadInterface, index) => (
                    <option value={el.id} key={`${el.id}-${index}`}>
                      {el.nombre}
                    </option>
                  ))
                ) : (
                  <></>
                )
              }
              name={"localidad"}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <SelectForm
              form={form}
              setForm={setForm}
              label={dictionary?.create.form1.pais}
              children={
                pais ? (
                  pais.map((el, index) => (
                    <option value={el.id} key={`${el.id}-${index}`}>
                      {el.nombre}
                    </option>
                  ))
                ) : (
                  <></>
                )
              }
              name={"pais"}
            />
            <InputForm
              name="zip_code"
              label={dictionary?.create.form1.codigoPostal}
              type="text"
              form={form}
              setForm={setForm}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <SelectForm
              form={form}
              setForm={setForm}
              label={dictionary?.create.form1.provincia}
              children={
                provincia ? (
                  provincia.map((el, index) => (
                    <option value={el.id} key={`${el}-${index}`}>
                      {el.nombre}
                    </option>
                  ))
                ) : (
                  <></>
                )
              }
              name={"provincia"}
            />
          </Grid>
        </Grid>
        <Box m={2}>
          <Divider />
        </Box>
        {/* datos de contacto */}
        <Grid container columns={12} spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <InputForm
              name="email"
              label={dictionary?.create.form1.email}
              type="email"
              form={form}
              setForm={setForm}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <InputForm
              name="cellphone"
              label={dictionary?.create.form1.telefono}
              type="text"
              form={form}
              setForm={setForm}
            />
          </Grid>
        </Grid>
        <Box textAlign={"center"} pt={5}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => handleSubmit()}>
            {dictionary?.create.form1.siguiente}
          </Button>
        </Box>
      </Box>
    </section>
  );
};

export default DatosGeneralesAltaCripto;
