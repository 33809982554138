import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { NivelEducacionVideoDTO } from "../../../../common/interfaces/EducacionFinancieraInterface";
import { useEffect } from "react";
import {
  GetVideoByIdNivelEducacionVideo,
  PostNivelEducacionVideoUsuario,
  PutNivelEducacionVideoUsuario,
} from "../../../../services/EducacionFinancieraService";
import { AppContext } from "../../../../context/AppContext";
import CloseIcon from "@mui/icons-material/Close";
import ES from "../../../../common/language/educacion/es.json";
import EN from "../../../../common/language/educacion/en.json";
import useLanguage from "../../../../common/hooks/useLanguage";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "85%", sm: "70%", md: "50%" },
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 2,
};

type Props = {
  cardInfo: NivelEducacionVideoDTO;
  eneable: boolean;
};

export const ModalVideo = ({ cardInfo, eneable }: Props) => {
  const [open, setOpen] = React.useState(false);
  const [videoInfo, setVideoInfo] = React.useState<NivelEducacionVideoDTO>();
  const [duracion, setDuracion] = React.useState<number>(0);
  //   const [currentTime, setCurrentTime] = React.useState<number>(0);
  const { dictionary } = useLanguage({ EN, ES });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    eneable &&
      GetVideoByIdNivelEducacionVideo(cardInfo?.idNivelEducacionVideo).then(
        (data) => setVideoInfo(data)
      );
  }, [cardInfo?.idNivelEducacionVideo, eneable]);

  const handlePlay = (e: any) => {
    setDuracion(e.target.duration);
    videoInfo?.idNivelEducacionVideoUsuario === 0 &&
      PostNivelEducacionVideoUsuario(
        videoInfo,
        appContext?.user?.idUsuario
      ).then((data) =>
        setVideoInfo({
          ...videoInfo,
          idNivelEducacionVideoUsuario: data.ididNivelEducacionVideoUsuario,
        })
      );
  };

  const appContext = React.useContext(AppContext);

  const handleTimeUpdate = (e: any) => {
    const currentTime = e.target.currentTime;

    const avance = (currentTime / duracion) * 100;
    if (
      avance >=
        (videoInfo?.porcentajeFinalizado
          ? videoInfo?.porcentajeFinalizado
          : 90) &&
      videoInfo?.estado !== "FINALIZADO"
    ) {
      const actualizar = {
        tiempoTranscurrido: currentTime,
        estado: "FINALIZADO",
      };
      setVideoInfo({
        ...videoInfo,
        ...actualizar,
      });
      PutNivelEducacionVideoUsuario(
        { ...videoInfo, ...actualizar },
        appContext?.user?.idUsuario
      );
    }
  };

  const handlePause = (e: any) => {
    if (videoInfo?.estado === "INICIADO") {
      if (
        e.target.currentTime >
        (videoInfo?.tiempoTranscurrido ? videoInfo?.tiempoTranscurrido : 0)
      ) {
        setVideoInfo({
          ...videoInfo,
          tiempoTranscurrido: e.target.currentTime,
        });
        PutNivelEducacionVideoUsuario(
          { ...videoInfo, tiempoTranscurrido: e.target.currentTime },
          appContext?.user?.idUsuario
        );
      }
    }
  };

  return (
    <div>
      <div onClick={handleOpen}>
        {dictionary?.nivel.nivelVideo} {cardInfo.idNivelEducacion}
      </div>
      {eneable && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style}>
            <Button
              variant="text"
              color="primary"
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
              onClick={() => setOpen(false)}>
              <CloseIcon />
            </Button>
            <Typography variant="h6" color="primary" pb={2}>
              {dictionary?.nivel.nivelVideo} {cardInfo.idNivelEducacion}
            </Typography>
            <video
              controls
              width={"100%"}
              onPlay={(e) => handlePlay(e)}
              onTimeUpdate={(e) => handleTimeUpdate(e)}
              onPause={(e) => handlePause(e)}>
              <source src={cardInfo.url} type="video/mp4" />
              Sorry, your borwser doesn't support videos
            </video>
          </Box>
        </Modal>
      )}
    </div>
  );
};
