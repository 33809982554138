import { Box, Grid } from "@mui/material";
import React from "react";
import "./MisDatosContainerForm.css";
type Props = {
  children: React.ReactNode;
};

const MisDatosContainerForm: React.FC<Props> = ({ children }) => {
  return (
    <Grid item xs={12} className="mis-datos-section-form">
      <Box
        className="mis-datos-container-form"
        border={3}
        borderColor={"primary.main"}>
        {children}
      </Box>
    </Grid>
  );
};

export default MisDatosContainerForm;
