import { headerOptions } from "../common/constants/constants";
import { formularioContactoInterface } from "../common/interfaces/ContactInterface";
import { fetchContent } from "../utils/fetchUtils";

export const motivoService = (lang: string) => {
  try {
    let response = fetchContent(
      `/api/contactanos/motivoscontacto?idioma=${lang}`,
      {
        method: "GET",
      }
    );
    return response;
  } catch (error: any) {
    throw new Error("[Contact page], motivoService error]: " + error);
  }
};

export const contactFormService = (body: formularioContactoInterface, lang: string) => {
  try {
    let response = fetchContent(`/api/notificacion/Contactanos?idioma=${lang}`, {
      method: "POST",
      headers: headerOptions,
      body: body,
    });
    return response;
  } catch (error: any) {
    throw new Error("[GigtCard], contactFormService error]: " + error);
  }
};
