import { fetchContent } from "../utils/fetchUtils";

export const GetClienteDatosFooter = async (idCliente: number) => {
  try {
    let response = await fetchContent(`/api/Cliente/${idCliente}/datosfooter`, {
      method: "GET",
    });

    return response;
  } catch (error: any) {
    throw new Error("[FooterPage], FooterService error]: " + error);
  }
};
