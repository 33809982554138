import { ReconocimientoInterface } from '../common/interfaces/ReconocimientoInterface';
import { Method, fetchContent } from '../utils/fetchUtils';
import { setQueryParams } from '../utils/functions';
import { handleServiceError } from './GiftCardService';

interface Props {
  userId: number;
  idioma: string;
  pagina: number;
  ordenamiento: string;
  CantidadRegistrosPorPagina: number;
  usuario?: string;
}

export const GetReconocimientoBySupervisor = async (props: Props): Promise<ReconocimientoInterface[]> => {
  const url = setQueryParams('api/reconocimiento/supervisor', props);
  try {
    let response = await fetchContent(url, {
      method: 'GET',
    });
    return response;
  } catch (error) {
    console.log('GetReconocimientoBySupervisor error: ' + error);
    throw handleServiceError(error);
  }
};

export const putReconocimientoAprobar = async (
  reconocimiento: ReconocimientoInterface,
  cliente: number,
  idPersona: number | null | undefined,
  idUsuario: number | undefined
) => {
  if (reconocimiento?.aprobados) {
    const reconocimientos =
      reconocimiento?.aprobados?.map((item) => ({
        idReconocimiento: item,
        idCliente: cliente,
        estado: 'APROBADO',
        fechaAprobacion: new Date(Date.now()).toISOString(),
        idUsuarioSupervisor: idUsuario,
      })) ?? [];

    for (const reco of reconocimientos) {
      try {
        await fetchContent('api/reconocimiento/aprobar', {
          method: Method.POST,
          body: reco,
          headers: {
            'x-api-version': 2.0,
          },
        });
        // TODO: REVISAR MSJ DE ERROR
        // notification.success({
        //   message: "Aprobación exitosa",
        //   description: "El reconocimiento ha sido aprobado correctamente.",
        // });
      } catch (error: any) {
        if (error.response.status === 422) {
          // notification.error({
          //   message: "Error al aprobar",
          //   description:
          //     "El total de puntos a reconocer es mayor al del presupuesto",
          // });
          throw handleServiceError(error);
        } else {
          // notification.error({
          //   message: "Error al aprobar",
          //   description: "Ha ocurrido un error al aprobar el reconocimiento.",
          // });
          throw handleServiceError(error);
        }
      }
    }
  }
};
