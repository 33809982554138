import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Grid, Hidden } from "@mui/material";
import "./Login.css";
import { LoginFormContainer } from "./components/LoginFormContainer/LoginFormContainer";

interface logoInterface {
  logoUrl: string;
  tipoImagenLogo: string;
  logo: string;
}

type Props = {
  theme: any;
  logoProp: logoInterface;
};

const Login: React.FC<Props> = ({ theme, logoProp }) => {
  const [logo, setLogo] = useState<logoInterface>({
    tipoImagenLogo: "",
    logo: "",
    logoUrl: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    setLogo(logoProp);
  }, [logoProp]);

  return (
    <>
      <Grid container sm={12} sx={{ height: "100vh" }} fontStyle="primary">
        <Hidden mdDown>
          <Grid
            item
            md={6}
            className="grid-gradient"
            sx={
              theme
                ? {
                    background: `linear-gradient(
            ${theme[9]?.color},
            ${theme[10]?.color}
            ${theme[11]?.color},
            ${theme[12]?.color}
            ${theme[13]?.color},
            ${theme[14]?.color}
            ${theme[15]?.color}
          )`,
                  }
                : {
                    background:
                      "linear-gradient(9deg, rgba(2,0,36,1) 0%, rgba(104,56,247,1) 0%, rgba(252,252,252,1) 0%, rgba(0,212,255,1) 35%)",
                  }
            }>
            <img
              className="logo-login-desktop"
              onClick={() => navigate("/")}
              src={logo?.logoUrl}
              alt="logo kiwin"
            />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <LoginFormContainer>
            <Outlet />
          </LoginFormContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
