import DesktopAccessDisabledIcon from "@mui/icons-material/DesktopAccessDisabled";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Box, Typography } from "@mui/material";
import "./ErrorPage.css";

const ErrorPage = () => {
  return (
    <div className="error-page-section">
      <div>
        <Box color={"primary.main"} sx={{ position: "relative" }}>
          <DesktopAccessDisabledIcon sx={{ fontSize: "100px" }} />
          <Box color={"error.main"}>
            <ErrorOutlineOutlinedIcon
              sx={{ fontSize: "75px" }}
              className="error-page-explamation"
            />
          </Box>
        </Box>
      </div>
      <Typography variant="h4" color="primary">
        ERROR PAGE NOT FOUND
      </Typography>
    </div>
  );
};

export default ErrorPage;
