import { useState, useEffect, useContext } from "react";
import MenuItem from "@mui/material/MenuItem";
import { ORDENAMIENTO_OPCIONES } from "../../../../common/constants/constants";
import { bodyProductsInterface } from "../../../../common/interfaces/GiftCardInterfaces";
import ES from "../../../../common/language/catalogo/es.json";
import EN from "../../../../common/language/catalogo/en.json";
import useLanguage from "../../../../common/hooks/useLanguage";
import { AppContext } from "../../../../context/AppContext";
import "./style.css";
import { Select } from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ThemeContext } from "../../../../context/ThemeContext";

type Props = {
  limpiar: boolean;
  filterProducts: bodyProductsInterface;
  setFilterProducts: React.ComponentState;
};

export const SelectCatalogo: React.FC<Props> = ({
  limpiar,
  filterProducts,
  setFilterProducts,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const appContext = useContext(AppContext);
  const { dictionary } = useLanguage({ EN, ES });
  const themeContext = useContext(ThemeContext);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    setIsFocused(false);
  }, [filterProducts.ordenamiento]);

  const handleChange = (event: any) => {
    setIsFocused(false);
    setFilterProducts({
      ...filterProducts,
      ordenamiento: event.target.value,
    });
  };

  return (
    <div>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        value={filterProducts.ordenamiento}
        IconComponent={() =>
          isFocused ? <ExpandLessIcon /> : <ExpandMoreIcon />
        }
        sx={{
          width: "190px",
          "&.MuiOutlinedInput-root": {
            fontFamily: "Poppins, sans-serif",
            fontWeight: 400,
            fontSize: "18px",
            color: "var(--color-light-gray)",
            border: "none",
            "&.Mui-focused fieldset": {
              fontFamily: "Poppins, sans-serif",
              border: "none",
            },
          },
          fieldset: {
            border: "none",
          },
        }}>
        {appContext?.user
          ? ORDENAMIENTO_OPCIONES.map((el) => (
              <MenuItem
                value={el.value}
                key={`menu-item-catalogo-${el.value}`}
                style={{
                  width: "230px",
                  color:
                    el.value === filterProducts.ordenamiento
                      ? themeContext?.theme && themeContext?.theme[0]
                        ? themeContext.theme[0].color
                        : "var(--color-light-gray)"
                      : "var(--color-light-gray)",
                  fontWeight:
                    el.value === filterProducts.ordenamiento ? 700 : 200,
                  fontFamily: "Poppins, sans-serif",
                }}
                divider={true}>
                {dictionary?.ordenarPor[el.dictionarySearch]}
              </MenuItem>
            ))
          : ORDENAMIENTO_OPCIONES.filter(
              (el) =>
                el.value !== "PuntosCanje asc" &&
                el.value !== "PuntosCanje desc"
            ).map((el) => (
              <MenuItem
                value={el.value}
                key={`menu-item-catalogo-${el.value}`}
                style={{
                  width: "230px",
                  color:
                    el.value === filterProducts.ordenamiento
                      ? themeContext?.theme && themeContext?.theme[0]
                        ? themeContext.theme[0].color
                        : "var(--color-light-gray)"
                      : "var(--color-light-gray)",
                  fontWeight:
                    el.value === filterProducts.ordenamiento ? 700 : 200,
                  fontFamily: "Poppins, sans-serif",
                }}
                divider={true}>
                {dictionary?.ordenarPor[el.dictionarySearch]}
              </MenuItem>
            ))}
      </Select>
    </div>
  );
};
