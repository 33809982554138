import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./PreLoginProducto.css";
import ES from "../../../../common/language/giftcard/es.json";
import EN from "../../../../common/language/giftcard/en.json";
import useLanguage from "../../../../common/hooks/useLanguage";

const PreLoginProducto = () => {
  const navigate = useNavigate();

  const { dictionary } = useLanguage({ EN, ES });

  return (
    <Grid item sm={12}>
      <Box className="Pre-login-producto-container">
        <Typography variant="h6" color="initial">
          {dictionary?.notLogged}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/login")}>
          {dictionary?.notLoggedButton}
        </Button>
      </Box>
    </Grid>
  );
};

export default PreLoginProducto;
