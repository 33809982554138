import { Box } from "@mui/material";
import React from "react";
import { FooterInterface } from "../../../common/interfaces/FooterInterface";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import "./FooterRedes.css";

type Props = {
  footerInfo: FooterInterface | null;
};
const FooterRedes: React.FC<Props> = ({ footerInfo }) => {
  return (
    <Box
      className="footer-redes-section"
      justifyContent={{ xs: "flex-end", sm: "flex-end", md: "center" }}>
      {footerInfo?.urlFacebook && (
        <Box color={"white"}>
          <a href={footerInfo?.urlFacebook} target="_blank" rel="noreferrer">
            <FacebookIcon />
          </a>
        </Box>
      )}
      {footerInfo?.urlTwiter && (
        <Box color={"white"}>
          <a href={footerInfo?.urlTwiter} target="_blank" rel="noreferrer">
            <TwitterIcon />
          </a>
        </Box>
      )}
      {footerInfo?.urlInstagram && (
        <Box color={"white"}>
          <a href={footerInfo?.urlInstagram} target="_blank" rel="noreferrer">
            <InstagramIcon />
          </a>
        </Box>
      )}
      {footerInfo?.urlLinkedin && (
        <Box color={"white"}>
          <a
            href={footerInfo?.urlLinkedin ? footerInfo?.urlLinkedin : ""}
            target="_blank"
            rel="noreferrer">
            <LinkedInIcon />
          </a>
        </Box>
      )}
    </Box>
  );
};

export default FooterRedes;
