import React, { useEffect, useState } from 'react';
import { TipoReconocimientoInterface } from '../../../../../common/interfaces/ReconocimientoInterface';
import fondoDefecto from '../../../../../common/assets/images/Reconocimientos/fondoDefecto.jpg';
import MiniLoader from '../../../../../components/Loaders/MiniLoader/MiniLoader';
import { formatNumber } from '../../../../../utils/functions';
import './ReconocimientoCard.css';

type Props = {
  data: TipoReconocimientoInterface;
  form: any;
  setForm: any;
};

const ReconocimientoCard: React.FC<Props> = ({ data, form, setForm }) => {
  const [clicked, setClicked] = useState(false);
  const [loaded, setLoaded] = useState(false);

  function onLoad() {
    setLoaded(true);
  }

  const handleClick = () => {
    setForm({
      ...form,
      idTipoReconocimiento: data.idTipoReconocimiento,
    });
  };

  useEffect(() => {
    form.idTipoReconocimiento && data.idTipoReconocimiento === form.idTipoReconocimiento
      ? setClicked(true)
      : setClicked(false);
  }, [form.idTipoReconocimiento, data.idTipoReconocimiento]);

  return (
    <div
      className={`tipo-reconocimiento-card ${clicked && 'tipo-reconocimiento-card--active'}`}
      onClick={() => handleClick()}
    >
      <img
        style={{ display: loaded ? 'block' : 'none' }}
        onLoad={onLoad}
        src={data?.imagen ?? fondoDefecto}
        alt={data?.titulo}
        onError={(e) => e.currentTarget.setAttribute('src', fondoDefecto)}
      />
      {!loaded && <MiniLoader />}

      <div className="reconocimiento-card-texto">
        <div className="reconocimiento-card-title">{data.titulo}</div>
        <div style={{ fontWeight: 600 }}>{data?.puntos > 0 ? formatNumber(data.puntos) : 'Sin'} puntos</div>
      </div>
    </div>
  );
};

export default ReconocimientoCard;
