import { Box, Typography } from '@mui/material';
import ShortLine from '../../../components/ShortLine/ShortLine';
import { ReconocimientosAprobadosTable } from './ReconocimientosAprobadosTable';
import useAprobarReconocimiento from '../../../common/hooks/useAprobarReconocimiento';
import ButtonSubmit from '../../../components/ButtonSubmit/ButtonSubmit';
import CustomSearch from '../../../components/CustomSearch/CustomSearch';

const AprobacionReconocimiento = () => {
  const {
    recoSearch,
    loading,
    dictionary,
    form,
    page,
    totalPages,
    orden,
    setForm,
    handleSubmit,
    setPage,
    cambiarOrdenamiento,
    setSearch,
  } = useAprobarReconocimiento();
  
  return (
    <Box className="reconocimientos-realizados-page">
      <Typography variant="h4" color="primary">
        {dictionary?.reconocimientosAprobados}
        <ShortLine position="left" />
      </Typography>
      <Box
        border={2}
        borderColor={'primary.main'}
        borderRadius={3}
        className="reconocimientos-realizados-table-container"
      >
        <CustomSearch setSearch={setSearch} setPage={setPage} placeHolder={dictionary?.formBusqueda.placeHolder}  />
        <ReconocimientosAprobadosTable
          recoRealizados={recoSearch}
          form={form}
          setForm={setForm}
          loading={loading}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          orden={orden}
          cambiarOrdenamiento={cambiarOrdenamiento}
          dictionary={dictionary}
        />
        {recoSearch && recoSearch.length > 0 && recoSearch.some((el) => el.estado !== 'APROBADO') && (
          <Box textAlign={'right'}>
            <ButtonSubmit disabled={form.aprobados.length === 0} onClick={handleSubmit}>
              {dictionary?.form.aprobar}
            </ButtonSubmit>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AprobacionReconocimiento;
