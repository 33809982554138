import { useState } from "react";
import { bodyProductsInterface } from "../../common/interfaces/GiftCardInterfaces";

const useFiltroCatalogo = ({
  filterProducts,
  setFilterProducts,
}: {
  filterProducts: bodyProductsInterface;
  setFilterProducts: React.Dispatch<
    React.SetStateAction<bodyProductsInterface>
  >;
}) => {
  const [check, setCheck] = useState<boolean>(false);

  const handleChangePais = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const isChecked = event.target.checked;
    const newPaises = isChecked
      ? [...(filterProducts.paises || []), id]
      : filterProducts.paises?.filter((item) => item !== id);

    setFilterProducts({
      ...filterProducts,
      paises: newPaises,
      pagina: 1,
    });
  };

  const handleChangeCategoria = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const isChecked = event.target.checked;
    const newCategorias = isChecked
      ? [...(filterProducts.categorias || []), id]
      : filterProducts.categorias?.filter((item) => item !== id);

    setFilterProducts({
      ...filterProducts,
      categorias: newCategorias,
      pagina: 1,
    });
  };

  const handleChangeMarca = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const isChecked = event.target.checked;
    const newMarcas = isChecked
      ? [...(filterProducts.marcas || []), id]
      : filterProducts.marcas?.filter((item) => item !== id);

    setFilterProducts({
      ...filterProducts,
      marcas: newMarcas,
      pagina: 1,
    });
  };

  const handleChangeTipoProducto = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const isChecked = event.target.checked;
    const newTipoProductos = isChecked
      ? [...(filterProducts.tipoProductos || []), id]
      : filterProducts.tipoProductos?.filter((item) => item !== id);

    setFilterProducts({
      ...filterProducts,
      tipoProductos: newTipoProductos,
      pagina: 1,
    });
  };

  return {
    check,
    setCheck,
    handleChangePais,
    handleChangeCategoria,
    handleChangeMarca,
    handleChangeTipoProducto,
  };
};

export default useFiltroCatalogo;
