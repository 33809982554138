import { useDispatch, useSelector } from "react-redux";
import { removeItem } from "../../common/redux/features/carritoReducer";
import { disminuirPorCantidad } from "../../common/redux/features/contadorItemReducer";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../common/redux/store";
import { useState } from "react";
import useLanguage from "./useLanguage";
import ES from "../../common/language/catalogo/es.json";
import EN from "../../common/language/catalogo/en.json";

const useCarrito = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { dictionary } = useLanguage({ EN, ES });

  const { contadorItem } = useSelector(
    (state: RootState) => state.contadorItem
  );
  const carrito = useSelector((state: RootState) => state.carrito);
  const navigate = useNavigate();

  const handleRemove = (index: number, cantidad: number) => {
    dispatch(removeItem(index));
    dispatch(disminuirPorCantidad(cantidad));
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(!open);
    };

  return {
    open,
    setOpen,
    contadorItem,
    carrito,
    handleRemove,
    navigate,
    toggleDrawer,
    dictionary,
  };
};

export default useCarrito;
