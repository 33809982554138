import { useState, useContext } from "react";
import useLanguage from "../../../../../common/hooks/useLanguage";
import ES from "../../../../../common/language/crypto/es.json";
import EN from "../../../../../common/language/crypto/en.json";
import { Box, Typography, Button } from "@mui/material";
import ShortLine from "../../../../../components/ShortLine/ShortLine";
import step1 from "../../../../../common/assets/images/Crypto/crypto_step1.png";
import loadImg from "../../../../../common/assets/images/Crypto/crypto_input_file.png";
import "./AltaCriptoFotoFrente.css";
import { PostFile } from "../../../../../services/CryptoService";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../../context/AppContext";
import { modalError } from "../../../../../utils/validaciones";

const AltaCriptoFotoFrente = () => {
  const { dictionary } = useLanguage({ EN, ES });
  const appContext = useContext(AppContext);
  const [img, setImg] = useState();
  const navigate = useNavigate();

  const handleImageChange = (e: any) => {
    setImg(e.target.value);
  };

  const handleSubmit = async () => {
    const res = await PostFile("id_front", appContext?.user?.idUsuario, img);
    res
      ? navigate("/crypto/alta/step2")
      : modalError("Error al procesar la imagen");
  };

  return (
    <div className="alta-lirium-step-container">
      <Typography
        variant="h6"
        color="primary"
        maxWidth={"550px"}
        textAlign={"center"}>
        {dictionary?.create.info}
        <ShortLine position="center" />
      </Typography>
      <div className="alta-lirium-img-container">
        <img src={step1} alt="agrega foto frente" />
        <Typography variant="subtitle1" color="primary">
          {dictionary?.create.form2.fotoDniFrente}
        </Typography>
      </div>
      <div>
        <div className="file-load-input-container">
          <img src={loadImg} alt="loading" width={"40px"} />
          <p>{dictionary?.create.form2.click}</p>
          <input
            type="file"
            accept="image/*"
            className="file-load-input"
            onChange={handleImageChange}
          />
        </div>
      </div>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}>
          {dictionary?.create.form2.siguiente}
        </Button>
      </Box>
    </div>
  );
};

export default AltaCriptoFotoFrente;
