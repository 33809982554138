import React, { createContext, useState } from "react";
import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  PaletteColorOptions,
} from "@mui/material/";

type ContextType = {
  theme: any;
  setTheme: React.Dispatch<React.SetStateAction<never[]>>;
};

export type ThemeContextProviderProps = {
  children: React.ReactNode;
};

export const ThemeContext = createContext<ContextType | null>(null);

export const AppThemeProvider = ({ children }: ThemeContextProviderProps) => {
  const [theme, setTheme] = useState([]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

//
// THEME DE MATERIAL UI
type ThemeProp = {
  colors: RootObject[] | null;
  children: JSX.Element;
};

interface RootObject {
  idColorEstilo: number;
  idCliente: number;
  orden: number;
  control: string;
  color: string;
  idClienteNavigation?: any;
}

declare module "@mui/material/styles" {
  interface Palette {
    btnPrimary: PaletteColorOptions;
    btnSecundary: PaletteColorOptions;
    txtHeader: PaletteColorOptions;
    paginationColor: PaletteColorOptions;
  }
  interface PaletteOptions {
    btnPrimary: PaletteColorOptions;
    btnSecundary: PaletteColorOptions;
    txtHeader: PaletteColorOptions;
    paginationColor: PaletteColorOptions;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    btnPrimary: true;
    btnSecundary: true;
    paginationColor: true;
  }
}
declare module "@mui/material/Button" {
  interface BoxPropsColorOverrides {
    btnPrimary: true;
    btnSecunday: true;
    paginationColor: true;
  }
}

declare module "@mui/material/Divider" {
  interface BoxPropsColorOverrides {
    btnPrimary: true;
    btnSecunday: true;
  }
}

declare module "@mui/material/Typography" {
  interface typographyOverrides {
    txtHeader: true;
  }
}

export const ThemeConfig: React.FC<ThemeProp> = ({ colors, children }) => {
  const { palette } = createTheme();
  const theme = createTheme({
    palette: {
      primary: {
        main: colors ? colors[0].color : "#005984",
      },
      secondary: {
        main: colors ? colors[1].color : "#00ffa6",
      },
      btnPrimary: palette.augmentColor({
        color: {
          main: colors ? colors[3].color : "#111125",
          light: "transparent",
          contrastText: "white",
        },
      }),
      paginationColor: palette.augmentColor({
        color: {
          main: colors && colors[10] ? colors[10].color : "#005984",
        },
      }),
      btnSecundary: palette.augmentColor({
        color: {
          main: colors ? colors[4].color : "#111125",
          light: "transparent",
        },
      }),
      txtHeader: palette.augmentColor({
        color: {
          main: "#111125",
        },
      }),
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins, sans-serif",
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins, sans-serif",
            borderRadius: "15px",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "12px",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
