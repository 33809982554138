import { Box, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { LineTitle } from "../../../Dashboard/styles";
import ShortLine from "../../../../../../components/ShortLine/ShortLine";
import { useNavigate } from "react-router-dom";

type Props = {
  title: String;
  children: ReactElement;
  color?: string;
  ruta?: string;
  maxHeight?: number | string;
};

const DashboardBox: React.FC<Props> = ({ title, children, color, ruta }) => {
  const navigate = useNavigate();
  return (
    <Box
      bgcolor={color === "white" ? "white" : "primary.main"}
      border={2}
      borderColor={"primary.main"}
      onClick={() => {
        ruta && navigate(ruta);
      }}
      sx={{
        cursor: "pointer",
        flexDirection: "column",
        minHeight: "180px",
        height: "fit-content",
        padding: "20px",
        borderRadius: "10px",

        "&:hover": {
          backgroundColor: color === "white" ? "#e5e4e8" : "primary.dark",
          transform: "scale(1.008)",
        },
      }}>
      <Typography
        variant="h6"
        color={color === "white" ? "primary" : "white"}
        textTransform="capitalize">
        {title}
        {color === "white" ? <ShortLine position="left" /> : <LineTitle />}
      </Typography>
      {children}
    </Box>
  );
};

export default DashboardBox;
