import PreLoginCrypto from "./components/PreLoginCrypto/PreLoginCrypto";
import LoginCrypto from "./components/LoginCrypto/LoginCrypto";
import { Loading } from "../../components";
import useCripto from "../../common/hooks/useCripto";

const Crypto = () => {
  const { customer, liriumStatus, appContext } = useCripto("");

  return (
    <>
      {liriumStatus.isLoading ? (
        <Loading />
      ) : appContext?.user && customer && liriumStatus ? (
        <LoginCrypto />
      ) : (
        <PreLoginCrypto />
      )}
    </>
  );
};

export default Crypto;
