import { Box, CircularProgress } from "@mui/material";

const MiniLoader = () => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}>
      <CircularProgress size={50} />
    </Box>
  );
};

export default MiniLoader;
