import { Box, SvgIcon, Typography } from "@mui/material";

const CustomOffer = ({ offer }) => {
  return (
    <Box>
      <SvgIcon
        sx={{
          color: "primary.main",
          position: "absolute",
          top: -14,
          right: 0,
          fontSize: "120px",
        }}>
        <svg
          viewBox="0 0 231 188"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          stroke="currentColor">
          <path
            d="M129.5 0.5H1.5L230 186.5V82L129.5 0.5Z"
            fill="currentColor"
            stroke="currentColor"
          />
        </svg>
      </SvgIcon>
      <Typography
        sx={{
          position: "absolute",
          top: 25,
          right: 0,
          color: "white",
          zIndex: 500,
          fontSize: "18px",
          fontWeight: 600,
          transform: "rotate(42deg)",
        }}>
        {offer} OFF
      </Typography>
    </Box>
  );
};
export default CustomOffer;
