import { SelectChangeEvent } from "@mui/material";
import { useContext, useState } from "react";
import {
  GetImporteByPuntos,
  postOrder,
} from "../../services/ComprarCryptoService";
import { cliente } from "../../common/constants/constants";
import { AppContext } from "../../context/AppContext";
import useLanguage from "./useLanguage";
import ES from "../../common/language/crypto/es.json";
import EN from "../../common/language/crypto/en.json";
import useCripto from "./useCripto";
import { Order } from "../../common/interfaces/CryptoInterface";
import {
  cambiarPuntosPorCripto,
  evaluarCamposCompraCrypto,
  evaluarPuntosCriticos,
} from "../../utils/functions";
import useHome from "./useHome";
import { validarPostOrder } from "../../utils/validaciones";

const useCompraCripto = () => {
  const [moneda, setMoneda] = useState<string>("BTC");
  const [textFields, setTextFields] = useState({
    puntos: 0,
    crypto: 0,
  });
  const [importe, setImporte] = useState<string>("");
  const [okForm, setForm] = useState(false);
  const [orden, setOrden] = useState<Order>();

  const { dictionary } = useLanguage({ EN, ES });
  const appContext = useContext(AppContext);
  const { dataExchange } = useCripto("");
  const { puntos: puntosPersona } = useHome();

  const handleChange = (event: SelectChangeEvent) => {
    setMoneda(event.target.value);
    GetImporteByPuntos(
      cliente,
      appContext?.user?.idUsuario,
      11,
      textFields.puntos
    )
      .then((res) => res.json())
      .then((data) => {
        setImporte(data);
        setTextFields({
          ...textFields,
          crypto: cambiarPuntosPorCripto(
            data,
            dataExchange.data,
            event.target.value
          ),
        });
      });
  };

  const handleChangePuntos = (event: any) => {
    evaluarPuntosCriticos(event, puntosPersona.data) &&
      (event.target.value = 1);
    event.target.value =
      event.target.value > puntosPersona ? puntosPersona : event.target.value;
    GetImporteByPuntos(
      cliente,
      appContext?.user?.idUsuario,
      11,
      event.target.value
    )
      .then((res) => res.json())
      .then((data) => {
        setImporte(data);
        setTextFields({
          puntos: event.target.value,
          crypto: cambiarPuntosPorCripto(data, dataExchange.data, moneda),
        });
      });
  };

  const handleSubmit = async () => {
    if (
      evaluarCamposCompraCrypto(
        textFields.puntos,
        dictionary?.js.completarCampos
      )
    ) {
      let res = await postOrder(
        appContext?.user?.idUsuario,
        moneda,
        importe,
        dictionary
      );

      if (res) {
        validarPostOrder(true, dictionary?.js.generarOrdenSuccess);
        setForm(true);
        setOrden(res);
      } else {
        validarPostOrder(false, dictionary?.js.generarOrdenError);
      }
    }
  };

  return {
    //variables
    moneda,
    textFields,
    importe,
    puntosPersona,
    dataExchange,
    okForm,
    orden,
    dictionary,
    //metodos
    handleChange,
    handleChangePuntos,
    handleSubmit,
  };
};

export default useCompraCripto;
