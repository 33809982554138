import { cliente, headerOptions } from "../common/constants/constants";
import { LiriumUsuario } from "../common/interfaces/ComunInterface";
import {
  CustomerDTO,
  CustomerInterface,
  cryptoValorInterface,
} from "../common/interfaces/CryptoInterface";
import { fetchContent, fetchNoJsonRes } from "../utils/fetchUtils";
import { modificarFecha } from "../utils/functions";
import { handleServiceError } from "./GiftCardService";
import { GetUsuarioLirium } from "./UsuarioService";

export const getMonedasClientes = (idCliente: number) => {
  try {
    let response = fetchContent(`/api/Cliente/moneda/${idCliente}`, {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    throw new Error("[Crypto], getMonedasClientes error]: " + error);
  }
};
export const getValuesForChart = async (
  moneda: string,
  monedasCliente: any
) => {
  const dataMonedaXCrip = await getMonedaXCrypto(
    monedasCliente.alfa3,
    moneda,
    cliente
  );
  const resMod = dataMonedaXCrip.map((el: cryptoValorInterface) => {
    return { ...el, timestamp: modificarFecha(el.timestamp) };
  });

  return resMod;
};

export const getMonedaXCrypto = (
  monedaCliente: string,
  moneda: string,
  idCliente: number
) => {
  try {
    let response = fetchContent(
      `/api/LiriumHistoricalPrice/moneda/${moneda}/period/month/top/7/${monedaCliente}`,
      {
        method: "GET",
      }
    );
    return response;
  } catch (error: any) {
    console.log("[Crypto], getMonedasClientes error]: " + error);
    throw handleServiceError(error);
  }
};

export const GetCustomer = async (idUsuario: number | undefined) => {
  try {
    if (idUsuario === undefined) {
      let userString = await sessionStorage.getItem("user");
      if (userString) {
        userString = userString !== null ? userString : "";
        idUsuario = JSON.parse(userString).idUsuario;
      } else {
        return null;
      }
    }

    const customerId_res = await getCustomerId(idUsuario);

    if (customerId_res.ok && customerId_res.status !== 204) {
      const customerid = await customerId_res.json();
      const _crypto_res = await fetchNoJsonRes(
        `/api/liriumusuario/customer/${customerid.id}`
      );

      if (_crypto_res.ok) {
        if (_crypto_res.status !== 204) {
          const _crypto = await _crypto_res.json();
          return _crypto;
        }
      }
    }

    return null;
  } catch (error: any) {
    console.error("[GetCustomer] error: ", error);
    throw handleServiceError(error);
  }
};

export const getCustomerId = async (idUsuario: number | undefined) => {
  try {
    if (!idUsuario) {
      const user: string | null = sessionStorage.getItem("user");
      if (user) {
        idUsuario = JSON.parse(user).idUsuario;
      }
    }
    let response = await fetchNoJsonRes(
      `/api/liriumUsuario/usuario/${idUsuario}`,
      {
        method: "GET",
        headers: headerOptions,
      }
    );
    return response;
  } catch (error: any) {
    throw new Error("[Crypto], getCustomerId [error]: " + error);
  }
};

export const getOrders = async (idUsuario: number | undefined) => {
  try {
    const customerId_res = await getCustomerId(idUsuario);
    const customerId = await customerId_res.json();

    const _orders_res = await getOrdersFetch(customerId.id);

    const _orders = _orders_res.orders.map((el: any) => ({
      created_at: el.created_at.slice(0, 10),
      asset: {
        currency: el.asset.currency,
        amount: el.asset.amount,
      },
      operation: el.operation,
      id: el.id,
    }));

    return _orders;
  } catch (error: any) {
    console.log("getOrders [error]: " + error);
    throw handleServiceError(error);
  }
};

export const getOrdersFetch = (customerid: number) => {
  try {
    let response = fetchContent(`/api/liriumorder/orders/${customerid}`, {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    throw new Error("[Crypto], getOrders [error]: " + error);
  }
};

export const GetSaldos = async (
  idUsuario: number | undefined,
  moneda: string
) => {
  if (idUsuario === undefined) {
    let userString = await sessionStorage.getItem("user");
    userString = userString !== null ? userString : "";
    idUsuario = JSON.parse(userString).idUsuario;
  }

  const res = await GetUsuarioLirium(idUsuario);

  const saldos = await getSaldosFetch(res.id);

  saldos.accounts.filter((el: any) => el.currency === moneda);
  return saldos.accounts;
};

export const getSaldosFetch = (customerId: string) => {
  try {
    let response = fetchContent(
      `/api/liriumusuario/customer/${customerId}/accounts`,
      {
        method: "GET",
      }
    );
    return response;
  } catch (error: any) {
    console.log("[Crypto], getOrders [error]: " + error);
    throw handleServiceError(error);
  }
};

export const ConvertirImporteAPuntos = async (
  importeDolares: number,
  idUsuario: number | undefined
) => {
  let valor = 0;
  let puntos = 0;
  const cotizacion = await getCotizacionFetch();

  valor = importeDolares * cotizacion.v;

  const _puntos = await GetPuntosByImporteAndTipoMovimiento(
    cliente,
    idUsuario,
    11,
    valor
  );
  puntos = await _puntos.json();
  return puntos;
};

const getCotizacionFetch = () => {
  try {
    let response = fetchContent(`/api/cotizacion/ultima`, {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    throw new Error("[Crypto], getOrders [error]: " + error);
  }
};

const GetPuntosByImporteAndTipoMovimiento = (
  idCliente: number,
  idusuario: number | undefined,
  idTipoMovimiento: number,
  importestr: number
) => {
  try {
    let response = fetchNoJsonRes(
      `/api/cliente/puntosporimporte/${idCliente}/${idusuario}/${idTipoMovimiento}/${importestr}`,
      {
        method: "GET",
      }
    );
    return response;
  } catch (error: any) {
    throw new Error("[Crypto], getOrders [error]: " + error);
  }
};

// crear  customer

export const PostCustomer = async (
  idUsuario: number | undefined,
  customerdto: CustomerDTO
) => {
  let estado = false;
  if (!idUsuario) {
    const user: string | null = sessionStorage.getItem("user");
    if (user) {
      idUsuario = JSON.parse(user).idUsuario;
    }
  }
  const customer: CustomerInterface = {
    reference_id: customerdto.reference_id,
    profile: {
      first_name: customerdto.first_name,
      middle_name: customerdto.middle_name,
      last_name: customerdto.last_name,
      date_of_birth: customerdto.date_of_birth,
      address_line1: customerdto.address_line1,
      zip_code: customerdto.zip_code,
      national_id_country: customerdto.national_id_country,
      national_id_type: customerdto.national_id_type,
      national_id: customerdto.national_id,
      citizenship: customerdto.citizenship,
      city: customerdto.provincia,
      country: customerdto.pais,
      state: customerdto.localidad,
    },
    contact: {
      cellphone: customerdto.cellphone,
      email: customerdto.email,
    },
  };

  // var _crypto = _servicesApi.PostExt<Customer>(customer, urlBase, "customers", token);
  const _crypto_res = await fetchNoJsonRes("/api/liriumusuario/customer", {
    method: "POST",
    body: JSON.stringify(customer),
    headers: headerOptions,
  });

  //Actualizar tabla Local

  if (_crypto_res.ok && _crypto_res.status !== 204) {
    const _crypto = await _crypto_res.json();
    const liriumUsuario: LiriumUsuario = {
      idUsuario: idUsuario,
      id: _crypto.id,
      estado: _crypto.state,
      fechaActualizacion: _crypto.created_at,
      label: "",
    };

    await fetchContent("/api/liriumusuario", {
      method: "POST",
      body: JSON.stringify(liriumUsuario),
      headers: headerOptions,
    });
    estado = true;
  }

  return estado;
};

export const PostFile = async (
  tipo: string,
  idUsuario: number | undefined,
  file: any
) => {
  let estado = false;
  if (!idUsuario) {
    const user: string | null = sessionStorage.getItem("user");
    if (user) {
      idUsuario = JSON.parse(user).idUsuario;
    }
  }
  const customerId_res = await getCustomerId(idUsuario);
  const customerid = await customerId_res.json();

  const document_type = tipo;
  const url = `/api/liriumusuario/customer/${customerid.id}/file/${document_type}`;

  // Convertir el Blob a un array de bytes
  const fileReader = new FileReader();
  fileReader.onload = async () => {
    const fileBytes: any = new Uint8Array(fileReader.result as ArrayBuffer);

    // Convertir los bytes en una cadena Base64
    btoa(String.fromCharCode.apply(null, fileBytes));

    // Realizar la solicitud POST
    const _crypto_res = await fetchNoJsonRes(url, {
      method: "POST",
      body: file,
    });
    estado = _crypto_res.ok;
  };

  fileReader.readAsArrayBuffer(file);

  return estado;
};

export const CreateDataImagenDorso = async (
  idUsuario: number | undefined,
  file: any
) => {
  let estado = false;
  estado = await PostFile("id_back", idUsuario, file);
  if (!estado) {
    return estado;
  } else {
    estado = await PostFile("proof_of_address", idUsuario, file);
    return estado;
  }
};
