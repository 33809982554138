import { useQuery } from "react-query";
import {
  eduFinancieraService,
  puntosCryptoHomeService,
  puntosHomeService,
} from "../../services/HomeService";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { cliente } from "../../common/constants/constants";
import ES from "../../common/language/home/es.json";
import EN from "../../common/language/home/en.json";
import { GetMovimientosByPersonaDataTableHome } from "../../services/MisMovimientosService";
import useLanguage from "./useLanguage";
import { LanguageContext } from "../../context/LanguageContext";
import { modalError } from "../../utils/validaciones";

const useHome = () => {
  const appContext = useContext(AppContext);
  const { dictionary } = useLanguage({ EN, ES });
  const { data: puntos } = useQuery({
    queryKey: ["puntos", appContext?.user?.idUsuario],
    queryFn: async () =>
      puntosHomeService(appContext?.user?.idPersona, cliente),
    initialData: 0,
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error ? error.message : "Error al traer los Puntos";
      modalError("Error al traer los Puntos\n" + errorMessage);
    },
  });

  const { data: crypto } = useQuery({
    queryKey: ["crypto", appContext?.user?.idUsuario],
    queryFn: async () => puntosCryptoHomeService(),
    initialData: 0,
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error ? error.message : "Error al traer Puntos Crypto";
      modalError("Error al traer Puntos Cryptos\n" + errorMessage);
    },
  });

  const { data: edu } = useQuery({
    queryKey: ["edu", appContext?.user?.idUsuario],
    queryFn: async () => eduFinancieraService(),
    initialData: 0,
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Error al traer los datos de Educación Financiera";
      modalError(
        "Error al traer los datos de Educación Financiera\n" + errorMessage
      );
    },
  });

  const language = useContext(LanguageContext);

  const ultimosConsu = useQuery({
    queryKey: ["ultimosConsu", appContext?.user?.idUsuario],
    queryFn: async () =>
      GetMovimientosByPersonaDataTableHome(language.language),
    initialData: 0,
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Error al traer los datos de movimientos por persona";
      modalError(
        "Error al traer los datos de movimientos por persona\n" + errorMessage
      );
    },
  });

  return { puntos, crypto, edu, ultimosConsu, dictionary };
};

export default useHome;
