import {
  TipoMovimiento_CompraPuntosConCrypto,
  cliente,
  headerOptions,
} from "../common/constants/constants";
import {
  ConfirmationInterface,
  LiriumOrder,
} from "../common/interfaces/CompraCryptoInterface";
import { Movimiento } from "../common/interfaces/ComunInterface";
import { Order } from "../common/interfaces/CryptoInterface";
import { fetchNoJsonRes } from "../utils/fetchUtils";
import { getClienteMoneda, postLiriumOrder } from "./ComprarCryptoService";
import { v4 as uuidv4 } from "uuid";
import { GetUsuarioLirium } from "./UsuarioService";

export const getPuntosByImporte = (
  idCliente: number,
  idusuario: number | undefined,
  importestr: number | undefined | string,
  idProducto?: number | string
) => {
  if (!idusuario) {
    const user: string | null = sessionStorage.getItem("user");
    if (user) {
      idusuario = JSON.parse(user).idUsuario;
    }
  }
  try {
    let response = fetchNoJsonRes(
      `/api/cliente/puntosporimporte/${idCliente}/${idusuario}/2/${importestr}${
        idProducto && `/${idProducto}`
      }`,
      {
        method: "GET",
      }
    );
    return response;
  } catch (error: any) {
    throw new Error("[VenderCrypto], getPuntosByImporte [error]: " + error);
  }
};

export const postOrderSell = async (
  idUsuario: number | undefined,
  currency: string,
  amount: string
) => {
  let order_return: Order | null = null;
  const customerId = await GetUsuarioLirium(idUsuario);
  let moneda: string = "";
  const clientemoneda = await getClienteMoneda(cliente);
  if (clientemoneda !== null || clientemoneda.trim()) {
    moneda = clientemoneda.alfa3;
  }
  const order: Order = {
    reference_id: uuidv4(),
    operation: "sell",
    asset: {
      currency: currency,
      amount: amount,
    },
    sell: {
      settlement: {
        currency: moneda,
      },
    },
  };
  const _order_res = await postOrderSellFetch(order, customerId.id);
  if (_order_res.ok) {
    if (_order_res.status !== 204) {
      const _order = await _order_res.json();
      order_return = _order;
      // Inserta en tabla Local

      var montoOrigen = _order.asset.amount;
      var montoDestino = _order.sell.settlement.amount;

      const liriumOrder: LiriumOrder = {
        idUsuario: idUsuario,
        id: _order.id,
        fechaAlta: new Date(Date.now()).toISOString(),
        referenceId: order.reference_id,
        estado: _order.state,
        tipoOrden: "sell",
        monedaOrigen: _order.asset.currency,
        montoOrigen: montoOrigen,
        monedaDestino: _order.sell.settlement.currency,
        montoDestino: montoDestino,
        montoOrigenDecimal: parseFloat(montoOrigen),
        montoDestinoDecimal: parseFloat(montoDestino),
      };
      await postLiriumOrder(liriumOrder);
    }
  }
  return order_return;
  //----Agregar descuento de puntos ----
};

const postOrderSellFetch = (body: Order, customerId: number) => {
  try {
    let response = fetchNoJsonRes(`/api/liriumorder/orders/${customerId}`, {
      method: "POST",
      headers: headerOptions,
      body: JSON.stringify(body),
    });
    return response;
  } catch (error: any) {
    throw new Error("postOrderFetch error]: " + error);
  }
};

export const PostConfirmarOrderSell = async (
  idPersona: number | undefined,
  idUsuario: number | undefined,
  ordenId: string,
  currency: string,
  amount: string,
  codigoConfirmacion: string,
  puntos: number,
  amountCrypto: string
) => {
  let estado = true;

  const customerId = await GetUsuarioLirium(idUsuario);

  //Antes de confirmar valida si los puntos no superan a los que tiene el usuario
  var idCliente = cliente;
  // var idPersona = _userManager.GetPersonaId();
  const confirmation: ConfirmationInterface = {
    confirmation_code: "",
    customer: {
      amount: amount,
      currency: currency,
    },
  };

  const _confirm_res = await PostConfirmarOrderSellFetch(
    confirmation,
    customerId.id,
    ordenId
  );

  if (_confirm_res.ok) {
    if (_confirm_res.status !== 204) {
      //----Agregar movimiento de canje-descuento de puntos ----
      const _confirm = await _confirm_res.json();

      let _movimiento: Movimiento = {
        fecha: new Date(Date.now()).toISOString(),
        idCliente: idCliente,
        idPersona: idPersona,
        idTipoMovimiento: TipoMovimiento_CompraPuntosConCrypto,
        idEstado: null,
        comentario: "Se vendieron " + amount + " " + currency,
      };
      const _movimiento_a_agregar = {
        idEstado: null,
        idProducto: null,
        puntos: puntos,
        idClienteConversion: null,
        cantidad: 1,
      };
      _movimiento.movimientoDetalles
        ? _movimiento.movimientoDetalles.push(_movimiento_a_agregar)
        : (_movimiento.movimientoDetalles = [_movimiento_a_agregar]);

      const _movimiento_res = await PostMovimientoFetch(_movimiento);
      _movimiento = await _movimiento_res.json();
      if (!_movimiento_res.ok) {
        estado = false;
      }

      //Actualiza estado y fecha de tabla local

      await PatchConfirmarSellOrder(
        ordenId,
        _confirm.state,
        _movimiento.idMovimiento
      );
    } else {
      estado = false;
    }
  } else {
    estado = false;
  }

  return estado;
};

const PostConfirmarOrderSellFetch = (
  confirmation: any,
  customerId: string,
  ordenId: string
) => {
  try {
    let response = fetchNoJsonRes(
      `/api/liriumorder/orders/confirm/${customerId}/${ordenId}`,
      {
        method: "POST",
        body: JSON.stringify(confirmation),
        headers: headerOptions,
      }
    );
    return response;
  } catch (error: any) {
    throw new Error("postLiriumOrder error]: " + error);
  }
};

const PostMovimientoFetch = (body: Movimiento) => {
  try {
    let response = fetchNoJsonRes(`/api/movimiento`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: headerOptions,
    });
    return response;
  } catch (error: any) {
    throw new Error("postLiriumOrder error]: " + error);
  }
};

const PatchConfirmarSellOrder = (
  ordenId: string,
  state: any,
  id: number | undefined
) => {
  try {
    let response = fetchNoJsonRes(
      `/api/liriumorder/${ordenId}/${state}/${id}`,
      {
        method: "PATCH",
        // body: JSON.stringify(body),
        headers: headerOptions,
      }
    );
    return response;
  } catch (error: any) {
    throw new Error("postLiriumOrder error]: " + error);
  }
};
