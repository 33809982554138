import { useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/material";
import "./FiltroCategoria.css";
import {
  IFiltro,
  bodyProductsInterface,
} from "../../../../../common/interfaces/GiftCardInterfaces";
import useFiltroCatalogo from "../../../../../common/hooks/useFiltroCatalogo";
import useCatalogo from "../../../../../common/hooks/useCatalogo";

type Props = {
  el?: IFiltro;
  limpiar?: boolean;
  filterProducts: bodyProductsInterface;
  setFilterProducts: React.ComponentState;
};

const CheckBoxCategoria: React.FC<Props> = ({
  el,
  filterProducts,
  setFilterProducts,
}) => {
  const { check, setCheck, handleChangeCategoria } = useFiltroCatalogo({
    filterProducts,
    setFilterProducts,
  });

  useEffect(() => {
    filterProducts.categorias &&
    filterProducts.categorias?.includes(Number(el?.id))
      ? setCheck(true)
      : setCheck(false);
  }, [filterProducts, setCheck, el?.id]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={check}
          size="small"
          sx={{
            height: "10px",
            "&.Mui-checked": {
              color: "paginationColor.main",
            },
          }}
          value={el?.id}
          onChange={(e) => handleChangeCategoria(e, Number(el?.id))}
        />
      }
      label={
        <Box component="div" fontSize={13}>
          {el?.descripcionWeb}
        </Box>
      }
    />
  );
};

export const FiltroCategoria: React.FC<Props> = ({
  limpiar,
  filterProducts,
  setFilterProducts,
}) => {
  const [interruptor, setInterruptor] = useState<boolean>(false);

  const { filtrosOptions } = useCatalogo();
  const categorias = filtrosOptions.data?.categorias;
  useEffect(() => {
    setInterruptor(true);
  }, [limpiar]);

  const handleInterruptor = () => {
    setInterruptor(false);
  };

  return (
    <section className="filtro-categoria-container">
      <FormGroup onSubmit={() => interruptor && (() => handleInterruptor())}>
        {categorias &&
          categorias.length > 0 &&
          categorias.map((el: IFiltro, index: number) => (
            <CheckBoxCategoria
              key={`filtro-cat-${el.id}-${index}`}
              filterProducts={filterProducts}
              setFilterProducts={setFilterProducts}
              el={el}
            />
          ))}
      </FormGroup>
    </section>
  );
};
