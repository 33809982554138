import { Box, Button, FormControl } from "@mui/material";
import React from "react";
import DatosBasicosForm from "../DatosBasicosForm/DatosBasicosForm";
import DatosDeContacto from "../DatosDeContacto/DatosDeContacto";
import "./DatosPersonalesForm.css";
import DatosDemograficos from "../DatosDemograficos/DatosDemograficos";
import { formMisDatosInterface } from "../../../../common/interfaces/MisDatosInterface";
import { submitMiContacto } from "../../../../utils/functions";
import useLanguage from "../../../../common/hooks/useLanguage";
import ES from "../../../../common/language/users/es.json";
import EN from "../../../../common/language/users/en.json";

type Props = {
  form: formMisDatosInterface | null;
  setForm: React.Dispatch<React.SetStateAction<any>>;
};

const DatosPersonalesForm: React.FC<Props> = ({ form, setForm }) => {
  const { dictionary } = useLanguage({ EN, ES });
  return (
    <FormControl fullWidth>
      <div className="form-mis-datos-personales">
        <DatosBasicosForm form={form} setForm={setForm} />
        <DatosDeContacto form={form} setForm={setForm} />
        <DatosDemograficos form={form} setForm={setForm} />
        <Box width={"100%"} display={"flex"} justifyContent={"center"}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => submitMiContacto(form, form && form.idPersona)}>
            {dictionary?.perfil.actualizar}
          </Button>
        </Box>
      </div>
    </FormControl>
  );
};

export default DatosPersonalesForm;
