import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import "./TiposReconocimientosContainer.css";
import ReconocimientoCard from "../ReconocimientoCard/ReconocimientoCard";
import { GetTipoReconocimientoBySector } from "../../../../../services/ReconocimientoService";
import { TipoReconocimientoInterface } from "../../../../../common/interfaces/ReconocimientoInterface";
import { Box } from "@mui/material";
import ES from "../../../../../common/language/reconocimiento/es.json";
import EN from "../../../../../common/language/reconocimiento/en.json";
import useLanguage from "../../../../../common/hooks/useLanguage";
import { AppContext } from "../../../../../context/AppContext";
import { LanguageContext } from "../../../../../context/LanguageContext";

type Props = {
  form: any;
  setForm: any;
};

const TiposReconocimientosContainer: React.FC<Props> = ({ form, setForm }) => {
  const [tipoReconocimiento, setTipoReconocimiento] = useState<
    TipoReconocimientoInterface[] | null
  >(null);

  const { dictionary } = useLanguage({ EN, ES });
  const appContext = useContext(AppContext);
  const language = useContext(LanguageContext);

  useEffect(() => {
    GetTipoReconocimientoBySector(appContext?.user?.idSector, language.language)
      .then((data) => setTipoReconocimiento(data))
      .catch((err) => {
        console.error(err);
      });
  }, [appContext, language]);

  return (
    <Box marginTop={4} width={"100%"} p={"20px"}>
      <Typography variant="h5" color="primary">
        {dictionary?.formBusqueda.tipoReconocimientos}
      </Typography>
      <div className="tipo-reconocimiento-card-container">
        {tipoReconocimiento &&
          tipoReconocimiento.map((el) => (
            <ReconocimientoCard
              form={form}
              setForm={setForm}
              data={el}
              key={`reco-card-${el.idTipoReconocimiento}`}
            />
          ))}
      </div>
    </Box>
  );
};

export default TiposReconocimientosContainer;
