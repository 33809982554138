import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

type Props = {
  open: boolean;
  reset: boolean;
  setOpen: React.Dispatch<React.SetStateAction<any>>;
  setReset: React.Dispatch<React.SetStateAction<any>>;
};

export const ModalReconocimiento: React.FC<Props> = ({
  open,
  setOpen,
  reset,
  setReset,
}) => {
  const handleClose = () => {
    setReset(!reset);
    setOpen(false);
  };
  const navigate = useNavigate();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        sx={{ zIndex: 20000 }}
        slots={{ backdrop: Backdrop }}>
        <Fade in={open}>
          <Box sx={style} textAlign={"center"}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              ¿Quieres agregar otro reconocimiento?
            </Typography>
            <Box
              marginTop={3}
              display={"flex"}
              gap={3}
              justifyContent={"center"}>
              <Button variant="contained" color="success" onClick={handleClose}>
                Agregar
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => navigate("/")}>
                No agregar
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
