import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, FormControlLabel, Radio, RadioGroup, Typography, FormControl, Box } from '@mui/material';
import InputForm from '../../../../components/Inputs/InputForm/InputForm';
import { postLogin } from '../../../../services/LoginServices';
import { formularioInterface } from '../../../../common/interfaces/LoginInterface';
import { cliente } from '../../../../common/constants/constants';
import ES from '../../../../common/language/login/es.json';
import EN from '../../../../common/language/login/en.json';
import useLanguage from '../../../../common/hooks/useLanguage';
import { modalError, validarLogin } from '../../../../utils/validaciones';
import { AppContext } from '../../../../context';
import './LoginForm.css';

const LoginForm = () => {
  const [form, setForm] = useState<formularioInterface>({
    Clave: '',
  });
  const [searchParams] = useSearchParams();
  const [radioBtn, setRadioBtn] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  // IDIOMA HOOK
  const { dictionary } = useLanguage({ EN, ES });

  useEffect(() => {
    setForm((prev) => ({ ...prev, ClienteId: cliente }));
  }, []);

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const campoAValidar = radioBtn ? form?.Email : form?.dni;
    if ((campoAValidar && !campoAValidar.trim()) || !form.Clave.trim()) {
      setError(dictionary?.form.camposIncompletos);
      modalError(dictionary?.form.camposIncompletos);
    } else {
      try {
        let finalForm = { ...form };
        if (radioBtn) {
          delete finalForm.dni;
        } else {
          delete finalForm.Email;
        }

        const user = await postLogin(finalForm);

        if (user && !user.bloqueado) {
          appContext?.guardarDatos(user);
          navigate('/');
        } else if (user?.bloqueado) {
          setError(dictionary?.form.usuarioBloqueado);
          modalError(dictionary?.form.usuarioBloqueado);
        }
      } catch (error: any) {
        validarLogin(radioBtn);
        console.error(error);
        const [statusCode] = error.message.split('|');
        if (statusCode === '403') {
          const errorMessage = dictionary?.form.usuarioBloqueado;
          setError(errorMessage);
          modalError(errorMessage);
        } else {
          const errorMessage = dictionary?.form.usuarioNoRegistrado;
          setError(errorMessage);
          modalError(errorMessage);
        }
      }
    }
  };

  return (
    <>
      <Typography variant="h4" color="primary">
        {dictionary?.form.titulo}
      </Typography>
      <form action="POST" onSubmit={submitForm}>
        <FormControl fullWidth={true}>
          <div className="radio-container">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={radioBtn}
              onChange={() => setRadioBtn(!radioBtn)}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                componentsProps={{ typography: { variant: 'subtitle2' } }}
                label={dictionary?.form.email}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                componentsProps={{ typography: { variant: 'subtitle2' } }}
                label={dictionary?.form.dni}
              />
            </RadioGroup>
          </div>

          <div className="login-input-container">
            <InputForm
              label={radioBtn ? dictionary?.form.correoElectronico : dictionary?.form.dni}
              type={radioBtn ? 'email' : 'text'}
              name={radioBtn ? 'Email' : 'dni'}
              form={form}
              setForm={setForm}
            />
          </div>
          <div className="login-input-container">
            <InputForm
              label={dictionary?.form.clave}
              type="password"
              name="Clave"
              form={form}
              setForm={setForm}
              showPassword={showPassword}
              handleTogglePassword={handleTogglePassword}
            />
            <Box
              sx={{
                width: 'fit-content',
                height: '10px',
              }}
            >
              <Link to="/login/recuperar">
                <Typography variant="h6" color="primary" fontSize={14} pb={4}>
                  {dictionary?.form.olvidasteContraseña}
                </Typography>
              </Link>
            </Box>
          </div>

          <Box paddingTop={2} textAlign={'center'}>
            <Button type="submit" variant="contained" color="primary">
              {dictionary?.form.ingresar}
            </Button>
            {searchParams.get('expiredToken') && (
              <Typography
                style={{
                  color: 'red',
                  marginTop: '10px',
                  whiteSpace: 'pre-line',
                }}
              >
                {dictionary?.form.tokenExpirado}
              </Typography>
            )}

            {error && (
              <Typography
                style={{
                  color: 'red',
                  marginTop: '30px',
                  whiteSpace: 'pre-line',
                }}
              >
                {error}
              </Typography>
            )}
          </Box>
        </FormControl>
      </form>
    </>
  );
};

export default LoginForm;
