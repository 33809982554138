import { fetchContent } from "../utils/fetchUtils";

export const GetReconocimientosRecibidos = async (
  lang: string,
  page: number = 1,
  pageSize: number = 10,
  ordenamiento: string = 'fecha desc'
) => {
  try {
    const url = `/api/reconocimiento/recibidos?idioma=${lang}&pagina=${page}&cantidadRegistrosPorPagina=${pageSize}&ordenamiento=${ordenamiento}`;
    let response = await fetchContent(url, {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    console.error("[GetReconocimientosRecibidos]:", error);
    throw new Error("[GetReconocimientosRecibidos]:" + error);
  }
};
