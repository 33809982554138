import { useQuery } from "react-query";
import { getBranches, getDetalleProducto } from "../../services/ProductService";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import useLanguage from "./useLanguage";
import ES from "../../common/language/giftcard/es.json";
import EN from "../../common/language/giftcard/en.json";
import { getPuntosByImporte } from "../../services/VenderCryptoService";
import { cliente } from "../../common/constants/constants";
import { incrementar } from "../../common/redux/features/contadorItemReducer";
import { addToCart } from "../../common/redux/features/carritoReducer";
import { IProducto } from "../../common/interfaces/ProductoInterface";
import { notificacionItemAgregado } from "../../utils/functions";
import { modalError } from "../../utils/validaciones";
import { useDispatch } from "react-redux";
import { getExchange } from "../../services/GiftCardService";

const useProducto = (id: string | undefined) => {
  //variables
  const [puntos, setPuntos] = useState<number>(0);
  const [prevPuntos, setPrevPuntos] = useState<number>(0);
  const [importe, setImporte] = useState<number>(0);
  const [producto, setProducto] = useState<IProducto | null>(null);
  const [giftcard, setGiftCard] = useState<boolean>(false);
  const [oferta, setOferta] = useState<boolean>(false);
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const { dictionary } = useLanguage({ EN, ES });
  //queries
  const { data: detalleProd } = useQuery({
    queryKey: ["detalleProducto", id],
    queryFn: async () => getDetalleProducto(id),
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Error al traer el detalle de los productos";
      modalError("Error al traer el detalle de los productos\n" + errorMessage);
    },
  });

  const { data: branches } = useQuery({
    queryKey: ["branchesByProduct", { productId: id }],
    queryFn: async () => getBranches(detalleProd?.codigoProductoProveedor),
    enabled: giftcard && detalleProd?.codigoProductoProveedor !== undefined,
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Error al traer el detalle de las sucursales";
      modalError(
        "Error al traer el detalle de las sucursales\n" + errorMessage
      );
    },
  });

  const calcularPuntos = async (
    idProducto: number | string,
    importeProp: number
  ) => {
    await getPuntosByImporte(
      cliente,
      appContext?.user?.idUsuario,
      importeProp,
      idProducto
    )
      .then((res) => res.json())
      .then((puntosData) => {
        if(puntosData?.oferta) {
          setPrevPuntos(parseInt(puntosData.puntos, 10));
          setPuntos(parseInt(puntosData.puntosOferta, 10));
          setOferta(puntosData?.oferta);
        } else {
          setPrevPuntos(parseInt(puntosData.puntos, 10));
          setPuntos(parseInt(puntosData.puntos, 10));
          setOferta(puntosData?.oferta);
        }
      })
      .catch((e) => {
        console.error("Error al convertir a puntos")
      });
  };

  //metodos
  useEffect(() => {
    if (detalleProd && detalleProd.productos.length <= 1) {
      setProducto(detalleProd.productos[0]);
      setImporte(detalleProd?.precioDesde);
      setGiftCard(detalleProd?.esOhGiftCard);
      calcularPuntos(
        detalleProd.productos[0].idProducto,
        detalleProd?.precioDesde
      );
      exchange(detalleProd.productos[0].codigoMoneda);
    }
    // eslint-disable-next-line
  }, [detalleProd]);

  const exchange = async (code: string) => {
    const res = await getExchange(code);
    setProducto((prev) => ({ ...prev, codigoMoneda: res?.alfa3 }));
  };

  const handleCanjear = async () => {
    if (producto) {
      const puntosUsar = oferta ? puntos : prevPuntos;
      dispatch(incrementar());
      dispatch(
        addToCart({
          idProducto: producto?.idProducto,
          Imagen: detalleProd?.picture,
          Descripcion: detalleProd?.descripcion,
          esGiftCard: producto?.esGiftcard,
          puntos: puntosUsar,
          puntosPorUnidad: puntosUsar,
          cantidad: 1,
          Regalo: false,
          Nombre: producto?.tituloProducto,
          Email: null,
          Comentario: null,
          PersonaRegalo: null,
          Precio: importe.toString(),
          Marca: detalleProd?.marcaDescripcion,
        })
      );
      notificacionItemAgregado(dictionary?.seAgregoAlCarrito);
    } else {
      modalError("Elija un producto");
    }
  };

  return {
    detalleProd,
    puntos,
    setImporte,
    importe,
    handleCanjear,
    calcularPuntos,
    dictionary,
    producto,
    setProducto,
    branches,
    prevPuntos,
    setPuntos,
    oferta
  };
};

export default useProducto;
