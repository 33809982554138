import React from "react";
import "./CarritoVacio.css";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ES from "../../../../common/language/catalogo/es.json";
import EN from "../../../../common/language/catalogo/en.json";
import useLanguage from "../../../../common/hooks/useLanguage";

const CarritoVacio = () => {
  const { dictionary } = useLanguage({ EN, ES });
  const navigate = useNavigate();
  return (
    <div className="carrito-vacio">
      <div className="section-carrito-vacio-img">
        <Button variant="text" onClick={() => navigate("/catalogo")}>
          <ShoppingCartIcon sx={{ fontSize: "150px" }} />
        </Button>
      </div>
      <div className="section-carrito-vacio-texto">
        <Typography variant="h4" color="initial">
          {dictionary?.carritoVacio}
        </Typography>
        <Typography variant="subtitle1" color="initial" textAlign={"center"}>
          {dictionary?.noHayProductos}
        </Typography>
        <Typography variant="subtitle1" color="initial" textAlign={"center"}>
          {dictionary?.buscaProductos}
        </Typography>
        <Box textAlign={"center"} pt={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate("/catalogo")}>
            {dictionary?.seguirComprando}
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default CarritoVacio;
