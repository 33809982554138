import { useContext, useEffect, useState, useCallback } from 'react';
import {
  UseReconocimientosRealizados,
  myReconocimientoInterface,
} from '../../common/interfaces/ReconocimientoInterface';
import useLanguage from './useLanguage';
import ES from '../../common/language/reconocimiento/es.json';
import EN from '../../common/language/reconocimiento/en.json';
import { useQuery } from 'react-query';
import { GetMyReconocimientos } from '../../services/HomeService';
import { modalError } from '../../utils/validaciones';
import { AppContext, LanguageContext } from '../../context';

const useReconocimientosRealizados = (): UseReconocimientosRealizados => {
  const [recoSearch, setRecoSearch] = useState<myReconocimientoInterface[] | null>(null);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [orden, setOrden] = useState({ campo: 'fecha', ascendente: false });
  const [search, setSearch] = useState<string>('');
  const appContext = useContext(AppContext);
  const language = useContext(LanguageContext);
  const { dictionary } = useLanguage({ EN, ES });

  const fetchData = useCallback(async () => {
    const direccion = orden.ascendente ? 'asc' : 'desc';
    const order = `${orden.campo} ${direccion}`;
    const usuario = search ?? '';
    let params = {
      idioma: language.language,
      pagina: page,
      ordenamiento: order,
      CantidadRegistrosPorPagina: 10,
      ...(usuario && { usuario }),
    };
    return await GetMyReconocimientos(params);
  }, [language.language, page, orden, search]);

  const { isLoading: loading, data: recoRealizados } = useQuery({
    queryKey: ['recoRealizados', appContext?.user?.idUsuario, language.language, page, orden, search],
    queryFn: fetchData,
    cacheTime: 0,
    onSuccess: (data) => {
      setTotalPages(data.cantidadPaginas);
      setRecoSearch(data.data);
    },
    onError: (error: any) => {
      const errorMessage = error instanceof Error ? error.message : 'Error al traer los Reconocimientos Realizados';
      modalError('Error al traer los Reconocimientos Realizados\n' + errorMessage);
    },
  });

  const cambiarOrdenamiento = (campo: string) => {
    setOrden((ordenActual) => {
      const esAscendente = ordenActual.campo === campo ? !ordenActual.ascendente : true;
      return {
        campo,
        ascendente: esAscendente,
      };
    });
  };

  useEffect(() => {
    if (recoRealizados && recoRealizados.data) {
      setRecoSearch(recoRealizados.data);
    }
  }, [recoRealizados]);

  return {
    loading,
    recoSearch,
    dictionary,
    page,
    totalPages,
    orden,
    language,
    setPage,
    setSearch,
    cambiarOrdenamiento,
  };
};

export default useReconocimientosRealizados;
