import { fetchContent, fetchNoJsonRes } from '../utils/fetchUtils';
import { setQueryParams } from '../utils/functions';
import { handleServiceError } from './GiftCardService';

interface IMovimientosPersona {
  idioma: string;
  ordenamiento: string;
  pagina: number;
  CantidadRegistrosPorPagina: number;
  movimientoDescripcion?: string;
}

export const GetMovimientosByPersonaDataTable = async (props: IMovimientosPersona) => {
  try {
    const url = setQueryParams('api/movimiento/persona/consolidado', props);

    let response = await fetchContent(url, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.log('[GetMovimientosByPersonaDataTable error]: ' + error);
    throw handleServiceError(error);
  }
};

export const GetMovimientosByPersonaDataTableHome = async (lang: string) => {
  try {
    const mov_res = await fetchNoJsonRes(`/api/movimiento/persona/consolidado/home?idioma=${lang}`, {
      method: 'GET',
    });
    if (mov_res.ok && mov_res.status !== 204) {
      const mov = await mov_res.json();
      return mov;
    }
    return null;
  } catch (error: any) {
    throw new Error('[GetMovimientosByPersonaDataTableHome error]: ' + error);
  }
};
