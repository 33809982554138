import { Divider, Grid, MenuItem, Select, Typography } from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useContext,
} from "react";
import { DatePickerParentController } from "../../../../components/DatePickerParentController/DatePickerParentController";
import SelectForm from "../../../../components/Inputs/SelectForm/SelectForm";
import { generoInterface } from "../../../../common/interfaces/MisDatosInterface";
import { GetGeneroPersonaForSelect } from "../../../../services/MisDatosService";
import ES from "../../../../common/language/users/es.json";
import EN from "../../../../common/language/users/en.json";
import useLanguage from "../../../../common/hooks/useLanguage";
import { LanguageContext } from "../../../../context/LanguageContext";
import { AVATAR_ROUTES } from "../../../../common/constants/constants";

type Props = {
  form: any;
  setForm: Dispatch<SetStateAction<any>>;
};

const DatosBasicosForm: React.FC<Props> = ({ form, setForm }) => {
  const [generos, setGeneros] = useState<generoInterface[] | null>(null);
  const { dictionary } = useLanguage({ EN, ES });
  const language = useContext(LanguageContext);

  useEffect(() => {
    GetGeneroPersonaForSelect(language.language).then((dataGenero) =>
      setGeneros(dataGenero)
    );
  }, [language.language]);

  const handleChangeAvatar = (event: any) => {
    setForm({
      ...form,
      avatar: event.target.value,
      idUsuarioNavigation: {
        ...(form?.idUsuarioNavigation ?? {}),
        avatar: event.target.value,
      },
    });
  };
  return (
    <div>
      <Typography variant="h5" color="initial" paddingBottom={"20px"}>
        {dictionary?.edit.datosPersonalesDe}
      </Typography>
      <Divider sx={{ marginBottom: "30px" }} />
      <Grid container columns={12} spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <DatePickerParentController
            label={dictionary?.edit.fechaNacimiento}
            name="fechaNacimiento"
            form={form}
            setForm={setForm}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <SelectForm
            label={dictionary?.edit.genero}
            name="idGeneroPersona"
            form={form}
            setForm={setForm}>
            <>
              {generos &&
                generos.map((el) => (
                  <option
                    key={`genero-${el.codigo}`}
                    value={el.id}
                    defaultValue={el.nombre}>
                    {el.nombre}
                  </option>
                ))}
            </>
          </SelectForm>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <div className="input-container">
            <label>{dictionary?.perfil.avatar}</label>
            <Select
              className="select-avatar"
              value={
                form?.idUsuarioNavigation?.avatar
                  ? form?.idUsuarioNavigation?.avatar
                  : AVATAR_ROUTES[0].avatar
              }
              onChange={(e) => handleChangeAvatar(e)}>
              {AVATAR_ROUTES.map((el) => (
                <MenuItem
                  key={`avatar-${el.id}`}
                  value={el.avatar}
                  className="menu-item-avatar">
                  <img src={el.icono} alt="icono" />
                  {dictionary?.perfil.avatar} {el.id}
                </MenuItem>
              ))}
            </Select>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default DatosBasicosForm;
