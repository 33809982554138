import { Dispatch, SetStateAction } from "react";
import "./DatePickerParentController.css";

type Props = {
  readonly?: boolean;
  name: string;
  label: string;
  //   type: string;
  form: any;
  fecha?: string;
  setForm: Dispatch<SetStateAction<any>>;
};
export const DatePickerParentController: React.FC<Props> = ({
  readonly,
  name,
  label,
  form,
  setForm,
}) =>
  //   type,
  {
    const handleChange = (e: any) => {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    };
    return (
      <div className="input-container">
        <label htmlFor={name}>{label}</label>
        <input
          type="date"
          id={name}
          className="input-date"
          name={name}
          value={form[name] ? form[name].slice(0, 10) : "0000-00-00"}
          onChange={handleChange}
        />
      </div>
    );
  };
