import React from "react";
import "./inputForm.css";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type Props = {
  readonly?: boolean;
  name: string;
  label: string;
  type: string;
  form: any;
  setForm: any;
  required?: boolean;
  err?: boolean;
  showPassword?: boolean;
  handleTogglePassword?: () => void;
};

const InputForm: React.FC<Props> = ({
  readonly,
  name,
  label,
  type,
  form,
  setForm,
  required,
  err,
  showPassword,
  handleTogglePassword,
}) => {
  const handleChange = (e: any) => {
    if (type !== "number") {
      setForm({ ...form, [e.target.name]: e.target.value });
    } else {
      setForm({ ...form, [e.target.name]: parseInt(e.target.value) });
    }
  };

  return (
    <div className="input-container-dos">
      <label>
        {label} {required && <b className="input-form--required">*</b>}
      </label>
      <TextField
        name={name}
        value={form[name] ? form[name] : ""}
        className={`input-form-dos ${readonly && "read-only-style"} ${err && !form[name] ? "input-form--error" : ""
          }`}
        type={type === "password" && showPassword ? "text" : type}
        onChange={(e) => handleChange(e)}
        required={required ? true : false}
        InputProps={{
          endAdornment: type === "password" && (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default InputForm;
