import { useContext, useState, useEffect, useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AppContext } from '../../context/AppContext';
import useLanguage from './useLanguage';
import ES from '../../common/language/reconocimiento/es.json';
import EN from '../../common/language/reconocimiento/en.json';
import {
  GetReconocimientoBySupervisor,
  putReconocimientoAprobar,
} from '../../services/ReaconocimientoAprobacionService';
import { myReconocimientoInterface, ReconocimientoInterface } from '../../common/interfaces/ReconocimientoInterface';
import { notificacion } from '../../utils/functions';
import { modalError, validarUsuariosSeleccionados } from '../../utils/validaciones';
import { cliente } from '../../common/constants/constants';
import useCustomQuery from './useCustomQuery';
import { getBudgetSupervisor } from '../../services/MiEquipoService';
import { LanguageContext } from '../../context';

const useAprobarReconocimiento = () => {
  const [recoSearch, setRecoSearch] = useState<myReconocimientoInterface[] | null>(null);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [orden, setOrden] = useState({ campo: 'fecha', ascendente: false });
  const [search, setSearch] = useState<string>('');
  const [form, setForm] = useState<ReconocimientoInterface>({ aprobados: [] });
  const appContext = useContext(AppContext);
  const idUsuario = appContext?.user?.idUsuario;
  const { dictionary } = useLanguage({ EN, ES });
  const language = useContext(LanguageContext);
  const queryClient = useQueryClient();

  const { data: budgetSupervisor } = useQuery({
    queryKey: ['budget-supervisor-key', idUsuario],
    queryFn: async () => await getBudgetSupervisor(idUsuario),
  });

  const fetchReconocimientos = useCallback(async (): Promise<ReconocimientoInterface[]> => {
    const direccion = orden.ascendente ? 'asc' : 'desc';
    const query = `${orden.campo} ${direccion}`;
    const usuario = search ?? '';
    let params = {
      idioma: language.language,
      userId: appContext?.user?.idUsuario,
      pagina: page,
      ordenamiento: query,
      CantidadRegistrosPorPagina: 10,
      ...(usuario && { usuario }),
    };
    return await GetReconocimientoBySupervisor(params);
  }, [language.language, appContext?.user?.idUsuario, page, orden, search]);

  const {
    isLoading: loading,
    isFetching: fetching,
    data: aproRealizados,
  } = useCustomQuery({
    queryKey: ['aproRealizados', language.language, idUsuario, page, orden, search],
    queryFn: fetchReconocimientos,
    cacheTime: 0,
    enabled: !!idUsuario, //* !! = null || undefined
    onSuccess: (data) => {
      setTotalPages(data.cantidadPaginas);
      setRecoSearch(data.data);
    },
    onError: (error: any) => {
      const errorMessage = error instanceof Error ? error.message : 'Error al traer los Reconocimientos';
      modalError('Error al traer los Reconocimientos\n' + errorMessage);
    },
  });

  const mutation = useMutation({
    mutationFn: async () => await putReconocimientoAprobar(form, cliente, appContext?.user?.idPersona, idUsuario),
    onError: (error) => {
      notificacion(false, dictionary?.errorAlAprobar);
    },
    onSuccess: () => {
      notificacion(true, dictionary?.seAprobaronLosItems);
      setForm({ aprobados: [] });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['aproRealizados', idUsuario]);
    },
  });

  const handleSubmit = async () => {
    const puntosTotales = form?.aprobados?.reduce((total, id) => {
      const reco = aproRealizados?.data?.find((rec) => rec.id === id);
      return total + (reco ? reco?.puntos : 0);
    }, 0);
    if (puntosTotales > budgetSupervisor?.presupuesto) {
      notificacion(false, dictionary?.errorValidacionPresupuesto);
      return;
    }
    if (validarUsuariosSeleccionados(form.aprobados)) {
      await mutation.mutateAsync();
    } else {
      notificacion(false, dictionary?.errorValidacion);
    }
  };

  const cambiarOrdenamiento = (campo: string) => {
    setOrden((ordenActual) => {
      const esAscendente = ordenActual.campo === campo ? !ordenActual.ascendente : true;
      return {
        campo,
        ascendente: esAscendente,
      };
    });
  };

  useEffect(() => {
    if (aproRealizados && aproRealizados.data) {
      setRecoSearch(aproRealizados.data);
    }
  }, [aproRealizados]);

  return {
    recoSearch,
    loading: loading || fetching,
    dictionary,
    form,
    page,
    totalPages,
    orden,
    setSearch,
    setRecoSearch,
    setForm,
    handleSubmit,
    setPage,
    cambiarOrdenamiento,
  };
};

export default useAprobarReconocimiento;
