import { combineReducers, configureStore } from "@reduxjs/toolkit";
import contadorItemReducer from "./features/contadorItemReducer";
import storageSession from "redux-persist/lib/storage/session"; //este para session storage
import { persistStore, persistReducer } from "redux-persist";
import { carritoReducer } from "./features/carritoReducer";
import { filtroCatalogoReducer } from "./features/filtroCatalogoReducer";

const rootReducer = combineReducers({
  contadorItem: contadorItemReducer,
  carrito: carritoReducer,
  filtroCatalogo: filtroCatalogoReducer,
});
const persistConfig = {
  key: "root",
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
