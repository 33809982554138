import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { IMasReconocidos } from "../../../../../../common/interfaces/HomeInterace";
import { Box, CircularProgress, TableHead } from "@mui/material";
import ES from "../../../../../../common/language/home/es.json";
import EN from "../../../../../../common/language/home/en.json";
import useLanguage from "../../../../../../common/hooks/useLanguage";
import { useQuery } from "react-query";
import { GetMasReconocidosDelMes } from "../../../../../../services/HomeService";
import { LanguageContext } from "../../../../../../context/LanguageContext";
import { useContext } from "react";
import { modalError } from "../../../../../../utils/validaciones";

const MasReconocidosTable = () => {
  const { dictionary } = useLanguage({ EN, ES });
  const language = useContext(LanguageContext);

  const { isLoading: loading, data: masReconocidos } = useQuery({
    queryKey: "masReconocidos",
    queryFn: async () => GetMasReconocidosDelMes(language.language),
    initialData: 0,
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Error al traer Mas Reconocidos del Més";
      modalError("Error al traer Mas Reconocidos del Més\n" + errorMessage);
    },
  });
  return (
    <Box>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#E9E9E9" }}>
              {[
                dictionary?.tabla.ranking,
                dictionary?.tabla.nombre,
                dictionary?.tabla.sector,
              ].map((el) => (
                <TableCell
                  align="center"
                  key={`header-${el}`}
                  sx={{ fontWeight: "bold", position: "relative" }}>
                  <div className="row-header-reco">{el}</div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading || !masReconocidos ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <CircularProgress />
                  <div>{dictionary?.tabla.loading}</div>
                </TableCell>
              </TableRow>
            ) : masReconocidos ? (
              masReconocidos.map((row: IMasReconocidos, index: number) => (
                <TableRow
                  key={`mas'reco-dashboard-${row.idUsuario}`}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell align="center">{row.nombre}</TableCell>

                  <TableCell align="center">{row.sector}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell align="center" colSpan={4}>
                {loading
                  ? dictionary?.tabla.loading
                  : dictionary?.tabla.sinDatos}
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MasReconocidosTable;
