import { Box, Button, FormControl, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import InputForm from "../../../../components/Inputs/InputForm/InputForm";
import { recuperarPassword } from "../../../../services/LoginServices";
import { cliente } from "../../../../common/constants/constants";
import EN from "../../../../common/language/recupero/en.json";
import ES from "../../../../common/language/recupero/es.json";
import useLanguage from "../../../../common/hooks/useLanguage";
import { modalError, modalSuccess } from "../../../../utils/validaciones";
import { useNavigate } from "react-router-dom";

const RecuperarForm = () => {
  const [form, setForm] = useState({
    email: "",
    idCliente: cliente,
  });
  const [mnjRes, setMsjRes] = useState<string>("");
  // IDIOMA HOOK
  const { dictionary } = useLanguage({ EN, ES });
  const navigate = useNavigate();

  const submit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    recuperarPassword(form)
      .then((res) => {
        if (res.status === 400) {
          modalError(dictionary?.noExiste);
        } else {
          modalSuccess(dictionary?.solicitudEnviada);
          navigate("/login");
        }
      })
      .catch(() => {
        modalError(dictionary?.noExiste);
        setMsjRes(dictionary?.noExiste);
      });

    setForm({ ...form, email: "" });
  };

  return (
    <>
      <Typography variant="h4" color="primary" textAlign={"center"}>
        {dictionary?.titulo}
      </Typography>
      <Typography
        variant="h6"
        color="primary"
        fontSize={16}
        textAlign={"center"}>
        {dictionary?.mensaje}
      </Typography>
      <form action="POST" onSubmit={submit}>
        <FormControl
          fullWidth={true}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "20px",
            width: 330,
          }}>
          <InputForm
            label={dictionary?.email}
            form={form}
            setForm={setForm}
            type={"email"}
            name={"email"}
          />

          <Grid item xs={12} position={"relative"} sx={{ paddingTop: 5 }}>
            <Typography
              variant="h6"
              color="error"
              fontSize={18}
              sx={{ position: "absolute", top: 0, left: 0 }}>
              {mnjRes}
            </Typography>
            <Box textAlign={"center"}>
              <Button type="submit" variant="contained" color="primary">
                {dictionary?.enviar}
              </Button>
            </Box>
          </Grid>
        </FormControl>
      </form>
    </>
  );
};

export default RecuperarForm;
