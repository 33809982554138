import { useContext, useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../common/redux/store';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../../context/AppContext';
import ES from '../../../../common/language/catalogo/es.json';
import EN from '../../../../common/language/catalogo/en.json';
import useLanguage from '../../../../common/hooks/useLanguage';
import { GetFormaEntrega, PostCanje } from '../../../../services/CarritoService';
import { ModalCanjeCarrito } from '../ModalCanjeCarrito/ModalCanjeCarrito';
import { ModalError } from '../../../../components/Modals/ModalError/ModalError';
import { IDeliveryOption, IFormCarrito, PedidoDetalle } from '../../../../common/interfaces/CarritoInterface';
import { modalError, validarDomicilio } from '../../../../utils/validaciones';
// import SelectPuntoEntrega from '../SelectPuntoEntrega/SelectPuntoEntrega';
// import { PuntoEntregaDatos } from '../PuntoEntregaDatos/PuntoEntregaDatos';
import ButtonSubmit from '../../../../components/ButtonSubmit/ButtonSubmit';
import { validationCarritoForm } from '../../../../common/hooks/validaciones/carrito.validaciones';
import { getPersonaPuntos } from '../../../../services/ComprarCryptoService';
import { cliente } from '../../../../common/constants/constants';
import './CarritoTotalContainer.css';
import { formatNumber } from '../../../../utils/functions';

const CarritoTotalContainer = () => {
  const { contadorItem } = useSelector((state: RootState) => state.contadorItem);
  const carrito = useSelector((state: RootState) => state.carrito);
  const [deliveryOptions, setDeliveryOptions] = useState<IDeliveryOption[] | null>(null);
  const [total, setTotal] = useState(0);
  const [form, setForm] = useState<IFormCarrito>({
    idFormaEntrega: null,
    domicilio: null,
  });
  const [open, setOpen] = useState<boolean>(false);
  // const [formErr, setFormErr] = useState<boolean>(false);
  const [openErr, setOpenErr] = useState<boolean>(false);
  const [puntosPersona, setPuntosPersona] = useState<number>();
  const [pedidoDetalle, setPedidoDetalle] = useState<PedidoDetalle[]>([]);
  const { dictionary } = useLanguage({ EN, ES });
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  useEffect(() => {
    getPersonaPuntos(appContext?.user?.idPersona, cliente)
      .then((res) => res.json())
      .then((punto) => setPuntosPersona(punto));
  }, [appContext]);

  useEffect(() => {
    let sumTotal = 0;
    carrito.forEach((el) => (sumTotal += el.puntos));
    setTotal(sumTotal);
  }, [carrito]);

  useEffect(() => {
    GetFormaEntrega()
      .then((data) => setDeliveryOptions(data))
      .catch(() => console.error('error'));
  }, []);

  const handleSubmit = async () => {
    let estado = true;

    if (!validationCarritoForm(form, dictionary)) {
      // setFormErr(true);
      estado = false;
      return;
    } else if (form.idFormaEntrega === 1 && !form.domicilio) {
      if (!validarDomicilio(form)) {
        // setFormErr(true);
        estado = false;
        return;
      }
    }
    if (estado) {
      try {
        const res = await PostCanje(appContext?.user?.idPersona, appContext?.user?.idUsuario, {
          ...form,
          productos: carrito,
        });
        if (res && res.ok) {
          setOpen(true);
          const pedido = await res.json();
          setPedidoDetalle(pedido);
        } else {
          await res?.text();
          setOpenErr(true);
        }
      } catch (err: any) {
        if (err.response.status === 404)
          modalError('Actualice sus datos personales para poder realizar esta operación');
      }
    }
  };

  const handleFormaEntrega = (e: any) => {
    setForm({
      ...form,
      idFormaEntrega: e.target.value,
      idPuntoEntrega: undefined,
    });
  };

  return (
    <Grid container columns={12} spacing={3}>
      <Grid
        item
        xs={12}
        sm={12}
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        alignItems={{ xs: 'center', sm: 'flex-start' }}
      >
        <Typography variant="h6" color="initial">
          {dictionary?.formCarrito.row2.header.formaEntrega}
        </Typography>
        <select defaultValue={"default"} className="select-carrito" onChange={handleFormaEntrega}>
          <option value={"default"} disabled>
            {dictionary?.elijaOpcion}
          </option>
          {deliveryOptions && carrito.find((el) => !el.esGiftCard) ? (
            deliveryOptions.map((el) => (
              <option key={`forma-entrega-${el.idFormaEntrega}`} value={el.idFormaEntrega}>
                {el.descripcion}
              </option>
            ))
          ) : (
            <option value={3}>{dictionary?.formasEntregas.virtual}</option>
          )}
        </select>
        {/* <SelectPuntoEntrega
          form={form}
          idFormaEntrega={form.idFormaEntrega}
          setForm={setForm}
        /> */}

        {/* {((form.idFormaEntrega && form.idFormaEntrega === 1) ||
          form?.idPuntoEntrega) && (
          <PuntoEntregaDatos form={form} setForm={setForm} err={formErr} />
        )} */}
      </Grid>
      {/* Total */}
      <Grid
        item
        xs={12}
        sm={6}
        md={8}
        display={'flex'}
        flexDirection={'column'}
        alignItems={{ xs: 'center', sm: 'flex-start' }}
      >
        <Typography variant="h6" color="initial">
          {dictionary?.totalArticulos.replace('{n}', contadorItem)}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        gap={'30px'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" color="initial">
          {dictionary?.totalGeneralPuntos.replace('{n}', formatNumber(total))}
        </Typography>
        <ButtonSubmit onClick={handleSubmit}>{dictionary?.confirmarCanje}</ButtonSubmit>

        <Button variant="text" sx={{ maxWidth: '200px' }} onClick={() => navigate('/catalogo')}>
          {dictionary?.seguirComprando}
        </Button>
      </Grid>
      <ModalCanjeCarrito open={open} setOpen={setOpen} pedidoDetalle={pedidoDetalle} />
      {puntosPersona <= 0 && puntosPersona < total ? (
        <ModalError open={openErr} setOpen={setOpenErr} message="El usuario no dispone de presupuesto suficiente" />
      ) : (
        <ModalError open={openErr} setOpen={setOpenErr} message="El usuario no dispone de presupuesto suficiente" />
      )}
    </Grid>
  );
};

export default CarritoTotalContainer;
