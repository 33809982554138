import { useEffect, useState } from "react";
import CardVideo from "../CardVideo/CardVideo";
import { GetVideoByNivel } from "../../../../services/EducacionFinancieraService";
import { NivelEducacionVideoDTO } from "../../../../common/interfaces/EducacionFinancieraInterface";
import "./EducacionFinancieraVideosSection.css";
import piggiBank from "../../../../common/assets/images/Educacion/piggy-bank.jpg";

const EducacionFinancieraVideosSection = () => {
  const [cards, setCards] = useState<NivelEducacionVideoDTO[]>();
  const [card, setCard] = useState<NivelEducacionVideoDTO>();

  useEffect(() => {
    GetVideoByNivel().then((data) => setCards(data));
  }, []);

  return (
    <section className="Educacion-financiera-videos-section">
      <div className="Educacion-financiera-videos">
        <div className="edu-videos-container">
          {cards &&
            cards.map((el, index) => (
              <CardVideo
                card={card}
                setCard={setCard}
                key={`video-edu-${el.idVideo}`}
                cardInfo={el}
                eneable={
                  index === 0 || el.estado
                    ? el.estado === "FINALIZADO"
                    : false || cards[index - 1].estado === "FINALIZADO"
                    ? true
                    : false
                }
              />
            ))}
        </div>
        <img src={piggiBank} className="piggy-bank-img" alt="piggy bank" />
      </div>
    </section>
  );
};

export default EducacionFinancieraVideosSection;
