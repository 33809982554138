import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { FooterInterface } from "../../common/interfaces/FooterInterface";
import FooterRedes from "./FooterRedes/FooterRedes";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
import ES from "../../common/language/shared/es.json";
import EN from "../../common/language/shared/en.json";
import useLanguage from "../../common/hooks/useLanguage";

type Props = {
  footerInfo: FooterInterface | null;
};

const Footer: React.FC<Props> = ({ footerInfo }) => {
  const navigate = useNavigate();
  const { dictionary } = useLanguage({ EN, ES });

  return (
    <Box className="footer-container">
      <Grid
        container
        sx={{
          paddingTop: "20px",
          height: "150px",
          width: "100%",
          margin: 0,
          backgroundColor: "primary.main",
        }}>
        {/* LINEA BLANCA DE FOOTER */}
        <Box
          sx={{
            Top: "30px",
            width: "100%",
            height: "10px",
            backgroundColor: "white",
          }}></Box>
        {/* SECCION LOGO E INFO DE DERECHOS */}
        <Grid item xs={6} sm={6} md={4}>
          <Box ml={3}>
            <img
              className="logo-footer"
              onClick={() => navigate("/")}
              src={footerInfo?.logoUrl}
              alt="logo-footer"
            />
            <Typography
              variant="h6"
              fontSize={12}
              color="white"
              textAlign="center">
              {footerInfo?.leyendaDerechos}
            </Typography>
          </Box>
        </Grid>
        {/* SECCION REDES SOCIALES */}
        <Grid item xs={6} sm={6} md={4} paddingRight={3}>
          <FooterRedes footerInfo={footerInfo} />
        </Grid>
        {/* SECCION LINKS */}
        <Grid item xs={12} sm={12} md={4} paddingRight={3}>
          <nav className="footer-links">
            {footerInfo?.linkPreguntaFrecuente && (
              <li>
                <a
                  href={footerInfo?.linkPreguntaFrecuente}
                  target="_blank"
                  rel="noreferrer">
                  {footerInfo?.preguntasFrecuentes
                    ? footerInfo?.preguntasFrecuentes
                    : dictionary?.footer.faqs}
                </a>
              </li>
            )}
            {footerInfo?.linkTerminos && (
              <li>
                <a
                  href={footerInfo?.linkTerminos}
                  target="_blank"
                  rel="noreferrer">
                  {footerInfo?.terminosCondiciones
                    ? footerInfo?.terminosCondiciones
                    : dictionary?.footer.terms}
                </a>
              </li>
            )}
            {footerInfo?.linkPoliticasPrivacidad && (
              <li>
                <a
                  href={footerInfo?.linkPoliticasPrivacidad}
                  target="_blank"
                  rel="noreferrer">
                  {footerInfo?.politicasPrivacidad
                    ? footerInfo?.politicasPrivacidad
                    : dictionary?.footer.policy}
                </a>
              </li>
            )}
          </nav>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
