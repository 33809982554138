import { notDeepEqual } from "assert";
import { contextUserInterface } from "../common/interfaces/LoginInterface";
import { EncryptString } from "../utils/criptoUtils";
import { handleServiceError } from "./GiftCardService";
import { notifyManager } from "react-query";
import { fetchNoJsonRes } from "../utils/fetchUtils";
interface body {
  Email?: string;
  Clave?: string;
  dni?: string;
  ClienteId?: number | null;
}

const headerOptions = {
  "Content-Type": "application/json",
  valid:
    "98bd0aa2-95d3-4f5a-adcf-df2afaf1eed01de41feb-eab8-423e-998f-a6c960ffcb8b",
  "Content-Encoding": "gzip, deflate, br",
  Accept: "application/json",
};

export const postLogin = async (form: body) => {
  // const pwdcrypt: string = EncryptString(form.Clave);
  // form.Clave = pwdcrypt;
  try {
    const login_res = await postLoginFetch(form);
    if (login_res.ok) {
      const login = await login_res.json();
      const user: contextUserInterface = {
        nombre: login.nombre,
        apellido: login.apellido,
        email: login.email,
        clienteId: login.clienteId,
        idPersona: login.idPersona,
        idUsuario: login.idUsuario,
        idSector: login.idSector,
        avatar: login.avatar,
        bloqueado: login.bloqueado,
      };

      if (!user.bloqueado) {
        // Guardar en session
        sessionStorage.setItem("token", login.token);
        sessionStorage.setItem("user", JSON.stringify(user));
        sessionStorage.setItem(
          "expiredToken",
          JSON.stringify(login.expiredToken)
        );
      }

      return user;
    } else if (login_res.status === 401) {
      const errorData = await login_res.json();
      throw new Error(
        `401|${errorData.intentosFallidosRestantes}|${errorData.intentosFallidosPermitidos}`
      );
    } else if (login_res.status === 403) {
      throw new Error("403|Usuario bloqueado.");
    } else if (login_res.status === 404) {
      throw new Error("404|Usuario no encontrado.");
    } else {
      throw new Error("Error inesperado.");
    }
  } catch (error: any) {
    throw error;
  }
};

export const postLoginFetch = async (body: body) => {
  try {
    let response = fetchNoJsonRes("/api/usuario/login", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "X-Api-Version": 2.0,
        ...headerOptions,
      },
    });
    return response;
  } catch (error: any) {
    throw new Error("[Loginservice error]: " + error);
  }
};

export const postLogout = async () => {
  try {
    const response = await fetchNoJsonRes("/api/usuario/logout", {
      method: "POST",
      headers: {
        "X-Api-Version": "2.0",
        ...headerOptions,
      },
    });

    if (!response.ok) {
      throw new Error(`Error al cerrar sesión: ${response.status}`);
    }

    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("expiredToken");

    // window.location.href = "/login";
  } catch (error: any) {
    console.error("Error al cerrar sesión:", error);
  }
};

export const recuperarPassword = (body: {
  email: string;
  idCliente: number;
}) => {
  try {
    let res = fetchNoJsonRes("/api/usuario/recupero", {
      method: "POST",
      body: JSON.stringify(body),
      headers: { ...headerOptions },
    }).then((res) => res.json());

    return res;
  } catch (error: any) {
    throw new Error("[RecuperarPassword error]: " + error);
  }
};

export const verificarToken = async (token) => {
  try {
    let res = await fetchNoJsonRes(`/api/PrimerAcceso?token=${token}`, {
      method: "GET",
      headers: headerOptions,
    });

    if (res.status === 204) {
      throw new Error("204");
    }

    let data = await res.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const cambiarPassword = async (body: {
  email: string;
  clave: string;
  token: string;
}) => {
  try {
    let res = await fetchNoJsonRes("/api/PrimerAcceso/clave", {
      method: "PUT",
      body: JSON.stringify(body),
      headers: headerOptions,
    });

    if (!res.ok) {
      throw new Error(res.status.toString());
    }

    return res;
  } catch (error) {
    throw new Error(error.message);
  }
};
