import { fetchContent } from '../utils/fetchUtils';
import { setQueryParams } from '../utils/functions';
import { handleServiceError } from './GiftCardService';

export const carouselService = (id: number, lenguaje: string) => {
  try {
    let response = fetchContent(`/api/HomeCarousel/Cliente/${id}/lenguaje/${lenguaje}`, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    throw handleServiceError(error);
  }
};

export const categoriasPopularesService = (idCliente: number, idioma: string) => {
  try {
    let response = fetchContent(`/api/categoria/populares/${idCliente}?idioma=${idioma.toLocaleLowerCase()}`, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    throw new Error('[HomeService, categoriasPopularesService error]: ' + error);
  }
};

export const puntosHomeService = (idPersona: any, idCliente: number) => {
  try {
    let response = fetchContent(`/api/Persona/${idPersona}/cliente/${idCliente}/puntos`, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.log('[Home], puntosHomeService error]: ' + error);
    throw handleServiceError(error);
  }
};

export const puntosCryptoHomeService = () => {
  try {
    let response = fetchContent(`/api/liriumusuario/puntosporcrypto`, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.log('[Home], puntosHomeService error]: ' + error);
    throw handleServiceError(error);
  }
};

export const eduFinancieraService = () => {
  try {
    let response = fetchContent(`/api/NivelEducacionVideoUsuario/porcentaje`, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.log('[Home], eduFinancieraService error]: ' + error);
    throw handleServiceError(error);
  }
};

interface IMyReconocimientos {
  idioma: string;
  ordenamiento: string;
  pagina: number;
  CantidadRegistrosPorPagina: number;
  usuario?: string;
}

export const GetMyReconocimientos = async (props: IMyReconocimientos) => {
  const url = setQueryParams('api/reconocimiento/my', props);

  try {
    const response = await fetchContent(url, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.log('[error][GetMyReconocimientos]: ' + error);
    throw handleServiceError(error);
  }
};

export const GetMasReconocidosDelMes = async (lang: string) => {
  try {
    const fechaHasta = new Date();
    const fechaUnMesAtras = new Date();

    // Resta un mes a la fecha actual
    fechaUnMesAtras.setMonth(fechaHasta.getMonth() - 1);

    // Si el mes actual es enero, ajusta el año y el mes
    if (fechaHasta.getMonth() === 0) {
      fechaUnMesAtras.setFullYear(fechaHasta.getFullYear() - 1);
      fechaUnMesAtras.setMonth(11);
    }

    const body = {
      FechaDesde: fechaUnMesAtras,
      FechaHasta: fechaHasta,
      CantidadRegistros: 10,
    };

    const response = await fetchContent(`api/reconocimiento/dashboard/ranking?idioma=${lang}`, {
      method: 'POST',
      body: body,
    });

    return response;
  } catch (error: any) {
    console.error('[GetMasReconocidosDelMes] error: ', error);
    throw handleServiceError(error);
  }
};

export const GetCategoriasCanjeadasByUsuario = async (
  idPersona: number | undefined,
  lang: string,
  sort: string,
  limit: number
) => {
  if (!idPersona) {
    const user: string | null = sessionStorage.getItem('user');
    if (user) {
      idPersona = JSON.parse(user).idPersona;
    }
  }

  try {
    let response = await fetchContent(
      `api/movimiento/dashboard/categoriascanjeadas/${idPersona}?idioma=${lang}&ordenamiento=${sort}&limit=${limit}`,
      {
        method: 'GET',
      }
    );
    return response;
  } catch (error: any) {
    console.error('[GetCategoriasCanjeadasByUsuario]: ' + error);
    throw handleServiceError(error);
  }
};
