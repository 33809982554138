import { Box, Grid, Typography, TextField } from "@mui/material";
import "./FormularioReconocimiento.css";
import TiposReconocimientosContainer from "../TiposReconocimientosContainer/TiposReconocimientosContainer";
import SeleccionarIntegrantesContainer from "../SeleccionarIntegrantesContainer/SeleccionarIntegrantesContainer";
import ES from "../../../../../common/language/reconocimiento/es.json";
import EN from "../../../../../common/language/reconocimiento/en.json";
import useLanguage from "../../../../../common/hooks/useLanguage";
import { ModalReconocimiento } from "../ModalReconocimiento/ModalREconocimiento";
import useRealizarReconocimiento from "../../../../../common/hooks/useRealizarReconocimiento";
import ButtonSubmit from "../../../../../components/ButtonSubmit/ButtonSubmit";

const FormularioReconocimiento = () => {
  const {
    form,
    setForm,
    open,
    setOpen,
    reset,
    setReset,
    handleSubmit,
    errorMessage,
  } = useRealizarReconocimiento();
  const { dictionary } = useLanguage({ EN, ES });

  return (
    <div className="formulario-reconocimiento-section">
      <Box
        border={2}
        borderColor={"primary"}
        borderRadius={2}
        padding={3}
        maxWidth={"1300px"}
        width={"100%"}>
        <Grid
          container
          spacing={2}
          columns={12}
          className="input-reconocimiento-container">
          <Grid item xs={12}>
            <Typography variant="h5" color="primary">
              {dictionary?.buscaYReconoce}
            </Typography>
            <Typography fontSize={16} color="grey">
              {dictionary?.aclaracion}
            </Typography>
          </Grid>
          {errorMessage && (
            <Grid item xs={12}>
              <Typography variant="body1" color="error">
                {errorMessage}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <SeleccionarIntegrantesContainer
              form={form}
              setForm={setForm}
              reset={reset}
            />
          </Grid>
          <TiposReconocimientosContainer form={form} setForm={setForm} />
          <Grid item xs={12}>
            <Typography fontSize={18} color="primary" pb={2}>
              {dictionary?.formBusqueda.comentario} (*)
            </Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={4}
              value={form.descripcion}
              className="reconocimiento-comentario"
              onChange={(e: any) =>
                setForm({ ...form, descripcion: e.target.value })
              }
            />
            <Box textAlign={"center"}>
              <ButtonSubmit
                onClick={handleSubmit}
                disabled={
                  !form.descripcion ||
                  !form.idTipoReconocimiento ||
                  form.usuariosReconocidos.length === 0
                }>
                {dictionary?.formBusqueda.enviar}
              </ButtonSubmit>
            </Box>
          </Grid>
        </Grid>
        <ModalReconocimiento
          reset={reset}
          setReset={setReset}
          open={open}
          setOpen={setOpen}
        />
      </Box>
    </div>
  );
};

export default FormularioReconocimiento;
