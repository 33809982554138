import { Box, Button, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import cryptoImg from "../../../../common/assets/images/Crypto/crypto-img-log.jpeg";
import ShortLine from "../../../../components/ShortLine/ShortLine";
import "./PreLoginCrypto.css";
import useLanguage from "../../../../common/hooks/useLanguage";
import ES from "../../../../common/language/crypto/es.json";
import EN from "../../../../common/language/crypto/en.json";
import parse from "html-react-parser";
import { AppContext } from "../../../../context/AppContext";
import { useNavigate } from "react-router-dom";

const PreLoginCrypto = () => {
  const { dictionary } = useLanguage({ EN, ES });
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <Grid container columns={12} spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <Box m={2}>
          <img
            src={cryptoImg}
            alt="crypto-img"
            className="img-cripto-pre-login"
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Box m={2} maxWidth={"1200px"}>
          <Typography variant="h4" color="primary">
            {dictionary?.inicio.titulo}
            <ShortLine position="left" />
          </Typography>
          <Typography variant="subtitle1" color="primary">
            {parse(dictionary?.inicio.descripcion)}
          </Typography>
          {appContext?.user && (
            <Box textAlign="center" mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/crypto/alta")}>
                {dictionary?.inicio.siguiente}
              </Button>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default PreLoginCrypto;
