import Typography from "@mui/material/Typography";
import ShortLine from "../../components/ShortLine/ShortLine";
import useLanguage from "../../common/hooks/useLanguage";
import ES from "../../common/language/contactanos/es.json";
import EN from "../../common/language/contactanos/en.json";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "./AgradecimientoContacto.css";
import { Box } from "@mui/material";

const AgradecimientoContacto = () => {
  const { dictionary } = useLanguage({ EN, ES });
  const navigate = useNavigate();
  return (
    <div className="agradecimiento-contacto-page">
      <div>
        <Typography variant="h4" color="primary">
          {dictionary?.contactanos}
          <ShortLine position="left" />
        </Typography>
      </div>
      <div className="agradecimiento-contacto-page-info">
        <Typography variant="h4" textAlign={"center"}>
          {dictionary?.gracias}
        </Typography>
        <Box textAlign={"center"}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/")}>
            {dictionary?.aceptar}
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default AgradecimientoContacto;
