import { Divider, Grid, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import InputForm from "../../../../components/Inputs/InputForm/InputForm";
import ES from "../../../../common/language/users/es.json";
import EN from "../../../../common/language/users/en.json";
import useLanguage from "../../../../common/hooks/useLanguage";

type Props = {
  form: any;
  setForm: Dispatch<SetStateAction<any>>;
};

const DatosDeContacto: React.FC<Props> = ({ form, setForm }) => {
  const { dictionary } = useLanguage({ EN, ES });
  return (
    <div>
      <Typography variant="h5" color="initial">
        {dictionary?.perfil.datosContacto}
      </Typography>
      <Divider sx={{ marginBottom: "30px" }} />
      <Grid container columns={12} spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <InputForm
            readonly={false}
            name="telefonoFijoCodigoPais"
            label={dictionary?.perfil.codigoPaisTelefonoFijo}
            type="text"
            form={form}
            setForm={setForm}
          />
          <InputForm
            readonly={false}
            name="telefonoMovilCodigoPais"
            label={dictionary?.perfil.codigoPaisTelefonoMovil}
            type="text"
            form={form}
            setForm={setForm}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputForm
            readonly={false}
            name="telefonoFijoCodigoArea"
            label={dictionary?.perfil.codigoAreaTelefonoFijo}
            type="text"
            form={form}
            setForm={setForm}
          />
          <InputForm
            readonly={false}
            name="telefonoMovilCodigoArea"
            label={dictionary?.perfil.codigoAreaTelefonoMovil}
            type="text"
            form={form}
            setForm={setForm}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <InputForm
            readonly={false}
            name="telefonoFijo"
            label={dictionary?.perfil.telefonoFijo}
            type="text"
            form={form}
            setForm={setForm}
          />
          <InputForm
            readonly={false}
            name="telefonoMovil"
            label={dictionary?.perfil.telefonoMovil}
            type="text"
            form={form}
            setForm={setForm}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DatosDeContacto;
