import { toast } from "react-toastify";
import { IPuntoEntrega } from "../common/interfaces/CarritoInterface";
import { Usuario } from "../common/interfaces/ComunInterface";
import { CustomerDTO } from "../common/interfaces/CryptoInterface";
import { ReconocimientoInterface } from "../common/interfaces/ReconocimientoInterface";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const validarLogin = (email: boolean) => {
  Toast.fire({
    icon: "error",
    title: `Errores en ${
      email ? "email" : "DNI"
    } o clave o no tiene permitido el acceso`,
  });
};

export const validarCamposReconocimiento = (
  campos: ReconocimientoInterface,
  dictionary: any
) => {
  if (!campos.usuariosReconocidos || campos.usuariosReconocidos.length <= 0) {
    Toast.fire({
      icon: "error",
      title: dictionary.formCrear.error.requeridos.nombre,
    });
    return false;
  }
  if (!campos.idTipoReconocimiento) {
    Toast.fire({
      icon: "error",
      title: dictionary.formCrear.error.requeridos.tipo,
    });
    return false;
  }
  if (!campos.descripcion?.trim()) {
    Toast.fire({
      icon: "error",
      title: dictionary.formCrear.error.requeridos.comentario,
    });
    return false;
  }

  return true;
};

export const validarUsuariosSeleccionados = (
  aprobados: number[] | null | undefined
) => {
  if (!aprobados || aprobados.length <= 0) {
    Toast.fire({
      icon: "error",
      title: "Seleccione un usuario",
    });
    return false;
  }
  return true;
};

export const validarPutReconocimeinto = (estado: boolean) => {
  estado
    ? Toast.fire({
        icon: "success",
        title: "Se ha realizado la aprobación",
      })
    : Toast.fire({
        icon: "error",
        title: "Hubo un problema para procesar la aprobación",
      });
};

export const validarPostReconocimiento = (estado: boolean) => {
  Toast.fire({
    icon: estado ? "success" : "error",
    title: estado
      ? "Se ha realizado el reconocimiento"
      : "Hubo un problema para procesar el reconocimiento",
  });
};

export const validarPostOrder = (estado: boolean, msj: string) => {
  Toast.fire({
    icon: estado ? "success" : "error",
    title: msj,
  });
};

export const validarPostConfirmacion = (estado: boolean, msj: string) => {
  Toast.fire({
    icon: estado ? "success" : "error",
    title: msj,
  });
};

export const validarPutVideo = () => {
  Toast.fire({
    icon: "success",
    title: "Se actualizaron los datos",
  });
};

export const modalError = (msj: string, tiempo?: number) => {
  Toast.fire({
    icon: "error",
    title: msj,
    timer: tiempo | 4000,
    width: "auto",
  });
};

export const modalSuccess = (msj: string, tiempo?: number) => {
  Toast.fire({
    icon: "success",
    title: msj,
    timer: tiempo | 6000,
    width: "auto",
  });
};

export const validarCamposDatosCuenta = (form: Usuario | null) => {
  if (form) {
    if (!form.nombre?.trim()) {
      toast("Nombre requerido");

      return false;
    }
    if (!form.apellido?.trim()) {
      toast("Apellido requerido");

      return false;
    }

    if (!form.email?.trim()) {
      toast("Email requerido");

      return false;
    }

    if (form.idRol === 0) {
      toast("Seleccione un rol");

      return false;
    }

    if (!form.clave) {
      toast("Contraseña requerida");
      return false;
    }

    if (form.clave !== form.clave2) {
      toast(
        "La contraseñas no coinciden, Contraseña y Repetir contraseña deben ser iguales"
      );

      return false;
    }
  } else {
    return false;
  }
  return true;
};

interface mjsErrRegalo {
  ingreseNombre: string;
  ingreseEmail: string;
  ingreseUnComentario: string;
}

export const validarRegalo = (
  form: { PersonaRegalo: string; Email: string; Comentario: string },
  msjErr: mjsErrRegalo
) => {
  if (!form.PersonaRegalo.trim()) {
    modalError(msjErr.ingreseNombre);
    return false;
  }
  if (!form.Email.trim()) {
    modalError(msjErr.ingreseEmail);
    return false;
  }
  if (!form.Comentario.trim()) {
    modalError(msjErr.ingreseUnComentario);
    return false;
  }
  return true;
};

export const validarCamposAltaCripto = (form: CustomerDTO) => {
  let estado = true;
  let keys = Object.keys(form) as (keyof CustomerDTO)[];
  keys = keys.filter((el) => el !== "middle_name");
  for (let i of keys) {
    if (!form[i]) {
      estado = false;
      modalError("Faltan completar campos");
      break;
    }
  }
  return estado;
};

export const validarDomicilio = (form: IPuntoEntrega): boolean => {
  let estado = true;

  if (!form.calle || form.calle.trim().length <= 0) {
    modalError("Falta calle");
    estado = false;
    return estado;
  } else if (!form.calleNumero || isNaN(form.calleNumero)) {
    modalError("Falta numero");
    estado = false;
    return estado;
  } else if (!form.piso || form.piso.trim().length <= 0) {
    modalError("Falta piso");
    estado = false;
    return estado;
  }
  if (!form.codigoPostal || form.codigoPostal.trim().length <= 0) {
    modalError("Falta Código postal");
    return false;
  } else if (!form.idPais) {
    modalError("Falta pais");
    estado = false;
    return estado;
  } else if (!form.idProvincia) {
    modalError("Falta provincia");
    estado = false;
    return estado;
  } else if (!form.idLocalidad) {
    modalError("Falta ciudad");
    estado = false;
    return estado;
  }

  return estado;
};
