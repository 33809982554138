import { Grid, Typography, Button, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IDetalleProducto } from '../../../../common/interfaces/ProductoInterface';
import Loading from '../../../../components/Loaders/Loading';
import { ModalRegalo } from '../ModalRegalo/ModalRegalo';
import { ModalTerminosYCondiciones } from '../ModalTerminosCondiciones/ModalTerminosCondiciones';
import FichasPrecioContainer from '../../components/FichasPrecioContainer/FichasPrecioContainer';
import useProducto from '../../../../common/hooks/useProducto';
import CustomCount from '../CustomCount/CustomCount';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import ModalBranches from '../ModalBranches/ModalBranches';
import { useLocation } from 'react-router-dom';
import { formatNumber } from '../../../../utils/functions';
import './ProductoDetalle.css';

type Props = {
  detalleProd: IDetalleProducto | null;
};

const ProductoDetalle: React.FC<Props> = ({ detalleProd }) => {
  const {
    puntos,
    importe,
    setImporte,
    handleCanjear,
    calcularPuntos,
    dictionary,
    producto,
    setProducto,
    branches,
    prevPuntos,
    oferta,
  } = useProducto(detalleProd?.idProducto.toString());
  const [error, setError] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const { state } = useLocation();

  useEffect(() => {
    if (detalleProd && producto) {
      setImporte(producto.precio);
    }
  }, [detalleProd, setImporte, producto]);

  const getCodigoMonedaParseado = (codigoMoneda: string) => {
    // tries to parse to int
    try {
      const isInt: boolean = !isNaN(parseInt(codigoMoneda));
      if (isInt) {
        return '';
      } else {
        return codigoMoneda;
      }
    } catch (error) {
      return '';
    }
  };

  return (
    <>
      <ModalBranches
        detailProd={detalleProd}
        handleClose={() => setOpen(!open)}
        open={open}
        dictionary={dictionary}
        branches={branches}
      />
      {detalleProd ? (
        <Grid container columns={12} p={2} maxWidth={'1400px'}>
          <Grid item sm={12} md={5}>
            <img src={detalleProd.bigPicture} className="img-producto-detalle" alt="img-producto-detalle" />
            {detalleProd.terminosYCondiciones && (
              <div className="terminos-condiciones-prod">
                <Typography fontSize={18} color="initial">
                  {dictionary?.terminos}
                </Typography>
                <ModalTerminosYCondiciones terminos={detalleProd.terminosYCondiciones} />
              </div>
            )}
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            p={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '25px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '15px',
                alignItems: 'center',
              }}
            >
              <Typography color="initial" fontSize={35}>
                {detalleProd.marcaDescripcion}
              </Typography>
              {state?.oferta ? (
                <Typography color="primary" fontSize={20} fontWeight={600} sx={{ marginTop: '3px' }}>
                  {state?.porcentajeDescuento}% OFF
                </Typography>
              ) : (
                ''
              )}
            </Box>
            <p>{detalleProd.descripcion}</p>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item xs={12} sm={4} md={3}>
                {producto &&
                oferta &&
                // typeof prevPuntos === "number" &&
                !!prevPuntos ? (
                  //state?.oferta ? (
                  <Typography fontSize={14} color="gray" sx={{ textDecoration: 'line-through' }}>
                    {dictionary?.puntos.producto.replace('{puntos}', formatNumber(prevPuntos))}
                  </Typography>
                ) : (
                  ''
                )}
                {producto && !!puntos && (
                  <Typography variant="h6" color="primary">
                    {dictionary?.puntos.producto.replace('{puntos}', formatNumber(puntos))}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={8} md={7}>
                {detalleProd.productos && detalleProd.productos.length > 1 ? (
                  <FichasPrecioContainer
                    detalleProd={detalleProd}
                    setImporte={setImporte}
                    setProducto={setProducto}
                    calcularPuntos={calcularPuntos}
                  />
                ) : (
                  detalleProd &&
                  detalleProd.precioDesde !== detalleProd.precioHasta && (
                    <CustomCount
                      maxPrice={detalleProd?.precioHasta}
                      minPrice={detalleProd?.precioDesde}
                      amount={importe}
                      setAmount={setImporte}
                      calculatePoints={calcularPuntos}
                      error={error}
                      setError={setError}
                      exchange={getCodigoMonedaParseado(producto?.codigoMoneda)}
                      product={producto}
                    />
                  )
                )}
              </Grid>
            </Grid>
            <Grid container columns={12} spacing={3}>
              <Grid item sm={6} md={6}>
                <ModalRegalo
                  detalleProd={detalleProd}
                  producto={producto}
                  puntos={puntos}
                  importe={importe}
                  disabled={
                    !producto ||
                    (error && !detalleProd.precioHasta && detalleProd.productos.length > 1) ||
                    (error && detalleProd.precioHasta && detalleProd.productos.length >= 1)
                  }
                />
              </Grid>
              <Grid item sm={6} md={6}>
                <Button
                  disabled={
                    !producto ||
                    (error && !detalleProd.precioHasta && detalleProd.productos.length > 1) ||
                    (error && detalleProd.precioHasta && detalleProd.productos.length >= 1)
                  }
                  variant="contained"
                  color="btnPrimary"
                  fullWidth
                  onClick={handleCanjear}
                >
                  {dictionary?.canjear}
                </Button>
              </Grid>
            </Grid>
            {detalleProd?.esOhGiftCard && (
              <Grid item columns={12}>
                <Button
                  disabled={!producto || error}
                  variant="text"
                  color="btnPrimary"
                  startIcon={<PinDropOutlinedIcon />}
                  onClick={() => setOpen(!open)}
                >
                  {dictionary?.sucursales}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ProductoDetalle;
