import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { AppBar, Box, Hidden, Toolbar } from "@mui/material";
import CenterNav from "./CenterNav/CenterNav";
import "./Header.css";
import { MenuDrawer } from "../Menu/MenuDrawer/MenuDrawer";
import { LanguageSelect } from "../LanguageSelect/LanguageSelect";
import { Carrito } from "../Carrito/Carrito";
import ES from "../../common/language/shared/es.json";
import EN from "../../common/language/shared/en.json";
import useLanguage from "../../common/hooks/useLanguage";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

type Props = {
  logo: {
    logoUrl: string;
    tipoImagenLogo: string | undefined;
    logo: string | undefined;
  };
  direction?: string;
  open?: boolean;
};

const RightNav = () => {
  const appContext = useContext(AppContext);
  const { dictionary } = useLanguage({ EN, ES });

  return (
    <Box
      fontSize={14}
      color="primary.main"
      className="header-nav"
      justifyContent="center"
      display={"flex"}
      alignContent={"center"}
      zIndex={2000}>
      {!appContext?.user && (
        <>
          <Hidden smDown={true}>
            <Link to="/contacto" className="link-header">
              <PhoneInTalkOutlinedIcon sx={{ fontSize: 20 }} />
              <span>{dictionary?.header.contactanos}</span>
            </Link>
          </Hidden>
          <Link
            to="/login"
            className="link-header"
            onClick={() => appContext?.cerrarSession()}>
            <AccountCircleOutlinedIcon sx={{ fontSize: 20 }} />
            <Hidden smDown={true}>
              <span>
                {appContext?.user
                  ? dictionary?.header.cerrarSesion
                  : dictionary?.header.ingresa}
              </span>
            </Hidden>
          </Link>
        </>
      )}
      <LanguageSelect />
      {appContext?.user && <Carrito />}
    </Box>
  );
};

const Header: React.FC<Props> = ({ logo }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    if (currentScrollPos > 70) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <>
      {visible && (
        <Box sx={{ width: "100%", top: 0 }}>
          <AppBar
            sx={{
              height: "80px",
              display: "flex",
              justifyContent: "center",
            }}>
            <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
              <MenuDrawer />
              <Box className="logo-container">
                <img
                  src={logo?.logoUrl}
                  onClick={() => navigate("/")}
                  alt="logo"
                  loading="lazy"
                />
              </Box>
              <CenterNav />
              <RightNav />
            </Toolbar>
          </AppBar>
        </Box>
      )}
    </>
  );
};

export default Header;
