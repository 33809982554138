import {
  TipoMovimiento_CanjePuntosPorCrypto,
  cliente,
  headerOptions,
} from "../common/constants/constants";
import {
  ConfirmationInterface,
  LiriumOrder,
} from "../common/interfaces/CompraCryptoInterface";
import { Movimiento } from "../common/interfaces/ComunInterface";
import { Order } from "../common/interfaces/CryptoInterface";
import { fetchContent, fetchNoJsonRes } from "../utils/fetchUtils";
import { validarPostOrder } from "../utils/validaciones";
import { handleServiceError } from "./GiftCardService";
import { GetUsuarioLirium } from "./UsuarioService";
import { v4 as uuidv4 } from "uuid";

export const getClienteMoneda = async (idCliente: number) => {
  try {
    const response = await fetchContent(`/api/cliente/moneda/${idCliente}`, {
      method: "GET",
    });

    return response;
  } catch (error: any) {
    console.log("[CompraCrypto], getClienteMoneda [error]: " + error);
    throw handleServiceError(error);
  }
};

export const getExchangeRates = async (moneda: any) => {
  try {
    let response = await fetchContent(
      `/api/liriumorder/exchange_rates/${moneda}`,
      {
        method: "GET",
      }
    );

    const responseFilter = response?.quotes.filter(
      (el: { currency: string }) =>
        el.currency !== "GTETH" && el.currency !== "TBTC"
    );
    response.quotes = responseFilter;
    return response;
  } catch (error: any) {
    console.log("[CompraCrypto], getExchangeRates [error]: " + error);
    throw handleServiceError(error);
  }
};

export const getPersonaPuntos = async (
  idPersona: number | null | undefined,
  idCliente: number
) => {
  if (idPersona === undefined) {
    if (sessionStorage.getItem("token")) {
      idPersona = JSON.parse(
        sessionStorage.getItem("user") || "null"
      ).idPersona;
    }
  }
  try {
    let response = fetchNoJsonRes(
      `/api/Persona/${idPersona}/cliente/${idCliente}/puntos`,
      {
        method: "GET",
      }
    );
    return response;
  } catch (error: any) {
    throw new Error("[CompraCrypto], getPersonaPuntos [error]: " + error);
  }
};

export const GetImporteByPuntos = (
  idCliente: number,
  idusuario: number | undefined,
  idTipoMovimiento: number,
  puntos: number | undefined
) => {
  try {
    let response = fetchNoJsonRes(
      `/api/cliente/importeporpuntos/${idCliente}/${idusuario}/${idTipoMovimiento}/${puntos}`,
      {
        method: "GET",
      }
    );
    return response;
  } catch (error: any) {
    throw new Error("[CompraCrypto], getExchangeRates [error]: " + error);
  }
};

export const getCotizacion = () => {
  try {
    let response = fetchContent(`/api/cotizacion/ultima`, {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    throw new Error("[CompraCrypto], getExchangeRates [error]: " + error);
  }
};

export const postOrder = async (
  idUsuario: number | undefined,
  currency: string,
  amount: string,
  dictionary: any
) => {
  let order_return: Order | null = null;
  const customerId = await GetUsuarioLirium(idUsuario);

  let moneda: string = "";
  //moneda del cliente

  const clientemoneda = await getClienteMoneda(cliente);

  if (clientemoneda !== null || clientemoneda.trim()) {
    moneda = clientemoneda.alfa3;
  }

  const order: Order = {
    reference_id: uuidv4(),
    operation: "buy",
    asset: { currency: currency },
    buy: {
      settlement: {
        currency: moneda,
        amount: amount,
      },
    },
  };
  const _order_res = await postOrderFetch(order, customerId.id);

  if (_order_res.ok) {
    if (_order_res.status !== 204) {
      const _order = await _order_res.json();
      order_return = _order;
      // Inserta en tabla Local
      if (_order !== null) {
        var montoOrigen = _order.buy.settlement.amount;
        var montoDestino = _order.asset.amount;

        const liriumOrder: LiriumOrder = {
          idUsuario: idUsuario,
          id: _order.id,
          fechaAlta: new Date(Date.now()).toISOString(),
          referenceId: order.reference_id,
          estado: _order.state,
          tipoOrden: "buy",
          monedaOrigen: _order.buy.settlement.currency,
          montoOrigen: montoOrigen,
          monedaDestino: _order.asset.currency,
          montoDestino: montoDestino,
          montoOrigenDecimal: parseFloat(montoOrigen),
          montoDestinoDecimal: parseFloat(montoDestino),
        };

        await postLiriumOrder(liriumOrder);
      }
    }
  }

  validarPostOrder(_order_res.ok, dictionary);

  return order_return;
  //----Agregar descuento de puntos ----
};

const postOrderFetch = async (body: Order, customerId: number) => {
  try {
    let response = await fetchNoJsonRes(
      `/api/liriumorder/orders/${customerId}`,
      {
        method: "POST",
        headers: headerOptions,
        body: JSON.stringify(body),
      }
    );
    return response;
  } catch (error: any) {
    throw new Error("postOrderFetch error]: " + error);
  }
};

export const postLiriumOrder = (liriumOrder: LiriumOrder) => {
  try {
    let response = fetchNoJsonRes(`/api/liriumorder`, {
      method: "POST",
      body: JSON.stringify(liriumOrder),
      headers: headerOptions,
    });
    return response;
  } catch (error: any) {
    throw new Error("postLiriumOrder error]: " + error);
  }
};

export const PostConfirmarOrder = async (
  idPersona: number | undefined,
  idUsuario: number | undefined,
  ordenId: string,
  currency: string,
  amount: string,
  codigoConfirmacion: string,
  puntos: number,
  amountCrypto: string
) => {
  let estado = true;

  const customerId = await GetUsuarioLirium(idUsuario);

  //Antes de confirmar valida si los puntos no superan a los que tiene el usuario
  var idCliente = cliente;
  // var idPersona = _userManager.GetPersonaId();
  const confirmation: ConfirmationInterface = {
    confirmation_code: "",
    customer: {
      amount: amount,
      currency: currency,
    },
  };

  const puntosExsitentes_res = await getPersonaPuntos(idPersona, idCliente);
  const puntosExsitentes = await puntosExsitentes_res.json();

  if (puntosExsitentes >= puntos) {
    const _confirm_res = await PostConfirmarOrderFetch(
      confirmation,
      customerId.id,
      ordenId
    );

    if (_confirm_res.ok) {
      if (_confirm_res.status !== 204) {
        //----Agregar movimiento de canje-descuento de puntos ----
        const _confirm = await _confirm_res.json();

        let _movimiento: Movimiento = {
          fecha: new Date(Date.now()).toISOString(),
          idCliente: idCliente,
          idPersona: idPersona,
          idTipoMovimiento: TipoMovimiento_CanjePuntosPorCrypto,
          idEstado: null,
          comentario: "Se compraron " + amountCrypto + " " + currency,
        };
        const _movimiento_a_agregar = {
          idEstado: null,
          idProducto: null,
          puntos: puntos,
          idClienteConversion: null,
          cantidad: 1,
        };
        _movimiento.movimientoDetalles
          ? _movimiento.movimientoDetalles.push(_movimiento_a_agregar)
          : (_movimiento.movimientoDetalles = [_movimiento_a_agregar]);

        const _movimiento_res = await PostMovimientoFetch(_movimiento);
        _movimiento = await _movimiento_res.json();
        if (!_movimiento_res.ok) {
          estado = false;
        }

        //Actualiza estado y fecha de tabla local

        await PatchConfirmarOrder(
          ordenId,
          _confirm.state,
          _movimiento.idMovimiento
        );
      } else {
        estado = false;
      }
    } else {
      estado = false;
    }
  } else {
    estado = false;
  }
  return estado;
};

const PostConfirmarOrderFetch = (
  confirmation: any,
  customerId: string,
  ordenId: string
) => {
  try {
    let response = fetchNoJsonRes(
      `/api/liriumorder/orders/confirm/${customerId}/${ordenId}`,
      {
        method: "POST",
        body: JSON.stringify(confirmation),
        headers: headerOptions,
      }
    );
    return response;
  } catch (error: any) {
    throw new Error("postLiriumOrder error]: " + error);
  }
};

const PostMovimientoFetch = (body: Movimiento) => {
  try {
    let response = fetchNoJsonRes(`/api/movimiento`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: headerOptions,
    });
    return response;
  } catch (error: any) {
    throw new Error("postLiriumOrder error]: " + error);
  }
};

const PatchConfirmarOrder = (
  ordenId: string,
  state: any,
  id: number | undefined
) => {
  try {
    let response = fetchNoJsonRes(
      `/api/liriumorder/${ordenId}/${state}/${id}`,
      {
        method: "PATCH",
        // body: JSON.stringify(body),
        headers: headerOptions,
      }
    );
    return response;
  } catch (error: any) {
    throw new Error("postLiriumOrder error]: " + error);
  }
};
