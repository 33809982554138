import { IFormCarrito } from "../../../common/interfaces/CarritoInterface";
import { modalError } from "../../../utils/validaciones";

export const validationCarritoForm = (
  form: IFormCarrito,
  dictionary: any
): boolean => {
  let estado = true;
  let diccionarioErr: string = "";
  if (!form.idFormaEntrega) {
    diccionarioErr = "idFormaEntrega";
    estado = false;
  } else if (form.idFormaEntrega === 2 && !form.idPuntoEntrega) {
    diccionarioErr = "idPuntoEntrega";
    estado = false;
  }
  !estado && modalError(dictionary?.formCarrito.validaciones[diccionarioErr]);
  return estado;
};
