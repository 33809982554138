import { Typography } from "@mui/material";
import { useEffect, useState, useContext, useCallback } from "react";
import useLanguage from "../../../common/hooks/useLanguage";
import ES from "../../../common/language/crypto/es.json";
import EN from "../../../common/language/crypto/en.json";
import ShortLine from "../../../components/ShortLine/ShortLine";
import "./AltaCripto.css";
import { AppContext } from "../../../context/AppContext";
import { getPersonaByIdUsuario } from "../../../services/UsuarioService";
import { CustomerDTO } from "../../../common/interfaces/CryptoInterface";
import DatosGeneralesAltaCripto from "./components/DatosGeneralesAltaCripto/DatosGeneralesAltaCripto";
import AltaCriptoFotoFrente from "./components/AltaCriptoFotoFrente/AltaCriptoFotoFrente";
import { getCustomerId } from "../../../services/CryptoService";
import { Loading } from "../../../components";

const formInit: CustomerDTO = {
  reference_id: "",
  national_id: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  date_of_birth: "2023/03/11",
  address_line1: "",
  zip_code: "",
  email: "",
  cellphone: "",
  provincia: "",
  pais: "",
  localidad: "",
};

const AltaCripto = () => {
  const [form, setForm] = useState<CustomerDTO>(formInit);
  const [customer, setCustomer] = useState(false);
  const [loading, setLoading] = useState(true);
  const { dictionary } = useLanguage({ EN, ES });
  const appContext = useContext(AppContext);

  const fetchCustomer = useCallback(async () => {
    try {
      const customer = await getCustomerId(appContext?.user?.idUsuario);
      if (customer.ok) {
        setCustomer(true);
        setLoading(false);
      }
    } catch (err: any) {
      setCustomer(false);
    } finally {
      setLoading(false);
    }
  }, [appContext]);

  useEffect(() => {
    getPersonaByIdUsuario(appContext?.user?.idUsuario)
      .then((data) =>
        setForm((prev) => ({
          ...prev,
          reference_id: data.idUsuarioNavigation.usuarioDvh,
          national_id: data.idUsuarioNavigation.dni,
          first_name: data.nombre,
          middle_name: "",
          last_name: data.apellido,
          date_of_birth: "",
          address_line1: data.domicilio,
          zip_code: data.cp,
          email: data.email,
          cellphone: data.telefonoMovil,
        }))
      )
      .catch((err) => {
        if (err.response.status === 404) {
          console.error(err);
        }
      });
    fetchCustomer();
  }, [appContext, fetchCustomer]);

  useEffect(() => {
    customer && setLoading(false);
  }, [customer]);

  return (
    <>
      {!loading ? (
        <div className="alta-cripto-section">
          <Typography variant="h4" color="primary">
            {dictionary?.create.titulo}
            <ShortLine position="left" />
          </Typography>
          {!customer ? (
            <DatosGeneralesAltaCripto
              form={form}
              setForm={setForm}
              setCustomer={setCustomer}
            />
          ) : (
            <AltaCriptoFotoFrente />
          )}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default AltaCripto;
