import { useContext } from 'react';
import { DCaregoriasCanjeados } from '../../../../../../common/interfaces/HomeInterace';
import { AppContext, LanguageContext } from '../../../../../../context';
import { GetCategoriasCanjeadasByUsuario } from '../../../../../../services/HomeService';
import { Box, LinearProgress, linearProgressClasses, styled, Typography } from '@mui/material';
import { modalError } from '../../../../../../utils/validaciones';
import useLanguage from '../../../../../../common/hooks/useLanguage';
import ES from '../../../../../../common/language/home/es.json';
import EN from '../../../../../../common/language/home/en.json';
import { MOST_CONSUMED_PRODUCTS } from '../../../../../../common/constants/constants';
import useCustomQuery from '../../../../../../common/hooks/useCustomQuery';
import { formatNumber } from '../../../../../../utils/functions';
import './CategoriaProgressBar.css';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: '80%',
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const CategoriaProgressBar = () => {
  const appContext = useContext(AppContext);
  const language = useContext(LanguageContext);
  const { dictionary } = useLanguage({ EN, ES });
  const { data: catCan } = useCustomQuery({
    queryKey: ['catCan', appContext?.user?.idPersona],
    queryFn: async () =>
      await GetCategoriasCanjeadasByUsuario(
        appContext?.user?.idPersona,
        language.language,
        MOST_CONSUMED_PRODUCTS.sort,
        MOST_CONSUMED_PRODUCTS.limit
      ),
    onError: (error: any) => {
      const errorMessage = error instanceof Error ? error.message : 'Error al traer Categorias';
      modalError('Error al traer Categorias\n' + errorMessage);
    },
  });

  return (
    <Box className="categoria-progress-bar-section">
      {catCan?.length > 0 ? (
        catCan.map((element: DCaregoriasCanjeados, index: number) => (
          <Box className="home-categoria-progress-bar" key={`progress-bar-cat${element.nombre}-${index}`}>
            <Box>{element.nombre}</Box>
            <Box className="home-gc-progress-bar">
              <BorderLinearProgress
                sx={{
                  height: '10px',
                  [`& .${linearProgressClasses.bar}`]: {
                    borderRadius: 5,
                    backgroundColor: element.color,
                  },
                }}
                variant="determinate"
                value={element.porcentaje}
              />
              <Box className="home-gc-category-porciento">{formatNumber(element.porcentaje)}%</Box>
            </Box>
          </Box>
        ))
      ) : (
        <Typography align='center'>{dictionary?.tabla.sinDatos}</Typography>
      )}
    </Box>
  );
};

export default CategoriaProgressBar;
