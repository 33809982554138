import React from "react";
import "./Loading.css";
import { CircularProgress } from "@mui/material";

const Loading: React.FC = () => {
  return (
    <div className="Loading-section">
      <CircularProgress size={80} />
    </div>
  );
};

export default Loading;
