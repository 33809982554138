import { Box, FormControl, Grid } from "@mui/material";
import React, { ReactNode } from "react";
import "./ExchangeFormContainer.css";

type Props = {
  children: ReactNode;
};
const ExchangeFormContainer = ({ children }: Props) => {
  return (
    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
      <Box
        width={"95%"}
        maxWidth={"682px"}
        minHeight={"350px"}
        border={2}
        borderColor={"primary.main"}
        borderRadius={3}
        marginBottom={6}
      >
        <FormControl
          sx={{ m: 1, minWidth: 120, width: "100%" }}
          className="exchange-crypto"
        >
          {children}
        </FormControl>
      </Box>
    </Grid>
  );
};

export default ExchangeFormContainer;
