import { useContext, useState } from "react";
import { ReconocimientoInterface } from "../../common/interfaces/ReconocimientoInterface";
import { AppContext } from "../../context/AppContext";
import useLanguage from "./useLanguage";
import {
  modalError,
  validarCamposReconocimiento,
} from "../../utils/validaciones";
import { PostReconition } from "../../services/ReconocimientoService";
import ES from "../../common/language/reconocimiento/es.json";
import EN from "../../common/language/reconocimiento/en.json";

const initForm = {
  descripcion: "",
  idTipoReconocimiento: null,
  usuariosReconocidos: [],
};

export const translateError = (errorKey, dictionary) => {
  return dictionary?.form.errorMessages[errorKey] || dictionary?.form.error;
};

const useRealizarReconocimiento = () => {
  const [form, setForm] = useState<ReconocimientoInterface>(initForm);
  const [open, setOpen] = useState(false);
  const [reset, setReset] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const appContext = useContext(AppContext);
  const { dictionary } = useLanguage({ EN, ES });

  const handleSubmit = async () => {
    if (validarCamposReconocimiento(form, dictionary)) {
      try {
        await PostReconition(form, appContext?.user?.idUsuario);
        setForm(initForm);
        setOpen(true);
        setErrorMessage(null);
      } catch (error: any) {
        modalError(error.message || dictionary?.form.errorStatus);
        throw Error(error)
      }
    }
  };

  return {
    form,
    setForm,
    open,
    setOpen,
    reset,
    setReset,
    handleSubmit,
    errorMessage,
  };
};

export default useRealizarReconocimiento;
