import React, { createContext, useState } from "react";

type ContextType = {
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
};

export type GlobalContextProviderProps = {
  children: React.ReactNode;
};

export const LanguageContext = createContext<ContextType>(undefined!);

export const LanguageProvider = ({ children }: GlobalContextProviderProps) => {
  const [language, setLanguage] = useState("ES");

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage,
      }}>
      {children}
    </LanguageContext.Provider>
  );
};
