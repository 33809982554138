import React, { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import "./LoginFormContainer.css";
import { GetClienteDatosFooter } from "../../../../services/FooterService";
import { cliente } from "../../../../common/constants/constants";
type Props = {
  children: ReactElement;
};
export const LoginFormContainer: React.FC<Props> = ({ children }) => {
  const [logo, setLogo] = useState({
    tipoImagenLogo: "",
    logo: "",
  });

  useEffect(() => {
    GetClienteDatosFooter(cliente)
      .then((data) =>
        setLogo({ tipoImagenLogo: data?.tipoImagenLogo, logo: data?.logo })
      )
      .catch(() => console.error("GetClienteDatosFooter"));
  }, []);
  return (
    <>
      <Grid
        container
        sm={8}
        md={10}
        sx={{
          padding: "0 30px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "50px",
          position: "relative",
        }}>
        <Link to="/">
          <img
            className="imageMovile"
            alt="imageLogin"
            src={`data:${logo?.tipoImagenLogo};base64,${logo?.logo}`}
          />
        </Link>
        <Box className="login-elipse-top"></Box>
        <Box className="login-elipse-bottom"></Box>
        {children}
      </Grid>
    </>
  );
};
