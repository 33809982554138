import React from 'react';
import {
  Box,
  CircularProgress,
  Pagination,
  PaginationItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Usuario } from '../../../../common/interfaces/ComunInterface';
import { ThemeContext } from '../../../../context';
import { ArrowDropDown, ArrowDropUp, ArrowBack, ArrowForward, ImportExport } from '@mui/icons-material';
import { IMyTeamDictionary } from '../../interfaces';

type Props = {
  usuarios: Usuario[] | null;
  loading: boolean;
  totalPages: number;
  page: number;
  orden: { campo: string; ascendente: boolean };
  dictionary?: IMyTeamDictionary;
  setPage: (page: number) => void;
  cambiarOrdenamiento: (campo: string) => void;
};

const MiEquipoTable: React.FC<Props> = ({
  usuarios,
  loading,
  page,
  totalPages,
  orden,
  dictionary,
  setPage,
  cambiarOrdenamiento,
}) => {
  const theme = React.useContext(ThemeContext);

  const handleChangePag = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Box marginTop={3}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#E9E9E9' }}>
              {[
                {
                  label: dictionary?.dataTable.name,
                  campo: 'nombre',
                  hasOrdering: true,
                },
                {
                  label: dictionary?.dataTable.lastName,
                  campo: 'apellido',
                  hasOrdering: true,
                },
                {
                  label: dictionary?.dataTable.email,
                  campo: 'email',
                  hasOrdering: false,
                },
              ].map((row) => (
                <TableCell
                  key={`header-${row.campo}`}
                  align="center"
                  sx={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    cursor: row?.hasOrdering ? 'pointer' : 'default',
                  }}
                  onClick={() => row?.hasOrdering && cambiarOrdenamiento(row.campo)}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px',
                    }}
                  >
                    {row.label}
                    {row?.hasOrdering ? (
                      orden.campo === row.campo ? (
                        orden.ascendente ? (
                          <ArrowDropUp fontSize="small" />
                        ) : (
                          <ArrowDropDown fontSize="small" />
                        )
                      ) : (
                        <ImportExport fontSize="small" />
                      )
                    ) : null}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                  <CircularProgress />
                  <div>{dictionary?.dataTable.loading}</div>
                </TableCell>
              </TableRow>
            ) : usuarios && usuarios.length > 0 ? (
              usuarios.map((row) => (
                <TableRow key={row.idUsuario}>
                  <TableCell align="center">{row.nombre}</TableCell>
                  <TableCell align="center">{row.apellido}</TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={3}>
                  {dictionary?.dataTable.noResults}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Stack spacing={2}>
          <Pagination
            color="primary"
            shape="rounded"
            variant="outlined"
            page={page}
            count={totalPages}
            onChange={handleChangePag}
            renderItem={(item) => (
              <PaginationItem
                components={{ previous: ArrowBack, next: ArrowForward }}
                {...item}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: theme?.theme && theme?.theme[10] ? theme?.theme[10].color : 'primary', // Color de fondo para el número de página seleccionado
                    color: 'white', // Color del texto para el número de página seleccionado
                  },
                }}
              />
            )}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default MiEquipoTable;
