import { useContext, useEffect, useState } from "react";
import useCripto from "./useCripto";
import { Order } from "../../common/interfaces/CryptoInterface";
import useLanguage from "./useLanguage";
import ES from "../../common/language/crypto/es.json";
import EN from "../../common/language/crypto/en.json";
import { AppContext } from "../../context/AppContext";
import { SelectChangeEvent } from "@mui/material";
import {
  cambiarCriptoPorImporte,
  evaluarCamposCompraCrypto,
} from "../../utils/functions";
import { cliente } from "../constants/constants";
import {
  getPuntosByImporte,
  postOrderSell,
} from "../../services/VenderCryptoService";
import { validarPostOrder } from "../../utils/validaciones";

const useVentaCripto = () => {
  const [moneda, setMoneda] = useState<string>("BTC");
  const [textFields, setTextFields] = useState({
    puntos: 0,
    crypto: 0,
  });
  const [criptoDisponible, setCriptoDisponible] = useState<string>("0");
  const [okForm, setForm] = useState(false);
  const [orden, setOrden] = useState<Order>();

  const appContext = useContext(AppContext);
  const { dataExchange, dataCrypto } = useCripto("");
  const { dictionary } = useLanguage({ EN, ES });

  const handleChange = (event: SelectChangeEvent) => {
    setMoneda(event.target.value);
    const saldoXMoneda = dataCrypto.filter(
      (el: any) => el.currency === event.target.value
    );
    setCriptoDisponible(saldoXMoneda[0].amount);
  };

  useEffect(() => {
    const saldoXMoneda = dataCrypto.filter((el: any) => el.currency === moneda);
    setCriptoDisponible(saldoXMoneda[0].amount);
  }, [dataCrypto, moneda]);

  const handleChangeCripto = (event: any) => {
    event.target.value =
      event.target.value > parseFloat(criptoDisponible).toFixed(8)
        ? parseFloat(criptoDisponible).toFixed(8)
        : event.target.value;

    const importeRes = cambiarCriptoPorImporte(
      event.target.value,
      dataExchange.data,
      moneda
    );

    getPuntosByImporte(cliente, appContext?.user?.idUsuario, importeRes)
      .then((res) => res.json())
      .then((data) => {
        setTextFields({
          crypto: event.target.value,
          puntos: data,
        });
      });
  };

  const handleSubmit = async () => {
    if (
      evaluarCamposCompraCrypto(
        textFields.puntos,
        dictionary?.js.completarCampos
      )
    ) {
      let res = await postOrderSell(
        appContext?.user?.idUsuario,
        moneda,
        textFields.crypto.toString()
      );
      if (res) {
        validarPostOrder(true, dictionary?.js.generarOrdenSuccess);
        setForm(true);
        setOrden(res);
      } else {
        validarPostOrder(false, dictionary?.js.error);
      }
    }
  };

  return {
    //variable
    moneda,
    dataExchange,
    textFields,
    dictionary,
    criptoDisponible,
    orden,
    okForm,
    //metedo
    handleChange,
    handleChangeCripto,
    handleSubmit,
  };
};

export default useVentaCripto;
