import { headerOptions } from "../common/constants/constants";
import { ERROR_MESSAGES } from "../common/constants/errorMessages";
import {
  IFiltroCatalogo,
  Marca,
  MarcaDTO,
  Pais,
  bodyProductsInterface,
} from "../common/interfaces/GiftCardInterfaces";
import { fetchContent, fetchNoJsonRes } from "../utils/fetchUtils";

export const handleServiceError = (error: any) => {
  const errorMessage =
    ERROR_MESSAGES[error.code] || "Ocurrió un error inesperado";
  return new Error(`${errorMessage}`);
};

export const GetProducts = async (
  body: bodyProductsInterface,
  idCliente: number,
  paises: Pais[] | null
) => {
  try {
    if (body.paises === null) {
      const codigoPaises = paises?.map((el: Pais) => el.id);
      body = { ...body, paises: codigoPaises };
    }

    const response = await fetchContent(`api/Catalogo/${idCliente}`, {
      method: "POST",
      body: body,
      headers: { "x-api-version": "2.0" },
    });

    return response;
  } catch (error) {
    console.error("[GigtCard], GetProducts error: ", error);
    throw handleServiceError(error);
  }
};

export const productsPreLoginService = async (
  body: bodyProductsInterface,
  idCliente: number,
  paises: Pais[] | null
) => {
  try {
    if (body.paises === null) {
      paises = paises ? await GetPaisForCheckBoxList(idCliente) : paises;
      const codigoPaises = paises?.map((el: Pais) => el.id);
      body = { ...body, paises: codigoPaises };
    }

    const response = await fetchContent(`api/Catalogo/${idCliente}`, {
      method: "POST",
      body: body,
      headers: { "x-api-version": "2.0" },
    });

    return response;
  } catch (error: any) {
    throw new Error("[GigtCard], productsPreLoginService error]: " + error);
  }
};

export const categoriaService = (idCliente: number) => {
  try {
    let response = fetchContent(`/api/categoria/byfilterweb/${idCliente}/032`, {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    throw new Error("[GigtCard], productsPreLoginService error]: " + error);
  }
};

export const productsService = async (
  body: bodyProductsInterface,
  idCliente: number,
  paises: Pais[] | null
) => {
  try {
    if (body.paises === null) {
      paises = paises ? await GetPaisForCheckBoxList(idCliente) : paises;
      const codigoPaises = paises?.map((el: Pais) => el.id);
      body = { ...body, paises: codigoPaises };
    }

    let response = await fetchNoJsonRes(
      `/api/Cliente/${idCliente}/productos/giftcard`,
      {
        method: "POST",
        headers: headerOptions,
        body: JSON.stringify(body),
      }
    );
    return response;
  } catch (error: any) {
    throw new Error("[GigtCard], productsService error]: " + error);
  }
};

export const GetMarcasForCheckBoxList = async (idCliente: number) => {
  const marcas: Marca[] = await fetchContent(
    `/api/marca/byfilterweb/${idCliente}`,
    {
      method: "GET",
    }
  );

  const MarcasOrdenadas: Marca[] = marcas.sort(function (x, y) {
    if (x.descripcion < y.descripcion) {
      return -1;
    }

    if (x.descripcion > y.descripcion) {
      return 1;
    }
    return 0;
  });

  let marcasResponse: MarcaDTO[] = MarcasOrdenadas.map((el: Marca) => {
    return {
      idMarca: el.idMarca,
      descripcion: el.descripcion,
      cantidadProductos: el.cantidadProductos,
    };
  });

  return marcasResponse;
};

export const GetPaisForCheckBoxList = async (idCliente: number) => {
  try {
    const paises = await fetchNoJsonRes(
      `/api/clienteCatalogoPais/PaisesBy/${idCliente}`,
      {
        method: "GET",
      }
    );
    const res = await paises.json();
    return res;
  } catch (error: any) {
    console.log("[GigtCard], productsService error]: " + error);
    throw handleServiceError(error);
  }
};

export const getMarcasFetch = async (idCliente: number) => {
  try {
    let response = await fetchContent(`/api/marca/byfilterweb/${idCliente}`, {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    throw new Error("[GigtCard], productsService error]: " + error);
  }
};

export const GetFiltros = async (
  filteroBody: IFiltroCatalogo,
  idCliente: number
) => {
  try {
    const response = await fetchContent(`/api/Catalogo/${idCliente}/metadata`, {
      method: "POST",
      body: filteroBody,
      headers: { "x-api-version": "2.0" },
    });

    return response;
  } catch (error: any) {
    console.error(
      `Error GetFilteros [GigtCard], productsService error]: ${error}`
    );
    throw handleServiceError(error);
  }
};

export const getExchange = async (code) => {
  try {
    const res = await fetchContent(`/api/moneda/${code}`);
    return res;
  } catch (error) {
    console.error("[GigtCard], productsService error]: " + error);
    throw handleServiceError(error);
  }
};
