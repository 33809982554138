import { useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import {
  SectionReconocimiento,
  Dashboard,
  SectionFinancialLiteracy,
} from "../../components";
import useCatalogo from "../../common/hooks/useCatalogo";
import Carousel from "./components/Carousel";
import PopCategories from "./components/Dashboard/components/PopCategories/PopCategories";

const PreLogin = () => {
  return (
    <>
      <PopCategories />
      <SectionFinancialLiteracy />
      <SectionReconocimiento />
    </>
  );
};

export default function Home() {
  const appContext = useContext(AppContext);
  const { PreFetchProductos } = useCatalogo();

  useEffect(() => {
    PreFetchProductos();
  }, [PreFetchProductos]);

  return (
    <>
      <Carousel />
      {appContext?.user ? <Dashboard /> : <PreLogin />}
    </>
  );
}
