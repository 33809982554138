import { fetchContent } from "../utils/fetchUtils";
import { handleServiceError } from "./GiftCardService";

export const detalleService = (id: string | undefined) => {
  try {
    let response = fetchContent(`/api/producto/detalleweb/${id}`, {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    console.log("[ProductoPage], detalleService error]: " + error);
    throw handleServiceError(error);
  }
};

export const getDetalleProducto = async (id: string | number | undefined) => {
  try {
    let response = await fetchContent(`/api/producto/detalleweb/v2/${id}`, {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    console.log("[ProductoPage], detalleService error]: " + error);
    throw handleServiceError(error);
  }
};

export const getBranches = async (
  codigoProductoProveedor: string | number | undefined
) => {
  try {
    let response = await fetchContent(
      `/api/OhGiftCard/sucursales/${codigoProductoProveedor}`
    );
    return response;
  } catch (error: any) {
    console.log("[ProductoPage], branchesServices error]: " + error);
    throw handleServiceError(error);
  }
};
