import { useContext } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import PreLoginProducto from "./components/PreLoginProducto/PreLoginProducto";
import ProductoDetalle from "./components/ProductoDetalle/ProductoDetalle";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import ShortLine from "../../components/ShortLine/ShortLine";
import { Loading } from "../../components";
import "./Producto.css";
import useProducto from "../../common/hooks/useProducto";
import useLanguage from "../../common/hooks/useLanguage";
import ES from "../../common/language/catalogo/es.json";
import EN from "../../common/language/catalogo/en.json";

const Producto = () => {
  const { id } = useParams();
  const appContext = useContext(AppContext);
  const { dictionary } = useLanguage({ EN, ES });

  const { detalleProd } = useProducto(id);

  return (
    <div className="container-section">
      <Grid container columns={12} paddingBottom={5}>
        <Grid item sm={12}>
          <Typography variant="h4" color="primary">
            {dictionary?.producto}
            <ShortLine position="left" />
          </Typography>
        </Grid>
        {appContext?.user ? (
          <>
            {detalleProd ? (
              <ProductoDetalle detalleProd={detalleProd} />
            ) : (
              <Loading />
            )}
          </>
        ) : (
          <PreLoginProducto />
        )}
      </Grid>
    </div>
  );
};

export default Producto;
