import { Box, Typography } from '@mui/material';
import ShortLine from '../../../components/ShortLine/ShortLine';
import { ReconocimientosRealizadosTable } from './ReconocimientosRealizadosTable';
import './reconocimientos.styles.css';
import useReconocimientosRealizados from '../../../common/hooks/useReconocimientosRealizados';
import CustomSearch from '../../../components/CustomSearch/CustomSearch';

const ReconocimientosRealizados = () => {
  const {
    loading,
    recoSearch,
    dictionary,
    page,
    totalPages,
    orden,
    language,
    setPage,
    setSearch,
    cambiarOrdenamiento,
  } = useReconocimientosRealizados();

  return (
    <div className="reconocimientos-realizados-page">
      <Typography variant="h4" color="primary">
        {dictionary?.realizados.titulo}
      </Typography>
      <ShortLine position="left" />
      <section className="myReco-table-section">
        <Box
          border={2}
          borderColor={'primary.main'}
          borderRadius={3}
          className="reconocimientos-realizados-table-container"
        >
          <CustomSearch setSearch={setSearch} setPage={setPage} placeHolder={dictionary?.form.empleadoReconocido} />
          <ReconocimientosRealizadosTable
            recoRealizados={recoSearch}
            loading={loading}
            totalPages={totalPages}
            page={page}
            orden={orden}
            dictionary={dictionary}
            language={language}
            setPage={setPage}
            cambiarOrdenamiento={cambiarOrdenamiento}
          />
        </Box>
      </section>
    </div>
  );
};

export default ReconocimientosRealizados;
