import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  Grid,
  Hidden,
} from "@mui/material";
import {
  verificarToken,
  cambiarPassword,
} from "../../../../services/LoginServices";
import "../../Login.css";
import { LoginFormContainer } from "../LoginFormContainer/LoginFormContainer";
import ES from "../../../../common/language/login/es.json";
import EN from "../../../../common/language/login/en.json";
import { modalError, modalSuccess } from "../../../../utils/validaciones";
import useLanguage from "../../../../common/hooks/useLanguage";
import { ERROR_MESSAGES } from "../../../../common/constants/errorMessages";

const CambioPassword = ({ theme, logoProp }) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const { dictionary } = useLanguage({ EN, ES });

  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isTokenValid, setIsTokenValid] = useState(true);

  useEffect(() => {
    if (token) {
      verificarToken(token)
        .then((response) => {
          if (response && response.email) {
            setEmail(response.email);
          } else {
            setIsTokenValid(false);
            setError(ERROR_MESSAGES[204]);
          }
        })
        .catch((error) => {
          setIsTokenValid(false);
          const errorMessage =
            ERROR_MESSAGES[parseInt(error.message)] ||
            "Error al verificar el token.\n Vuelva a intentarlo en unos minutos o contacte con soporte técnico";
          setError(errorMessage);
          modalError(errorMessage);
        });
    } else {
      navigate("/404");
    }
  }, [token, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      modalError("Las contraseñas no coinciden.");
      return;
    }
    cambiarPassword({ email, clave: newPassword, token })
      .then((response) => {
        if (response && response.status === 200) {
          modalSuccess(
            "Contraseña cambiada con éxito. Por favor, inicie sesión con su nueva contraseña."
          );
          navigate("/login");
        } else {
          const errorMessage =
            ERROR_MESSAGES[response.status] ||
            "Error al cambiar la contraseña.\n Vuelva a intentarlo en unos minutos o contacte con soporte técnico";
          modalError(errorMessage);
        }
      })
      .catch((error) => {
        const errorMessage =
          ERROR_MESSAGES[parseInt(error.message)] ||
          "Error al cambiar la contraseña.\n Vuelva a intentarlo en unos minutos o contacte con soporte técnico";
        modalError(errorMessage);
      });
  };

  const handleBackToLogin = () => {
    navigate("/login");
  };

  return (
    <Grid container sm={12} sx={{ height: "100vh" }} fontStyle="primary">
      <Hidden mdDown>
        <Grid
          item
          md={6}
          className="grid-gradient"
          sx={
            theme
              ? {
                  background: `linear-gradient(
            ${theme[9]?.color},
            ${theme[10]?.color}
            ${theme[11]?.color},
            ${theme[12]?.color}
            ${theme[13]?.color},
            ${theme[14]?.color}
            ${theme[15]?.color}
          )`,
                }
              : {
                  background:
                    "linear-gradient(9deg, rgba(2,0,36,1) 0%, rgba(104,56,247,1) 0%, rgba(252,252,252,1) 0%, rgba(0,212,255,1) 35%)",
                }
          }>
          <img
            className="logo-login-desktop"
            onClick={() => navigate("/")}
            src={logoProp?.logoUrl}
            alt="logo"
          />
        </Grid>
      </Hidden>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <LoginFormContainer>
          <Box sx={{ width: "100%", maxWidth: 500, margin: "auto", mt: 5 }}>
            <Typography
              variant="h4"
              color="primary"
              textAlign="center"
              sx={{ marginBottom: 6 }}>
              {dictionary?.form.cambioDeClave}
            </Typography>

            {error && (
              <Typography color="error" textAlign="center">
                {error}
              </Typography>
            )}

            {isTokenValid ? (
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <TextField
                    label={dictionary?.form.correoElectronico}
                    value={email}
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <TextField
                    label={dictionary?.form.nuevaClave}
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <TextField
                    label={dictionary?.form.confirmarNuevaClave}
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </FormControl>
                <Box textAlign="center" sx={{ mt: 3 }}>
                  <Button type="submit" variant="contained" color="primary">
                    Cambiar Contraseña
                  </Button>
                </Box>
              </form>
            ) : (
              <Box textAlign="center" sx={{ mt: 3 }}>
                <Button
                  onClick={handleBackToLogin}
                  variant="contained"
                  color="primary">
                  Volver al Login
                </Button>
              </Box>
            )}
          </Box>
        </LoginFormContainer>
      </Grid>
    </Grid>
  );
};

export default CambioPassword;
