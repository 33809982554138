import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { misMovimientosInterface } from "../../../../../../common/interfaces/MisMovimientosInterface";
import useHome from "../../../../../../common/hooks/useHome";

const UltimosConsumosTable = () => {
  const { ultimosConsu, dictionary } = useHome();
  const { isLoading, data } = ultimosConsu;

  return (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#E9E9E9" }}>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                {dictionary?.tabla.fecha}
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                {dictionary?.tabla.transaccion}
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                {dictionary?.tabla.detalle}
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                {dictionary?.tabla.puntos}
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                {dictionary?.tabla.comentario}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading || !data ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                  <div>{dictionary?.tabla.loading}</div>
                </TableCell>
              </TableRow>
            ) : ultimosConsu && ultimosConsu.data.length > 0 ? (
              ultimosConsu.data
                .slice(0, 6)
                ?.map((row: misMovimientosInterface, index: number) => (
                  <TableRow key={`${row.idMovimiento}-${index}`}>
                    <TableCell align="center">{row.fechaCorta}</TableCell>
                    <TableCell align="center">{row.idMovimiento}</TableCell>
                    <TableCell align="center">
                      {row.tipoMovimientoDescripcion}
                    </TableCell>
                    <TableCell align="center">{row.puntos}</TableCell>
                    <TableCell align="center">
                      {row.comentario ? row.comentario : "-"}
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableCell align="center" colSpan={4}>
                {dictionary?.tabla.sinDatos}
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UltimosConsumosTable;
