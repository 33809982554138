import { Box, Hidden } from "@mui/material";
import React, { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import MenuIcon from "@mui/icons-material/Menu";

type Props = {
  open: boolean;
  setOpen: React.ComponentState;
};
const BurgerBtn: React.FC<Props> = ({ open, setOpen }) => {
  const appContext = useContext(AppContext);
  return (
    <Hidden smUp={appContext?.user ? false : true}>
      <Box
        onClick={() => setOpen(!open)}
        sx={{ zIndex: 10000, cursor: "pointer", position: "relative" }}>
        <MenuIcon sx={{ color: "white" }} fontSize="large" />
      </Box>
    </Hidden>
  );
};

export default BurgerBtn;
