import { TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { Clear } from '@mui/icons-material';

const DELAY = 1000;
interface Props {
  setSearch: (value: string) => void;
  setPage: (value: number) => void;
  placeHolder?: string;
}
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (value.length > 2 || value.length <= 0) {
        setDebouncedValue(value);
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

const CustomSearch = ({ setSearch, setPage, placeHolder }: Props) => {
  const [inputValue, setInputValue] = useState('');
  const debouncedValue = useDebounce(inputValue, DELAY);

  const onInputChange = (value: string) => {
    setInputValue(value);
  };

  useEffect(() => {
    setSearch(debouncedValue);
    setPage(1);
  }, [debouncedValue, setSearch, setPage]);

  return (
    <TextField
      type="text"
      value={inputValue}
      className="input-form input-buscar"
      placeholder={placeHolder}
      onChange={(e) => {
        onInputChange(e.target.value);
      }}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {inputValue && (
              <IconButton onClick={() => onInputChange('')}>
                <Clear />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};
export default CustomSearch;
