import React from "react";
import video1 from "../../../../common/assets/images/home/video-crypto-home-1.webp";
import video2 from "../../../../common/assets/images/home/video-crypto-home-2.webp";
import video3 from "../../../../common/assets/images/home/video-crypto-home-3.webp";
import Grid from "@mui/material/Grid";
import { Box, Hidden, Typography } from "@mui/material";
import "./SectionFinancialLiteracy.css";
import ShortLine from "../../../../components/ShortLine/ShortLine";
import img1 from "../../../../common/assets/images/home/crypto-home-1.webp";
import img2 from "../../../../common/assets/images/home/crypto-home-2.webp";
import img3 from "../../../../common/assets/images/home/crypto-home-3.webp";
import ES from "../../../../common/language/home/es.json";
import EN from "../../../../common/language/home/en.json";
import useLanguage from "../../../../common/hooks/useLanguage";

type Props = {
  direction?: string;
  title?: string;
  description?: string;
  image?: string;
  infront?: boolean;
};

const SectionFinancialLiteracy: React.FC<Props> = () => {
  const { dictionary } = useLanguage({ EN, ES });
  return (
    <Grid
      container
      xs={12}
      sx={{
        backgroundColor: "#e7edfa",
        padding: "50px 0",
        position: "relative",
      }}>
      <Hidden smDown={true}>
        <img
          src={img1}
          alt="icono seccion financiera"
          className="icono-home-sec-fin icono-home-sec-fin-1"
        />
        <img
          src={img2}
          alt="icono seccion financiera"
          className="icono-home-sec-fin icono-home-sec-fin-2"
        />
        <img
          src={img3}
          alt="icono seccion financiera"
          className="icono-home-sec-fin icono-home-sec-fin-3"
        />
        <img
          src={img1}
          alt="icono seccion financiera"
          className="icono-home-sec-fin icono-home-sec-fin-4"
        />
      </Hidden>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography variant="h4" color="primary">
            {dictionary?.educacionFinanciera}
            <ShortLine />
          </Typography>
          <Typography variant="h6" color="initial" textAlign={"center"}>
            {dictionary?.consejo}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <div className="SectionImage">
            <img
              src={video2}
              className="edu-financiera-img"
              alt="edu-financiera-img"
            />
            <img
              src={video3}
              className="edu-financiera-img"
              alt="edu-financiera-img"
            />
            <img
              src={video1}
              className="edu-financiera-img front"
              alt="edu-financiera-img"
            />
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SectionFinancialLiteracy;
