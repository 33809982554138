import { headerOptions } from "../common/constants/constants";
import {
  NivelEducacionVideoDTO,
  NivelEducacionVideoUsuario,
} from "../common/interfaces/EducacionFinancieraInterface";
import { fetchContent, fetchNoJsonRes } from "../utils/fetchUtils";
import { validarPutVideo } from "../utils/validaciones";

export const GetNivelAlcanzado = async () => {
  const res: NivelEducacionVideoDTO[] = await GetVideoByNivel();
  const resNivelAlcanzado = res.find(
    (el) => el.estado === "" || el.estado === "INICIADO"
  );

  return resNivelAlcanzado ? resNivelAlcanzado : res[3];
};

export const GetVideoByNivel = () => {
  try {
    let response = fetchContent(`/api/video/niveleducacion`, {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    throw new Error("GetVideoByNivel: " + error);
  }
};

export const GetVideoByIdNivelEducacionVideo = (id: number | undefined) => {
  try {
    let response = fetchContent(`/api/video/niveleducacionvideo/${id}`, {
      method: "GET",
    });
    return response;
  } catch (error: any) {
    throw new Error("GetVideoByNivel: " + error);
  }
};

export const PutNivelEducacionVideoUsuario = async (
  data: NivelEducacionVideoDTO | undefined,
  idUsu: number | undefined
) => {
  const nivelEducacionVideoUsuario: NivelEducacionVideoUsuario = {
    idNivelEducacionVideoUsuario: data?.idNivelEducacionVideoUsuario,
    idNivelEducacionVideo: data?.idNivelEducacionVideo,
    tiempoTranscurrido: data?.tiempoTranscurrido,
    estado: data?.estado,
    idUsuario: idUsu,
    fechaHoraInicio: new Date(Date.now()).toISOString(),
    fechaHoraFin: new Date(Date.now()).toISOString(),
  };

  const _nivelEducacionVideoUsuario = await PutNivelEducacionVideoUsuarioFetch(
    nivelEducacionVideoUsuario.idNivelEducacionVideoUsuario,
    nivelEducacionVideoUsuario
  );
  if (_nivelEducacionVideoUsuario.ok) {
    validarPutVideo();
    const res = await _nivelEducacionVideoUsuario.json();
    return res;
  }
};

const PutNivelEducacionVideoUsuarioFetch = (
  IdNivelEducacionVideoUsuario: number | undefined,
  body: NivelEducacionVideoUsuario
) => {
  try {
    let response = fetchNoJsonRes(
      `/api/nivelEducacionVideoUsuario/${IdNivelEducacionVideoUsuario}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: headerOptions,
      }
    );
    return response;
  } catch (error: any) {
    throw new Error("GetVideoByNivel: " + error);
  }
};

export const PostNivelEducacionVideoUsuario = async (
  data: NivelEducacionVideoDTO | undefined,
  idUsu: number | undefined
) => {
  const nivelEducacionVideoUsuario: NivelEducacionVideoUsuario = {
    idNivelEducacionVideo: data?.idNivelEducacionVideo,
    tiempoTranscurrido: data?.tiempoTranscurrido,
    estado: "INICIADO",
    idUsuario: idUsu,
    fechaHoraInicio: new Date(Date.now()).toISOString(),
    fechaHoraFin: new Date(Date.now()).toISOString(),
  };

  const _nivelEducacionVideoUsuario = await PostNivelEducacionVideoUsuarioFetch(
    nivelEducacionVideoUsuario
  );
  if (_nivelEducacionVideoUsuario.ok) {
    validarPutVideo();
    const res = await _nivelEducacionVideoUsuario.json();
    return res;
  }
};

const PostNivelEducacionVideoUsuarioFetch = (
  body: NivelEducacionVideoUsuario
) => {
  try {
    let response = fetchNoJsonRes(`/api/nivelEducacionVideoUsuario`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: headerOptions,
    });
    return response;
  } catch (error: any) {
    throw new Error("GetVideoByNivel: " + error);
  }
};
