import { cliente, headerOptions } from "../common/constants/constants";
import {
  CrearCanjeDTO,
  carritoItemsInterface,
  formCarritoCanje,
} from "../common/interfaces/CarritoInterface";
import { fetchContent, fetchNoJsonRes } from "../utils/fetchUtils";
import { getPersonaPuntos } from "./ComprarCryptoService";
import { getPersonaByIdUsuario } from "./UsuarioService";

export const PostCanje = async (
  idPersona: number | null | undefined,
  idUsuario: number | undefined,
  form: formCarritoCanje
) => {
  if (!idUsuario) {
    const user: string | null = sessionStorage.getItem("user");
    if (user) {
      idUsuario = JSON.parse(user).idUsuario;
    } else {
      return null;
    }
  }

  const puntosRes = await getPersonaPuntos(idPersona, cliente);
  const puntos = await puntosRes.json();

  const user = await getPersonaByIdUsuario(idUsuario);

  let sumTotal = 0;
  form.productos.forEach(
    (el: carritoItemsInterface) => (sumTotal += el.puntos)
  );

  if (puntos >= sumTotal) {
    try {
      const products = form.productos.map((el: carritoItemsInterface) => {
        return {
          idProducto: el.idProducto,
          cantidad: el.cantidad,
          costo: el.puntosPorUnidad,
          importe: parseFloat(parseFloat(el.Precio).toFixed(2)),
          codigoProveedor: el.CodigoProveedor,
          codigoProductoProveedor: el.CodigoProductoProveedor,
          esRegalo: el.Regalo,
          regaloInfo: {
            nombreDestinatario: el?.PersonaRegalo || "",
            emailDestinatario: el?.Email || "",
            mensaje: el?.Comentario || "",
            nombreRemitente: el.Regalo
              ? `${user?.nombre} ${user.apellido}`
              : "",
          },
        };
      });

      const canjeDto: CrearCanjeDTO = {
        idUsuario: idUsuario,
        idCliente: cliente,
        puntosUsuario: puntos,
        costoTotal: sumTotal,
        domicilio: user.domicilio,
        observacionesDomiclio: "",
        localidad: user.localidad,
        provincia: user.provincia,
        pais: user.pais,
        codigoPostal: user.cp,
        telefonoContacto: user.telefonoContacto,
        email: user.email,
        observaciones: "",
        idFormaEntrega: form.idFormaEntrega,
        formaEntregaDescripcion: "",
        idPuntoEntrega: form.idPuntoEntrega,
        fecha: new Date(Date.now()).toISOString(),
        productos: products,
      };
      if (form.idFormaEntrega === 1) {
        //si es domicilio agregar
        canjeDto.idPais = form.idPais;
        canjeDto.idProvincia = form.idProvincia;
        canjeDto.idLocalidad = form.idLocalidad;
        canjeDto.domicilio = form.domicilio
          ? form.domicilio
          : `${form.calle} ${form.calleNumero} ${form.piso} ${form.departamento}`;
        canjeDto.codigoPostal = form.codigoPostal;
      }

      let response = await fetchNoJsonRes("/api/canje", {
        method: "POST",
        body: JSON.stringify(canjeDto),
        headers: headerOptions,
      });
      return response;
    } catch (error: any) {
      console.error(error);

      throw new Error("[ComprarCrypto], PostORder error]: " + error);
    }
  } else {
    // mensaje de error
  }
};

export const GetFormaEntrega = async () => {
  try {
    const response = await fetchContent("api/formaEntrega", { method: "GET" });

    return response;
  } catch (error: any) {
    console.error("GetFormaEntrega");
    throw new Error("GetFormaEntrega: " + error);
  }
};

export const GetPuntoEntrega = async () => {
  try {
    const response = await fetchContent("api/puntoentrega", { method: "GET" });

    return response;
  } catch (error: any) {
    console.error("GetFormaEntrega");
    throw new Error("GetFormaEntrega: " + error);
  }
};

export const GetDomicilioEntrega = async (
  idPersona: number | undefined | null
) => {
  try {
    if (!idPersona) {
      const user: string | null = sessionStorage.getItem("user");
      if (user) {
        idPersona = JSON.parse(user).idPersona;
      } else {
        return null;
      }
    }
    const response = await fetchContent(
      `api/persona/domicilioentrega/${idPersona}`,
      { method: "GET" }
    );

    return response;
  } catch (error: any) {
    console.error("GetFormaEntrega");
    throw error;
  }
};
