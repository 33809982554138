import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import React from "react";
import { FiltroCategoria } from "./FiltroCategoria/FiltroCategoria";
import "./styles/index.css";
import {
  IFiltro,
  bodyProductsInterface,
} from "../../../../common/interfaces/GiftCardInterfaces";
import { bodyFilterInit } from "../../../../common/constants/constants";
import { FiltroMarca } from "./FiltroMarca/FiltroMarca";
import ES from "../../../../common/language/catalogo/es.json";
import EN from "../../../../common/language/catalogo/en.json";
import useLanguage from "../../../../common/hooks/useLanguage";
import { FiltroPais } from "./FiltroPais/FiltroPais";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { accordionStyle } from "./styles/style";
import { useDispatch } from "react-redux";
import { resetFilter } from "../../../../common/redux/features/filtroCatalogoReducer";

const FilterNameStyle = {
  color: "var(--color-light-gray)",
  fontWeight: 500,
  fontSize: "15px",
  paddingTop: "10px",
  paddingBottom: "5px",
};

type Props = {
  paises: IFiltro[] | null;
  limpiar: boolean;
  setLimpiar: React.ComponentState;
  filterProducts: bodyProductsInterface;
  setFilterProducts: React.ComponentState;
  handleClickFilter: () => void;
};

const FiltroCatalogo: React.FC<Props> = ({
  paises,
  limpiar,
  setLimpiar,
  filterProducts,
  setFilterProducts,
}) => {
  const { dictionary } = useLanguage({ EN, ES });
  const dispatch = useDispatch();

  const handleClickFilter = () => {
    dispatch(resetFilter());
    setLimpiar(!limpiar);
    let pagina = filterProducts.pagina;
    setFilterProducts({ ...bodyFilterInit, pagina: pagina });
  };

  return (
    <Box maxWidth={300}>
      <Box className="filtros-catalogo-header">
        <Typography
          variant="h5"
          color="initial"
          fontWeight={500}
          sx={{ color: "var(--color-light-gray)" }}>
          {dictionary?.filtrarPor}
        </Typography>
        <Button
          variant="contained"
          color="paginationColor"
          sx={{
            color: "primary.main",
            textTransform: "capitalize",
            borderRadius: "10px",
          }}
          onClick={() => handleClickFilter()}>
          {dictionary?.limpiarFiltros}
        </Button>
      </Box>

      <Accordion sx={accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography variant="h5" color="initial" sx={FilterNameStyle}>
            {dictionary?.pais}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FiltroPais
            paises={paises}
            limpiar={limpiar}
            filterProducts={filterProducts}
            setFilterProducts={setFilterProducts}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion sx={accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography variant="h5" color="initial" sx={FilterNameStyle}>
            {dictionary?.categoria}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FiltroCategoria
            limpiar={limpiar}
            filterProducts={filterProducts}
            setFilterProducts={setFilterProducts}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion sx={accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography variant="h5" color="initial" sx={FilterNameStyle}>
            {dictionary?.marcas}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FiltroMarca
            limpiar={limpiar}
            filterProducts={filterProducts}
            setFilterProducts={setFilterProducts}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FiltroCatalogo;
