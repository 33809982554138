import { dataGrapfFormat } from "../../../../utils/functions";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Box, Typography } from "@mui/material";
import "./CryptoGrafico.css";
import MiniLoader from "../../../../components/Loaders/MiniLoader/MiniLoader";
import useCripto from "../../../../common/hooks/useCripto";

type Props = {
  moneda: string;
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const CryptoGrafico = ({ moneda }: Props) => {
  const { dictionary, dataGraph } = useCripto(moneda);

  return (
    <Box className="graph-continer-card">
      {/* {cryptoValores && ( */}
      <Box
        textAlign={"center"}
        sx={{
          width: "100%",
          maxWidth: "315px",
          height: "240px",
          border: 2,
          borderColor: "primary.main",
          borderRadius: 2,
        }}>
        <Typography variant="subtitle1" color="initial">
          {moneda}
        </Typography>
        {dataGraph ? (
          <>
            <Typography variant="caption" color="initial">
              {dictionary?.js.cotizacionPesosTiempoReal}
            </Typography>
            {dataGraph && (
              <Line
                options={{
                  scales: {
                    x: {
                      ticks: {
                        font: {
                          size: 10,
                        },
                      },
                    },
                    y: {
                      ticks: {
                        callback: function (value: any) {
                          const valueLegend = this.getLabelForValue(value);
                          valueLegend.replaceAll(".", "");
                          return valueLegend.replaceAll(".", "");
                        },
                      },
                    },
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  responsive: true,
                  maintainAspectRatio: true,
                }}
                data={dataGrapfFormat(dataGraph)}
              />
            )}
          </>
        ) : (
          <MiniLoader />
        )}
      </Box>
    </Box>
  );
};

export default CryptoGrafico;
