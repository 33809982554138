import React, { useCallback, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { ReconocimientoAsignadoInterface } from '../../../common/interfaces/ReconocimientoAsignadoInterface';

interface DetailModalProps {
  open: boolean;
  onClose: () => void;
  recognition: ReconocimientoAsignadoInterface;
}

const DetailModal: React.FC<DetailModalProps> = ({
  open,
  onClose,
  recognition,
}) => {
  const { descripcion, tituloTipoReconocimiento, imagen, puntos } = recognition;

  const updateMetaTags = useCallback(() => {
    const extendedDescription =
      descripcion.length < 100
        ? `${descripcion}... (más detalles sobre este reconocimiento para cumplir con el requisito de longitud mínima de descripción en LinkedIn)`
        : descripcion;

    document
      .getElementById('og-title')
      ?.setAttribute('content', tituloTipoReconocimiento);
    document
      .getElementById('og-description')
      ?.setAttribute('content', extendedDescription);
    document.getElementById('og-image')?.setAttribute('content', imagen);
    document
      .getElementById('og-url')
      ?.setAttribute('content', window.location.href);
  }, [descripcion, imagen, tituloTipoReconocimiento]);

  // Log recognition data for debugging purposes
  useEffect(() => {
    if (open) {
      updateMetaTags();
    }
  }, [recognition, open, updateMetaTags]);

  const shareOnLinkedIn = () => {
    const shareUrl = window.location.href; // URL actual
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        shareUrl
      )}`,
      '_blank'
    );
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="div">
          Detalle
        </Typography>
        <Typography>{descripcion}</Typography>
        {imagen && (
          <img
            src={imagen}
            alt="Imagen"
            style={{ maxWidth: '100%', marginBottom: '16px', maxHeight: "14rem" }}
          />
        )}
        {puntos > 0 && (
          <Typography variant="h6" component="h6">
            {puntos} puntos
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={shareOnLinkedIn}
          style={{ marginTop: '16px', display: 'flex', alignItems: 'center' }}
          startIcon={<LinkedInIcon />}
        >
          Compartir en LinkedIn
        </Button>
      </Box>
    </Modal>
  );
};

export default DetailModal;
