import { fetchContent } from '../utils/fetchUtils';
import { setQueryParams } from '../utils/functions';
import { handleServiceError } from './GiftCardService';

interface IMiEquipo {
  ordenamiento: string;
  pagina: number;
  CantidadRegistrosPorPagina: number;
  usuario?: string;
}

export const GetMiEquipo = async (props: IMiEquipo) => {
  try {
    const url = setQueryParams('api/usuario/supervisor/usuarios', props);

    return await fetchContent(url, {
      method: 'GET',
    });
  } catch (error: any) {
    console.log('GetMiEquipo error]: ' + error);
    throw handleServiceError(error);
  }
};

export const getBudgetSupervisor = async (idUsuario: number | string) => {
  try {
    const response = await fetchContent(`/api/Supervisor/${idUsuario}`);
    return response;
  } catch (error) {
    throw handleServiceError(error);
  }
};
