import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./ModalRegalo.css";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "@mui/material";
import {
  IDetalleProducto,
  IProducto,
} from "../../../../common/interfaces/ProductoInterface";
import { useDispatch } from "react-redux";
import { incrementar } from "../../../../common/redux/features/contadorItemReducer";
import { notificacionItemAgregado } from "../../../../utils/functions";
import { addToCart } from "../../../../common/redux/features/carritoReducer";
import { modalError, validarRegalo } from "../../../../utils/validaciones";
import ES from "../../../../common/language/giftcard/es.json";
import EN from "../../../../common/language/giftcard/en.json";
import useLanguage from "../../../../common/hooks/useLanguage";
import { cliente } from "../../../../common/constants/constants";
import { AppContext } from "../../../../context/AppContext";
import { getPuntosByImporte } from "../../../../services/VenderCryptoService";

type Props = {
  detalleProd: IDetalleProducto | null;
  puntos: number;
  importe: number;
  producto: IProducto | null;
  disabled: boolean;
};

const initForm = {
  PersonaRegalo: "",
  Email: "",
  Comentario: "",
};
export const ModalRegalo: React.FC<Props> = ({
  detalleProd,
  puntos,
  importe,
  producto,
  disabled,
}) => {
  const [open, setOpen] = React.useState(false);
  const [form, setForm] = React.useState(initForm);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const appContext = React.useContext(AppContext);
  const { dictionary } = useLanguage({ EN, ES });

  const handleChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const { ingreseNombre, ingreseEmail, ingreseUnComentario } = dictionary;
    if (
      !validarRegalo(form, { ingreseNombre, ingreseEmail, ingreseUnComentario })
    ) {
      return;
    }
    await getPuntosByImporte(
      cliente,
      appContext?.user?.idUsuario,
      importe
    ).then((res) => {
      res.json();
    });
    dispatch(incrementar());
    if (producto) {
      dispatch(
        addToCart({
          idProducto: detalleProd ? detalleProd?.idProducto : null,
          Imagen: detalleProd?.picture,
          Descripcion: detalleProd?.descripcion,
          puntos: puntos,
          puntosPorUnidad: puntos,
          cantidad: 1,
          Nombre: producto?.tituloProducto,
          Regalo: true,
          Precio: importe.toString(),
          Marca: detalleProd?.marcaDescripcion,
          esGiftCard: producto?.esGiftcard,
          ...form,
        })
      );
      notificacionItemAgregado(dictionary?.seAgregoAlCarrito);
      setForm(initForm);
      setOpen(false);
    } else {
      modalError("Elija un producto");
    }
  };

  return (
    <div>
      <Button
        disabled={disabled}
        variant="outlined"
        color="btnPrimary"
        fullWidth
        onClick={handleOpen}
      >
        {dictionary?.regalar}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 10000 }}
      >
        <Box className="modal-regalo">
          <div className="modal-regalo-btn-close-container">
            <Button
              variant="text"
              color="primary"
              className="modal-regalo-btn-close"
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </Button>
          </div>
          <CardGiftcardIcon sx={{ fontSize: "70px" }} color="primary" />
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h3"
            color="primary"
          >
            {dictionary?.paraRegalo}
          </Typography>
          <Divider sx={{ width: "100%" }} />

          <div className="input-container-regalo">
            <Typography variant="subtitle1" color="primary">
              {dictionary?.modalGift.destinatario}
            </Typography>
            <input
              name="PersonaRegalo"
              onChange={(e) => handleChange(e)}
            ></input>
          </div>
          <div className="input-container-regalo">
            <Typography variant="subtitle1" color="primary">
              {dictionary?.modalGift.correo}
            </Typography>
            <input name="Email" onChange={(e) => handleChange(e)}></input>
          </div>
          <div className="input-container-regalo">
            <Typography variant="subtitle1" color="primary">
              {dictionary?.modalGift.mensaje}
            </Typography>
            <textarea
              name="Comentario"
              onChange={(e) => handleChange(e)}
            ></textarea>
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
          >
            {dictionary?.modalGift.agregarAlCarrito}
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
