import React, { ChangeEvent, useRef, useState } from "react";
import ExchangeFormContainer from "../../components/ExchangeFormContainer/ExchangeFormContainer";
import Typography from "@mui/material/Typography";
import ES from "../../../../common/language/crypto/es.json";
import EN from "../../../../common/language/crypto/en.json";
import useLanguage from "../../../../common/hooks/useLanguage";
import { Button, TextField } from "@mui/material";
import ShortLine from "../../../../components/ShortLine/ShortLine";
import { Link, useNavigate } from "react-router-dom";
import "./OrderCompraExchange.css";
import { Order } from "../../../../common/interfaces/CryptoInterface";
import { AppContext } from "../../../../context/AppContext";
import { validarPostConfirmacion } from "../../../../utils/validaciones";
import { PostConfirmarOrder } from "../../../../services/ComprarCryptoService";

type Props = {
  orden: Order | undefined;
  textFields: {
    puntos: number;
    crypto: number;
  };
};
const OrderCompraExchange: React.FC<Props> = ({ orden, textFields }) => {
  const [codigo, setCodigo] = useState<string[]>(["", "", "", "", "", ""]);
  const { dictionary } = useLanguage({ EN, ES });

  const textInputRefs = useRef<any[]>([]);

  const handleChange = (index: number, event: ChangeEvent<any>) => {
    const { value } = event.target;
    const arr = codigo;
    arr[index] = event.target.value;
    setCodigo(arr);
    if (value.length === 1 && index < textInputRefs.current.length - 1) {
      textInputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent<any>) => {
    if (event.key === "Backspace" && index > 0 && codigo[index] === "") {
      event.preventDefault(); // Evita el comportamiento predeterminado de la tecla de retroceso
      textInputRefs.current[index - 1]?.focus();
    }
  };
  const appContext = React.useContext(AppContext);
  const navigate = useNavigate();

  const handleConfirmar = async () => {
    if (orden) {
      const res = await PostConfirmarOrder(
        appContext?.user?.idPersona,
        appContext?.user?.idUsuario,
        orden.id ? orden?.id : "",
        orden.asset?.currency ? orden.asset?.currency : "",
        orden?.buy?.settlement?.amount ? orden?.buy?.settlement?.amount : "", //amount
        codigo.join(""),
        textFields.puntos,
        orden?.asset?.amount ? orden?.asset?.amount : ""
      );
      if (res) {
        validarPostConfirmacion(true, dictionary?.js.ordenConfirmada);
        navigate("/crypto");
      } else {
        validarPostConfirmacion(false, dictionary?.js.ordenConfirmacionError);
      }
    }
  };

  return (
    <ExchangeFormContainer>
      <Typography variant="h6" color="primary">
        {dictionary?.comprar.formConfirmacion.ingresaCodigo}
        <ShortLine />
      </Typography>
      {/* container de codigo */}
      <div className="order-exchange-codigo-container">
        {[0, 1, 2, 3, 4, 5].map((el, index) => (
          <TextField
            type="text"
            key={`codigo-compra-${el}`}
            sx={{
              width: "60px",
              input: { color: "primary.main", fontSize: "18px" },
            }}
            inputRef={(elRef) => (textInputRefs.current[index] = elRef as any)}
            onChange={(e) => handleChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            // value={codigo[index]}
            inputProps={{
              maxLength: 1,
              style: { textAlign: "center" },
            }}
          />
        ))}
      </div>
      {/*  */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleConfirmar()}>
        {dictionary?.comprar.formConfirmacion.Siguiente}
      </Button>
      <div className="order-compra-reenvio">
        <Typography variant="subtitle1" color="primary">
          {dictionary?.comprar.formConfirmacion.siNoRecibisteMail}
        </Typography>
        <Link to="../">
          <Typography variant="subtitle1" color="primary" fontWeight={"bold"}>
            &nbsp;{dictionary?.comprar.formConfirmacion.clickReenvio}
          </Typography>
        </Link>
      </div>
    </ExchangeFormContainer>
  );
};

export default OrderCompraExchange;
