const modalBranchesStyles = () => {
  return {
    container: {
      position: "relative",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: "80vh",
      width: "700px",
      backgroundColor: "white",
      borderRadius: "15px",
      boxShadow: "0 0 24px rgba(0, 0, 0, 0.1)",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
    },
  };
};
export default modalBranchesStyles;
