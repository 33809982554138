import { createSlice } from "@reduxjs/toolkit";

import { FILTROS_OPTIONS_INIT } from "../../constants/constants";
import { IFiltroCatalogo } from "../../interfaces/GiftCardInterfaces";

const initialState: IFiltroCatalogo = FILTROS_OPTIONS_INIT;

const filtroCatalogoSlice = createSlice({
  name: "filtroCatalogo",
  initialState,
  reducers: {
    setearMarca: (state, action) => {
      state.marcas = action.payload;
    },
    setearCategoria: (state, action) => {
      state.categorias = action.payload;
    },
    setearPaises: (state, action) => {
      state.paises = action.payload;
    },
    setearTipoProductos: (state, action) => {
      state.tipoProducto = action.payload;
    },
    setearOrdenamiento: (state, action) => {
      state.ordenamiento = action.payload;
    },
    resetFilter: () => initialState,
  },
});

export const filtroCatalogoReducer = filtroCatalogoSlice.reducer;
export const {
  setearMarca,
  setearCategoria,
  setearPaises,
  setearTipoProductos,
  setearOrdenamiento,
  resetFilter,
} = filtroCatalogoSlice.actions;
