const fichasPrecioContainerStyles = () => {
  return {
    container: {
      display: "flex",
      gap: "8px",
      maxWidth: "500px",
      flexWrap: "wrap",
      justifyContent: "center",
    },
  };
};
export default fichasPrecioContainerStyles;
