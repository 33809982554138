import { Box, Typography } from "@mui/material";
import ShortLine from "../../../components/ShortLine/ShortLine";
import FormularioReconocimiento from "./components/FormularioReconocimiento/FormularioReconocimiento";
import ES from "../../../common/language/reconocimiento/es.json";
import EN from "../../../common/language/reconocimiento/en.json";
import useLanguage from "../../../common/hooks/useLanguage";

const Reconocimientos = () => {
  const { dictionary } = useLanguage({ EN, ES });
  return (
    <Box p={"20px"}>
      <Typography variant="h4" color="primary">
        {dictionary?.reconocimientos}
        <ShortLine position="left" />
        <FormularioReconocimiento />
      </Typography>
    </Box>
  );
};

export default Reconocimientos;
