import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "./PopCategories.css";
import ShortLine from "../../../../../../components/ShortLine/ShortLine";
import { useNavigate } from "react-router-dom";
import ES from "../../../../../../common/language/home/es.json";
import EN from "../../../../../../common/language/home/en.json";
import useLanguage from "../../../../../../common/hooks/useLanguage";

export default function PopCategories() {
  const navigate = useNavigate();

  const { dictionary } = useLanguage({ EN, ES });

  return (
    <section className="PopCategoriesSection">
      <Typography variant="h4" color="primary">
        {dictionary?.categoriasMasPopularesGiftCards}
      </Typography>
      <ShortLine />
      <Grid
        container
        md={12}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent="center"></Grid>
      <Button
        variant="contained"
        color="btnPrimary"
        sx={{ marginTop: "50px" }}
        onClick={() => navigate("/catalogo")}>
        {dictionary?.descubriMasCategorias}
      </Button>
    </section>
  );
}
