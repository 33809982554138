import { Box, Divider, Typography, Button, Hidden } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import "./UserStats.css";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import useLanguage from "../../../../common/hooks/useLanguage";
import { getPersonaPuntos } from "../../../../services/ComprarCryptoService";
import { AppContext } from "../../../../context/AppContext";
import { cliente } from "../../../../common/constants/constants";
import { eduFinancieraService } from "../../../../services/HomeService";
import { GetPuntosCrypto } from "../../../../services/UsuarioService";
import { useNavigate } from "react-router-dom";
import ES from "../../../../common/language/shared/es.json";
import EN from "../../../../common/language/shared/en.json";

const UserStats = () => {
  const [puntos, setPuntos] = useState<number>(0);
  const [nivelEdu, setNivelEdu] = useState<number>(0);
  const [cripto, setCripto] = useState<number>(0);
  const { dictionary } = useLanguage({ EN, ES });
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    getPersonaPuntos(appContext?.user?.idPersona, cliente)
      .then((res) => res.json())
      .then((pto) => setPuntos(pto))
      .catch((err) => console.error(err));

    eduFinancieraService()
      .then((data) => setNivelEdu(data))
      .catch((err) => console.error(err));

    GetPuntosCrypto()
      .then((res) => res.json())
      .then((data) => setCripto(data))
      .catch((err) => console.error(err));
  }, [appContext]);

  return (
    <section className="user-stats-section">
      <Box
        flexDirection={{ xs: "column", sm: "row", md: "row" }}
        className="user-stats-container">
        <Box className="user-stat" onClick={() => navigate("/misMovimientos")}>
          <Button variant="text" color="primary">
            <WorkspacePremiumOutlinedIcon sx={{ fontSize: "70px" }} />
          </Button>
          <div className="user-stat-text">
            <Typography variant="h6" color="primary">
              {dictionary?.indicadores.puntosDisponibles}
            </Typography>
            <Typography paragraph={true} color="primary">
              {dictionary?.indicadores.cantidadPuntos}
            </Typography>
            <Typography variant="h4" color="primary">
              {puntos}
            </Typography>
          </div>
        </Box>
        <Hidden smDown>
          <Divider
            orientation={"vertical"}
            sx={{ bgcolor: "primary.light" }}
            flexItem
          />
        </Hidden>
        <Hidden smUp>
          <Divider
            orientation="horizontal"
            sx={{ bgcolor: "primary.light" }}
            flexItem
          />
        </Hidden>
        <Box className="user-stat" onClick={() => navigate("/crypto")}>
          <Button variant="text" color="primary">
            <MonetizationOnOutlinedIcon sx={{ fontSize: "70px" }} />
          </Button>
          <div className="user-stat-text">
            <Typography variant="h6" color="primary">
              {dictionary?.indicadores.inversion}
            </Typography>
            <Typography paragraph={true} color="primary">
              {dictionary?.indicadores.seguiInvirtiendo}
            </Typography>
            <Typography variant="h4" color="primary">
              {cripto}
            </Typography>
          </div>
        </Box>
        <Hidden smDown>
          <Divider
            orientation={"vertical"}
            sx={{ bgcolor: "primary.light" }}
            flexItem
          />
        </Hidden>
        <Hidden smUp>
          <Divider
            orientation="horizontal"
            sx={{ bgcolor: "primary.light" }}
            flexItem
          />
        </Hidden>
        <Box className="user-stat">
          <Button variant="text" color="primary">
            <SchoolOutlinedIcon sx={{ fontSize: "70px" }} />
          </Button>
          <div className="user-stat-text">
            <Typography variant="h6" color="primary">
              {dictionary?.indicadores.nivelEducacionFinanciera}
            </Typography>
            <Typography paragraph={true} color="primary">
              {dictionary?.indicadores.seguiEducandote}
            </Typography>
            <Typography variant="h4" color="primary">
              {nivelEdu} %
            </Typography>
          </div>
        </Box>
      </Box>
    </section>
  );
};

export default UserStats;
